import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { buildConfigBasedOptions } from '../../../../utility/config'
const { Option } = Select

const Heading = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 10px;
    text-shadow: 0 0 #000000;
`

const SelectContainer = styled.div`
    display: flex;
`

const SelectFlexChild = styled.div`
    margin-right: 20px;
`

const entityOptions = [
  {
    name: 'Orders',
    value: 'orders'
  },
  {
    name: 'Shipments',
    value: 'shipments'
  },
  {
    name: 'Loads',
    value: 'loads'
  },
  {
    name: 'Companies',
    value: 'companies'
  },
  {
    name: 'Products',
    value: 'products'
  }
]

const templateOptions = [
  'Standard'
]

const SelectEntityComponent = (props) => {
  const handleEntityChange = (value) => {
    props.setEntity(value)
  }

  const handleTemplateChange = (value) => {
    // to be implemented
  }

  const options = buildConfigBasedOptions(entityOptions)
  return (
    <SelectContainer>
      <SelectFlexChild>
        <Heading>Choose Entity *</Heading>
        <Select defaultValue={options[0].name} style={{ width: 120 }} onChange={handleEntityChange}>
          {options.map((entity, index) => {
            return (
              <Option
                key={index}
                value={entity.value}
              >
                {entity.name}
              </Option>
            )
          })}
        </Select>
      </SelectFlexChild>
      <SelectFlexChild>
        <Heading>Templates *</Heading>
        <Select defaultValue={templateOptions[0]} style={{ width: 120 }} onChange={handleTemplateChange}>
          {templateOptions.map((template, index) => {
            return (
              <Option
                key={index}
                value={template}
              >
                {template}
              </Option>
            )
          })}
        </Select>
      </SelectFlexChild>
    </SelectContainer>
  )
}
export default SelectEntityComponent
