import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { Container, EntityDetailView } from '../../components'
import { doLoadDocuments, doLoadOrderItems, doLoadShipments } from '../../../redux/modules/entities/actions'
import OrdersSideLink from './OrdersSideLink'
import { entityTitle } from '../../../configs/objects'
import OrderDetailTable from './OrderDetailTable'
import { FileList3 } from '@styled-icons/remix-line'
import OrderStatusInformation from './OrderStatusInformation'
import { Row, Col, Icon } from 'antd'
import { getEntityAlerts } from '../../../redux/modules/alerts/actions'

const IconEx = styled(Icon)`
  margin-right: 3.5px;
  border: 1px solid #c5c5c5;
  padding: 3px;
  border-radius: 20px;
  color: #c5c5c5;
`
const RowEx = styled(Row)``
const Title = styled.span`
  font-weight: normal;
  color: #212121;
  font-size: 15px;
`
const ColSideLink = styled(Col)`
  margin-right: -20px !important;
`
const ColDetails = styled(Col)`
  padding-left:0 !important;
`
const RelatedOrderContainer = styled(Col)`
  background-color: #F3F4F6;
  padding: 0;
`
const ContainerEx = styled(Container)`
  padding: 0;
  flex-grow: 1;
`

const OrderIcon = styled(FileList3)`
  color: #2196f3;
  width: 45px;
  padding: 5px;
  background-color: #f7f7f7;
`

class OrderDetailPage extends React.Component {
  componentDidMount () {
    const { loadOrderItems, loadDocuments, loadShipments, match, orders, getEntityAlerts } = this.props
    const { orderNumber } = match.params
    const order = _.find(orders, { number: orderNumber }) || {}
    loadOrderItems(order.id)
    loadDocuments(order.id)
    loadShipments(order.id)
    getEntityAlerts(order.id)
  }

  selectOrder = (id) => {
    const { loadOrderItems, loadDocuments, loadShipments, history, orders, getEntityAlerts } = this.props
    const order = _.find(orders, { id }) || {}
    history.push(`${order.number}`)
    loadOrderItems(id)
    loadDocuments(id)
    loadShipments(id)
    getEntityAlerts(id)
  }

  render () {
    const { orders, match, documents, entityAlerts } = this.props
    const { orderNumber } = match.params

    const order = _.find(orders, { number: orderNumber })
    const { billTo, shipTo, customerId } = order

    const address = [{ ...billTo, title: <Title><IconEx type='user' /> Bill To</Title>, customerId }, { ...shipTo, title: <Title><IconEx type='user' /> Ship To</Title>, customerId }]
    const showAlert = true
    return (
      <RowEx>
        <ColSideLink span={5}>
          <RelatedOrderContainer>
            <OrdersSideLink orders={orders} orderId={order.id} selectOrder={this.selectOrder} />
          </RelatedOrderContainer>
        </ColSideLink>
        <ColDetails span={19}>
          <ContainerEx>
            <EntityDetailView data={order} documentId={orderNumber} icon={OrderIcon}
              entity={entityTitle.ORDER} subtitle={{}} documents={documents} information={OrderStatusInformation}
              table={OrderDetailTable} tableTitle='Order Items' address={address} showAlert={showAlert} entityAlerts={entityAlerts} />
          </ContainerEx>
        </ColDetails>
      </RowEx>
    )
  }
}

const mapStateToProps = ({ entities: { loading, orders, documents }, alerts: { entityAlerts } }) => {
  return {
    orders,
    documents,
    loading: loading,
    entityAlerts
  }
}

const mapDispatchToProps = {
  loadOrderItems: doLoadOrderItems,
  loadDocuments: doLoadDocuments,
  loadShipments: doLoadShipments,
  getEntityAlerts
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage))
