import React from 'react'
import styled from 'styled-components'
import { Card, Statistic } from 'antd'
import { FormattedNumber } from 'react-intl'

const CardEx = styled(Card)`
  margin-bottom: 5px;
  .ant-card-body {
    padding: 4px 11px !important;
`
const StatisticEx = styled(Statistic)`
  .ant-statistic-content{
    color: #212121;
    font-size: 15px;
  }
  .ant-statistic-title {
    margin-bottom: 4px;
    color: #757575;
    font-size: 13px;
}
`

const STAT_TYPE = Object.freeze({
  CURRENCY: 'currency',
  NUMBER: 'number'
})

const renderCurrency = ({ value, unit }) => {
  const len = value.toString().length
  return <FormattedNumber {...{ style: 'currency' }} notation={len > 10 ? 'compact' : 'standard'} currency={unit || 'USD'} value={value} />
}

const FastFactsCard = ({ value, id, defaultMessage, unit, type = STAT_TYPE.NUMBER }) => {
  return <CardEx >
    { type === STAT_TYPE.CURRENCY
      ? <StatisticEx value={value}
        title={defaultMessage}
        formatter={(value) => renderCurrency({ value, unit })} />
      : <StatisticEx value={value}
        suffix={unit}
        title={defaultMessage}
      />
    }
  </CardEx>
}

export default FastFactsCard
