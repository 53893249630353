import moment from 'moment'

export const isProduction = process.env.NODE_ENV === 'production'

export const DATE_FORMAT = 'LL'
export const DATE_TIME_FORMAT = 'LLL'
export const DAY_DATE_TIME_FORMAT = 'LLLL'

export const RANGE_SELECTOR = {
  Today: [moment().startOf('day'), moment().endOf('day')],
  'This Week': [moment().startOf('week'), moment().endOf('week')],
  'This Month': [moment().startOf('month'), moment().endOf('month')]
}

const dateWrapper = (date, format) => {
  if (date) {
    if (moment(date, 'YYMMDD').isValid()) {
      return moment(date, 'YYMMDD').format(format)
    } else if (moment(date, moment.ISO_8601).isValid()) {
      return moment(date).format(format)
    } else {
      return date
    }
  } else {
    return 'NA'
  }
}

export const dateFormatter = (date) => {
  return dateWrapper(date, DATE_FORMAT)
}

export const dateTimeFormatter = (date) => {
  return dateWrapper(date, DATE_TIME_FORMAT)
}

export const dayDateTimeFormatter = (date) => {
  return dateWrapper(date, DAY_DATE_TIME_FORMAT)
}

export const sortByKey = (key, order = 'asc') => {
  return function innerSort (a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    )
  }
}
