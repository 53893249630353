import appActions from './actions'
import packageJson from '../../../../package.json'

const initialState = {
  loginError: null,
  changePassError: null,
  version: process.env.BUILD_TAG || packageJson.version || 'BETA'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case appActions.LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.payload
      }
    }
    case appActions.CHECK_OLD_PASSWORD_ERROR:
      return { ...state, changePassError: action.payload }
    case appActions.CHANGE_PASSWORD_ERROR:
      return { ...state, changePassError: action.payload }
    case appActions.CLEAR_ERRORS:
      return { ...state, changePassError: null }
    default:
      return state
  }
}
export default reducer
