import MANUFACTURER from './manufacturer'
import WHOLESALER from './wholesaler'
import TRANSPORTER from './transporter'

const ROLES = {
  MANUFACTURER,
  WHOLESALER,
  TRANSPORTER
}

export default ROLES
