import jwtDecode from 'jwt-decode'
import store from '../../../../src'

const actions = {
  LOGIN: 'user/LOGIN',
  LOGIN_SUCCESS: 'user/LOGGED-IN',
  LOGIN_FAIL: 'user/LOGIN_FAIL',
  LOGOUT: 'user/LOGOUT',
  LOGIN_ERROR: 'app/LOGIN_ERROR',

  TENANT_INFO: 'user/TENANT_INFO',
  TENANT_INFO_SUCCESS: 'user/TENANT_INFO_SUCCESS',
  TENANT_INFO_FAIL: 'user/TENANT_INFO_FAIL',

  LOAD_LOGO: 'user/LOAD_LOGO',
  LOAD_LOGO_SUCCESS: 'user/LOAD_LOGO_SUCCESS',
  LOAD_LOGO_FAIL: 'user/LOAD_LOGO_FAIL',

  CHECK_OLD_PASSWORD: 'user/CHECK_OLD_PASSWORD',
  CHECK_OLD_PASSWORD_ERROR: 'app/CHECK_OLD_PASSWORD_ERROR',
  CHECK_OLD_PASSWORD_FAIL: 'user/CHECK_OLD_PASSWORD_FAIL',
  CHECK_OLD_PASSWORD_SUCCESS: 'user/CHECK_OLD_PASSWORD_SUCCESS',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
  CHANGE_PASSWORD_ERROR: 'app/CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_FAIL: 'user/CHANGE_PASSWORD_FAIL',
  CHANGE_PASSWORD_SUCCESS: 'user/CHANGE_PASSWORD_SUCCESS',
  CLEAR_ERRORS: 'app/CLEAR_ERRORS',
  CLEAR_SUCCESS: 'user/CLEAR_SUCCESS'
}

export const isLogin = () => {
  const token = localStorage.getItem('accessToken')
  return token && token !== '' && Math.round(new Date().getTime() / 1000) < jwtDecode(token).exp
}

/*
* The different between isLogin and isAuthenticated is isAuthenticated also compare user in Redux with local storage
*/
export const isAuthenticated = () => {
  const token = localStorage.getItem('accessToken')
  const user = store.getState().user
  return (
    token &&
    token !== '' &&
    Math.round(new Date().getTime() / 1000) < jwtDecode(token).exp &&
    user.email === localStorage.getItem('userEmail')
  )
}

export const doLogin = (credentials) => ({
  type: actions.LOGIN,
  data: credentials
})

export const doLogout = () => ({
  type: actions.LOGOUT
})
export const doCheckOldPassWord = (credentials) => ({
  type: actions.CHECK_OLD_PASSWORD,
  data: credentials
})
export const clearSuccess = () => ({
  type: actions.CLEAR_SUCCESS
})

export const getLogo = (obj) => ({
  type: actions.LOAD_LOGO,
  data: obj
})

export default actions
