// @flow
import React from 'react'
import styled from 'styled-components'
import { Row, Tabs, Icon, Empty } from 'antd'
import TimelineWidget from './TimelineWidget'
import { FormattedMessage } from 'react-intl'

const { TabPane } = Tabs

const TabsEx = styled(Tabs)`
  .ant-tabs-extra-content {
    float: left !important;
    padding: 0 10px;
  }
  .ant-tabs-nav-container{
    float: right !important;
    padding: 10px 0px 0px 0;
  }
  .ant-tabs-tab{
    padding: 0px 5px 10px 12px;
    margin: 0;
    font-size: 18px;
  }
  .ant-tabs-tab-active {
    color: #2196F3;
  }
  .ant-tabs-ink-bar{
    background-color: #2196F3;
  }
`

const TabPaneEx = styled(TabPane)``

const Section = styled(Row)`
  background-color: white;
`
const BoldText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #212121;
  font-size: 15px;
`
const EmptyEx = styled(Empty)`
  margin: 20% auto !important;
`

type EventData = {
  type: string,
  timestamp: string,
  notes: string
}

type Props = {
  events: Array<EventData>
}

const EventTimeline = function (props: Props) {
  const { itemId, entity } = props
  return (
    <TabsEx defaultActiveKey='1' tabBarExtraContent={<BoldText><FormattedMessage id='timeline' defaultMessage='Timeline' /></BoldText>}>
      <TabPaneEx tab={<Icon type='clock-circle' />} key='1'>
        <Section>
          <TimelineWidget itemId={itemId} entity={entity} />
        </Section>
      </TabPaneEx>
      <TabPaneEx tab={<Icon type='flag' />} key='2'>
        <Section>
          <EmptyEx image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Section>
      </TabPaneEx>
    </TabsEx>
  )
}

export default EventTimeline
