const SHIPMENT_STATUS = [
  'Created',
  'Picked',
  'Loaded',
  'InTransit',
  'Delivered',
  'Closed',
  'Cancelled',
  'None',
  'Partially Loaded'
]
const SUPPORTED_CURRENCIES = ['EUR', 'USD', 'GBP']
const DELIVERY_STATUS = ['None', 'Partial', 'Complete']
const REJECTION_STATUS = [
  'Unspecified',
  'None',
  'ContainsRejections',
  'Recalls'
]

const CONST = {
  SHIPMENT_STATUS,
  SUPPORTED_CURRENCIES,
  DELIVERY_STATUS,
  REJECTION_STATUS
}

export default CONST
