import { FormattedNumber } from 'react-intl'
import React from 'react'
import moment from 'moment'
import keyBy from 'lodash/keyBy'
import get from 'lodash/get'
import TableStyled from './TableStyled'
import styled from 'styled-components'
import { Thermometer } from '@styled-icons/boxicons-solid'
import { Flag } from '@styled-icons/heroicons-outline'
import { dateTimeFormatter, dateFormatter } from '../../../utility'
import store from '../../../../src'

const ThermometerEx = styled(Thermometer)`
  width: 20px;
`

const FlagEx = styled(Flag)`
  width: 20px;`

const OrangeText = styled.span`
  color: orange;
`
const renderCurrency = (text, record = {}, index) => {
  const { priceUnit, currency = 'USD' } = record

  return (text) ? <FormattedNumber {...{ style: 'currency' }} currency={priceUnit || currency} value={text} /> : '---'
}

const renderNumber = (text, record, index) => {
  return (text) ? <FormattedNumber value={text} /> : '---'
}

const renderCount = (text, record, index) => {
  return Array.isArray(text) ? <FormattedNumber value={text.length} /> : '---'
}

const renderTemp = (text, record, index) => {
  return <ThermometerEx />
}

const renderTemperature = (text, record, index) => {
  const { temperatureUnit = 'C' } = record
  return <>
    <ThermometerEx /> {text} {temperatureUnit === 'C' ? '°C' : '°F'}
    </>
}

const renderFlag = (text, record, index) => {
  return <FlagEx />
}

const renderScannedQtyUOM = (text, record, index) => {
  return `${record.scannedQuantity} ${record.UOM}`
}

const renderQtyUOM = (text, record, index) => {
  return `${record.shipmentQuantity} ${record.UOM}`
}

const renderDate = (text, record, index) => {
  return text ? dateFormatter(text) : '--'
}

const renderDateTime = (text, record, index) => {
  return dateTimeFormatter(text)
}

const dateSorter = (dateFieldName) => {
  return (current, next) => {
    return moment(current[dateFieldName]) - moment(next[dateFieldName])
  }
}

const customSorter = (fieldName) => {
  return (current, next) => {
    const currentVal = get(current, fieldName)
    const nextVal = get(next, fieldName)
    if (currentVal && isNaN(currentVal) && typeof currentVal === 'string') {
      return currentVal.localeCompare(nextVal)
    } else {
      return currentVal - nextVal
    }
  }
}

const identificationSorter = (fieldName) => {
  return (current, next) => {
    const currentObj = get(current, fieldName)
    const nextObj = get(next, fieldName)

    const currentKey = Object.keys(currentObj)[0]
    const currentValue = currentObj[currentKey]

    const nextKey = Object.keys(nextObj)[0]
    const nextValue = nextObj[nextKey]
    return currentValue.localeCompare(nextValue)
  }
}

const renderCustomerName = function () {
  const { searchBar: { companies = [] }, user } = store.getState()
  // include self id also for name lookup
  const { tenant: { id, name } = {} } = user
  const all = companies.slice()
  all.push({ id, name })

  return (text) => {
    const customerLookUp = keyBy(all, 'id')
    const customer = customerLookUp[text]
    return customer ? customer.name : text
  }
}

const renderProductName = function (products) {
  return (text) => {
    const productLookup = keyBy(products, 'id')
    const product = productLookup[text]
    return product ? product.name : text
  }
}

const renderIdentification = function (instance) {
  const key = Object.keys(instance)[0]
  const fullValue = instance[key]
  return `${key.toUpperCase()}: ${fullValue}`
}

const renderState = (text, record, index) => record.state && record.state.exception ? <OrangeText> {text} </OrangeText> : <span>{text}</span>

const Link = styled.a`
  cursor: pointer;
  width: 100%;
`

const renderLink = (text, record, index) => {
  return <Link>{text}</Link>
}

export default {
  TableStyled,
  renderCurrency,
  renderNumber,
  renderDate,
  renderLink,
  renderTemp,
  renderTemperature,
  renderFlag,
  renderScannedQtyUOM,
  dateSorter,
  customSorter,
  identificationSorter,
  renderQtyUOM,
  renderCustomerName,
  renderProductName,
  renderDateTime,
  renderCount,
  renderState,
  renderIdentification
}
