import React, { Component } from 'react'
import { Icon, Layout, Tooltip, Select, Skeleton, Popover } from 'antd'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { connect } from 'react-redux'
import UserDropdownMenu from './UserDropdownMenu'
import Logo from '../../../assets/logo_drakes_drink.jpg'
import { changeLocale } from '../../../redux/modules/locale/actions'
import NotificationPannel from './notificationPannel'
import { FormattedMessage } from 'react-intl'

const { Header } = Layout

const { Option } = Select

const HeaderEx = styled(Header)`
    background-color: white;
    padding: 0;
    display: flex;
    box-shadow: 0 0 1px #333;
    z-index:10;
    height: 60px;
    line-height: 60px;
`

const IconEx = styled(Icon)`
    padding: 0  20px;
    font-size: 22px;
    cursor: pointer;
    color: #bdbdbd;
`
const GlobeIcon = styled(IconEx)`
  font-size: 21px;
`

const LogoContainer = styled.div`

`

const LogoEx = styled.img`
  height: 40px;
  margin-left: 10px;
`

const UserContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    padding-right: 30px;
`

const SelectContainer = styled.div`
    margin-right: 20px;
`

const SelectDropdown = styled(Select)`
    width:100px;
`

class AppHeader extends Component {
  handleSelectChange = value => {
    this.props.onChangeLocale(value)
  }

  render () {
    const { user, locale, languages } = this.props
    const { tenantLogoUrl, logoLoading } = user
    const title = (<FormattedMessage id='selectLangauge' defaultMessage='Select Language' />)
    const selectLangauge = (<SelectContainer>
      <SelectDropdown
        onChange={this.handleSelectChange}
        defaultValue={locale}
      >
        {Object.keys(languages).map((locale) => {
          return <Option key={locale} value={locale}>{languages[locale]}</Option>
        })}
      </SelectDropdown>
    </SelectContainer>)

    return <HeaderEx>
      <LogoContainer>
        {logoLoading ? <Skeleton active /> : <LogoEx src={tenantLogoUrl || Logo} alt='image' />}
      </LogoContainer>
      <UserContainer>
        <Popover placement='bottomRight' content={selectLangauge} trigger='click' title={title}>
          <GlobeIcon type='global' onClick={this.togglePopOver} />
        </Popover>
        <NotificationPannel />
        <Tooltip placement='bottom' title='Help Information'>
          <IconEx type='question-circle-o' />
        </Tooltip>
        <UserDropdownMenu user={user} />
      </UserContainer>
    </HeaderEx>
  }
}

const mapStateToProps = ({ user, locale: { locale, languages } }) => {
  return {
    user,
    locale,
    languages
  }
}

const mapDispatchToProps = {
  onChangeLocale: changeLocale
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeader))
