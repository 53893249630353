import React, { Component } from 'react'
import { Typography, Form, Modal, Input, Icon, message, Spin } from 'antd'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearSuccess, doCheckOldPassWord } from '../../../redux/modules/user/actions'
import { clearErrors } from '../../../redux/modules/app/actions'
const { Text } = Typography
const IconEx = styled(Icon)`
    font-size: 16px;
    cursor: pointer;
    color: #888;
`
const ChangePassForm = styled(Form)`
  margin:  auto;
  max-width: 300px;
  text-align:center;
`
class ChangePassword extends Component {
  formSubmitHandler () {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { password, newPassword } = values
        const { doCheckOldPassWord, email: username } = this.props
        doCheckOldPassWord({ password, newPassword, username })
      }
    })
  }
  modalCancelHandler () {
    this.props.clearErrors()
    this.props.onCancel()
  }
  showMessage (msg) {
    message.info(msg)
  }
  textFieldChangeHandler (e) {
    if (this.props.changePassError) {
      this.props.clearErrors()
    }
  }
  componentDidUpdate () {
    if (this.props.isChangePassSuccess) {
      this.showMessage('Password changed successfully')
      this.props.clearSuccess()
      this.props.clearErrors()
      this.props.form.resetFields()
      this.props.onCancel()
    }
  }
  render () {
    const { form: { getFieldDecorator }, changePassloading, changePassError } = this.props
    return (<Modal
      destroyOnClose
      title='Change Password'
      visible={this.props.isVisible}
      onOk={() => this.formSubmitHandler()}
      okText='Submit'
      onCancel={() => this.modalCancelHandler()}
    >
      <Spin spinning={changePassloading}>
        <ChangePassForm onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your old Password!' }]
            })(
              <Input
                onChange={() => this.textFieldChangeHandler()}
                prefix={<IconEx type='lock' />}
                type='password'
                placeholder='Old Password'
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('newPassword', {
              rules: [{ required: true, message: 'Please input your new Password!' }]
            })(
              <Input
                onChange={() => this.textFieldChangeHandler()}
                prefix={<IconEx type='lock' />}
                type='password'
                placeholder='New Password'
              />
            )}
          </Form.Item>
          <Text type='danger'>{changePassError}</Text>
        </ChangePassForm>
      </Spin>
    </Modal>)
  }
}
const mapStateToProps = ({ user: { email, isChangePassSuccess, changePassloading }, app: { changePassError } }) => {
  return {
    email,
    changePassloading,
    changePassError,
    isChangePassSuccess
  }
}
const mapDispatchToProps = {
  doCheckOldPassWord: doCheckOldPassWord,
  clearErrors: clearErrors,
  clearSuccess: clearSuccess
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'change_password' })(ChangePassword)))
