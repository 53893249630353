import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { Typography, Form, Icon, Input, Button, Spin, Select } from 'antd'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { doLogin, isAuthenticated } from '../../../redux/modules/user/actions'
import SmartCosmosLogo from '../../../assets/smartcosmos_logo.svg'
import { FormattedMessage } from 'react-intl'
import { changeLocale } from '../../../redux/modules/locale/actions'

const { Text } = Typography

const { Option } = Select

const IconEx = styled(Icon)`
  color: rgba(0,0,0,.25);
`

const LoginForm = styled(Form)`
  margin:  auto;
  max-width: 300px;
  margin-top: 100px;
  text-align:center;
`

const ForgetPasswordLink = styled.a`
  float: right;
`

const LoginButton = styled(Button)`
  width: 100%;
`

const Logo = styled.img`
  margin-bottom: 20px;
`

const SelectContainer = styled.div`
  right: 20px;
  position: fixed;
  width: 100px;
  display: grid;
  top: 20px;
`

type Props = {
  onChangeLocale: (value: string) => any,
  intl: any,
  locale: string,
  languages: Array<string>
};

class Login extends Component<Props> {
handleSubmit = e => {
  e.preventDefault()
  this.props.form.validateFields((err, values) => {
    if (!err) {
      const { username, password } = values
      const { doLogin, history } = this.props
      doLogin({ username, password, history })
    }
  })
}
handleSelectChange = value => {
  this.props.onChangeLocale(value)
}

render () {
  const { locale, languages } = this.props
  if (isAuthenticated()) {
    return <Redirect to='/' />
  }

  const { form: { getFieldDecorator }, loading, loginError, version } = this.props
  return (
    <Spin spinning={loading}>
      <LoginForm onSubmit={this.handleSubmit}>
        <Logo src={SmartCosmosLogo} alt='Smart Cosmos Logo' />
        <Form.Item>
          <Text type='danger'>{loginError}</Text>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not valid E-mail!' }
            ]
          })(
            <Input
              prefix={<IconEx type='user' />}
              placeholder='Username'
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }]
          })(
            <Input
              prefix={<IconEx type='lock' />}
              type='password'
              placeholder='Password'
            />
          )}
        </Form.Item>
        <Form.Item>
          <ForgetPasswordLink href='/forgotPassword'>
            <FormattedMessage id='forgotPassword' defaultMessage='Forgot Password' />
          </ForgetPasswordLink>
          <LoginButton type='primary' htmlType='submit'>
            <FormattedMessage id='login' defaultMessage='Log in' />
          </LoginButton>
        </Form.Item>
        <Text strong>
          <FormattedMessage id='version' defaultMessage='VERSION' /> {version}</Text>
      </LoginForm>
      <SelectContainer>
        <Select
          onChange={this.handleSelectChange}
          defaultValue={locale}
        >
          {Object.keys(languages).map((locale) => {
            return <Option key={locale} value={locale}>{languages[locale]}</Option>
          })}
        </Select>
      </SelectContainer>
    </Spin>
  )
}
}

const mapStateToProps = ({ app: { loginError, version }, locale: { locale, languages }, user: { loading } }) => {
  return {
    locale,
    languages,
    loading,
    loginError,
    version
  }
}

const mapDispatchToProps = {
  onChangeLocale: changeLocale,
  doLogin: doLogin
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'normal_login' })(Login)))
