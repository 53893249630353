// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Empty } from 'antd'
import Dashboard from '../dashboard'
import OrderDetail from '../orderDetail'
import ShipmentDetail from '../shipmentDetail'
import LoadDetail from '../loadDetail'
import CaseDetail from '../caseDetail'
import itemDetail from '../itemDetail'
import batchDetail from '../batchDetail'
import Profile from '../profile'
import DataMgmt from '../dataMgmt'
import AlertsDetails from '../../components/Alerts/alertsDetails'
import Analytics from '../analytics'

type Props = {
  context: Object,
  pages: Array<{ path: string, Component: Function }>,
  defaultPage: string,
};

const AppRouter = ({ context, pages, defaultPage }: Props) => (
  <Switch>
    {pages.map(({ path, Component }, index) => (
      <Route
        key={path}
        strict={false}
        path={path}
        render={
          () => <Component {...context} />
        }
      />
    ))}
    <Route exact path='/' render={props => <Redirect to={defaultPage} />} />
    <Route component={Empty} />
  </Switch>
)

const basePages = [
  {
    path: '/dashboard',
    Component: Dashboard
  },
  {
    path: '/orderDetail/:orderNumber',
    Component: OrderDetail
  },
  {
    path: '/loadDetail/:loadNumber',
    Component: LoadDetail
  },
  {
    path: '/shipment/:shipmentNumber',
    Component: ShipmentDetail
  },
  {
    path: '/caseDetail/:caseNumber',
    Component: CaseDetail
  },
  {
    path: '/itemDetail/:itemNumber',
    Component: itemDetail
  },
  {
    path: '/batchDetail/:batchNumber',
    Component: batchDetail
  },
  {
    path: '/analytics',
    Component: Analytics
  },
  {
    path: '/administration',
    Component: Empty
  },
  {
    path: '/dataManagement',
    Component: DataMgmt
  },
  {
    path: '/profile',
    Component: Profile
  },
  {
    path: '/alerts/details',
    Component: AlertsDetails
  }
]

const mapStateToProps = ({ user }) => {
  return {
    defaultPage: basePages[0].path,
    pages: basePages,
    context: { user }
  }
}

export default withRouter(connect(mapStateToProps)(AppRouter))
