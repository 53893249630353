import React from 'react'
import { Redirect } from 'react-router-dom'
import { Layout } from 'antd'
import styled from 'styled-components'
import { Sidebar, Header } from '../../components'
import AppRouter from './AppRouter'
import { isAuthenticated } from '../../../redux/modules/user/actions'

const { Content } = Layout

const LayoutEx = styled(Layout)`
    height: 100vh;
    overflow: hidden;
`

const ContentContainer = styled(Content)`
   z-index:1;
   border:none;
   background-color: #f2f2f2;
`

const AppContent = styled.div`
    // padding: 10px;
`

class App extends React.Component {
  render () {
    if (!isAuthenticated()) {
      return <Redirect to='/login' />
    }
    return <LayoutEx>
      <Header />
      <Layout>
        <Sidebar />
        <ContentContainer>
          <AppContent><AppRouter /></AppContent>
        </ContentContainer>
      </Layout>
    </LayoutEx>
  }
}

export default App
