import Utility from './utility'
import CONST from './constants'

const { isUuidValid, isDateValid, requiredError, validateError, headerError } = Utility
const { SHIPMENT_STATUS, DELIVERY_STATUS, REJECTION_STATUS } = CONST

const LoadsConfig = {
  headers: [
    {
      name: 'number',
      inputName: 'number',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'number')
    },
    {
      name: 'tenantId',
      inputName: 'tenantId',
      unique: false,
      validate: tenantId => isUuidValid(tenantId),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, 'should be valid uuid')
    },
    {
      name: 'carrier',
      inputName: 'carrier',
      unique: false,
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'carrier')
    },
    {
      name: 'status',
      inputName: 'status',
      unique: false,
      validate: (status) => SHIPMENT_STATUS.includes(status.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${SHIPMENT_STATUS.join()}]`)
    },
    {
      name: 'shippedDate',
      inputName: 'shippedDate',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'shippedDate'),
      validate: shippedDate => isDateValid(shippedDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`)
    },
    {
      name: 'deliveryDate',
      inputName: 'deliveryDate',
      unique: false,
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'deliveryDate'),
      validate: deliveryDate => isDateValid(deliveryDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`)
    },
    {
      name: 'plannedDeliveryDate',
      inputName: 'plannedDeliveryDate',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'plannedDeliveryDate'),
      validate: plannedDeliveryDate => isDateValid(plannedDeliveryDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`)
    },
    {
      name: 'notes',
      inputName: 'notes',
      unique: false
    },
    {
      name: 'deliveryStatus',
      inputName: 'deliveryStatus',
      unique: false,
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'deliveryStatus'),
      validate: (deliveryStatus) => DELIVERY_STATUS.includes(deliveryStatus.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${DELIVERY_STATUS.join()}]`)
    },
    {
      name: 'rejectionStatus',
      inputName: 'rejectionStatus',
      unique: false,
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'rejectionStatus'),
      validate: (rejectionStatus) => REJECTION_STATUS.includes(rejectionStatus.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${REJECTION_STATUS.join()}]`)
    },
    {
      name: 'trailerId',
      inputName: 'trailerId',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'trailerId')
    }
  ]
}
export default LoadsConfig
