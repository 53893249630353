import Status from '../../components/Status'
import { FormattedNumber } from 'react-intl'
import React from 'react'
import Information from '../../components/EntityDetailView/Information'

export default function (props) {
  const { data, documentId } = props
  const { deliveryStatus, status, rejectionStatus, priceUnit, price, salesPerson, poReference } = data

  const headerInfo = { label: 'Order Status', documentId: documentId, status: status }
  const listOfInfo = [
    {
      label: 'Order Status',
      value: <Status status={status} />
    }, {
      label: 'Delivery Status',
      value: <Status status={deliveryStatus} />
    }, {
      label: 'Rejection Status',
      value: <Status status={rejectionStatus} />
    }, {
      label: 'Order Total',
      value: <FormattedNumber {...{ style: 'currency' }} currency={priceUnit || 'USD'} value={price} />
    }, {
      label: 'Salesman',
      value: salesPerson,
      isFooter: true
    }, {
      label: 'Customer PO',
      value: poReference || 'Unavailable',
      isFooter: true
    }
  ]

  return <Information listOfInfo={listOfInfo} headerInfo={headerInfo} />
}
