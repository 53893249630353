import React, { useState } from 'react'
import { Table } from 'antd'
import _ from 'lodash'
import { TableUtils } from '../../components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { doLoadShipmentItems } from '../../../redux/modules/entities/actions'
import ShipmentTable from '../dashboard/tables/ShipmentTable'
const { renderCurrency, renderDate, dateSorter, customSorter } = TableUtils

const columns = [
  { title: 'Item', dataIndex: 'number', key: 'id', sorter: customSorter('number') },
  { title: 'Product Code', dataIndex: 'productCode', key: 'productCode', sorter: customSorter('productCode') },
  { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: 'center', sorter: customSorter('quantity') },
  { title: 'Order Value', dataIndex: 'orderValue', key: 'orderValue', render: renderCurrency, sorter: customSorter('orderValue') },
  { title: 'Status', dataIndex: 'status', key: 'status', default: 'N/A', sorter: customSorter('status') },
  { title: 'Rejection Status', dataIndex: 'rejectionStatus', key: 'rejectionStatus', sorter: customSorter('rejectionStatus') },
  { title: 'Delivery Date', dataIndex: 'plannedDeliveryDate', key: 'plannedDeliveryDate', render: renderDate, sorter: dateSorter('plannedDeliveryDate') }
]

const OrderDetailTable = (props) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const { orderItems = [], loadShipmentItems, loading } = props

  const orderItemsForTable = orderItems.map((orderItem) => {
    const { price, quantity, productCode } = orderItem

    return {
      ...orderItem,
      number: productCode,
      orderValue: price * quantity
    }
  })

  return (
    <Table
      scroll={{ y: 'auto' }}
      columns={columns}
      dataSource={orderItemsForTable}
      expandedRowKeys={expandedRowKeys}
      size='small'
      rowKey='id'
      loading={loading}
      onExpand={(expanded, record) => {
        const { id } = record
        if (expanded) {
          setExpandedRowKeys([id])
          loadShipmentItems({ orderItemId: id })
        } else {
          setExpandedRowKeys([])
        }
      }}
      expandedRowRender={(record, index, indent, expanded) => {
        const { shipments = [], shipmentItems, loading } = props
        const relatedShipmentItemIds = _.filter(shipmentItems, (item) => item.orderItemId === record.id).map((item) => item.shipmentId)
        const relatedShipments = _.filter(shipments, (item) => relatedShipmentItemIds.includes(item.id))

        return <ShipmentTable {...props} dataSource={relatedShipments} loading={loading} isDetailsTable />
      }}
    />
  )
}

const mapStateToProps = ({ entities: { loading, orderItems, shipments, shipmentItems } }) => {
  return {
    shipments,
    orderItems,
    shipmentItems,
    loading: loading
  }
}

const mapDispatchToProps = {
  loadShipmentItems: doLoadShipmentItems
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetailTable))
