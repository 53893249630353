// @flow
import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Row, Col, Tooltip } from 'antd'
import { FormattedMessage } from 'react-intl'

const QuickInfoContainer = styled.div`
`
const Label = styled.span`
  color: #212121;
  font-weight: normal;
  font-size: 13px;
`
const Value = styled.span`
  color: ${({ color }) => color};
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
`
const ColEx = styled(Col)`
    padding: 5px 12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const StatusContainer = styled.div`
  border-radius: 0px;
  background-color: white;
  height: 36vh;
  @media (max-height: 768px) {
    height: 29vh;
  }
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    width: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
`

const RowEx = styled(Row)``

const InfoHeader = styled.div`
  padding: 11.5px;
`
const BoldText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: normal;
  color: #212121;
  font-size: 15px;
`

type Props = {
  listOfInfo: Array<{
      label: string,
      value: any,
      inFooter: boolean
    }>
}

const LoadInformation = function (props: Props) {
  const { listOfInfo = [] } = props
  const itemsOnTop = _.filter(listOfInfo, (item) => !item.isFooter)
  const itemsInFooter = _.filter(listOfInfo, (item) => item.isFooter)

  return <QuickInfoContainer>
    <InfoHeader>
      <Row>
        <Col span={16}>
          <BoldText><FormattedMessage id='quickInfo' defaultMessage='Quick Info' /></BoldText>
        </Col>
      </Row>
    </InfoHeader>
    <RowEx>
      <StatusContainer>
        { itemsOnTop.map(({ label, value, color = '#757575' }) => {
          return <ColEx key={label}>
            <Label>{label}</Label>
            <Value color={color}><Tooltip title={value}>{value}</Tooltip></Value>
          </ColEx>
        })}
        {itemsInFooter.map(({ label, value, color = '#757575' }) => {
          return <ColEx key={label}>
            <Label>{label}</Label>
            <Value><Tooltip title={value}>{value}</Tooltip></Value>
          </ColEx>
        })}
      </StatusContainer>
    </RowEx>
  </QuickInfoContainer>
}

export default (LoadInformation)
