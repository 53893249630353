import ordersConfig from './OrdersConfig'
import loadsConfig from './LoadsConfig'
import productsConfig from './ProductsConfig'
import companiesConfig from './CompaniesConfig'
import shipmentsConfig from './ShipmentsConfig'

const validationConfig = {
  ordersConfig,
  loadsConfig,
  productsConfig,
  companiesConfig,
  shipmentsConfig
}

export default validationConfig
