import documentImportActions from './actions'

const initialState = {
  isUploading: false,
  docUploadStatus: null,
  uploadErrorMessage: '',
  error: null,
  loading: false,
  data: [],
  pageSize: 10,
  pageNumber: 1
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case documentImportActions.DOCUMENTS_PAGE_SIZE_CHANGE: {
      return {
        ...state,
        pageSize: action.data
      }
    }
    case documentImportActions.UPLOAD_INIT: {
      return { ...state, isUploading: true, docUploadStatus: null }
    }
    case documentImportActions.UPLOAD_FAILURE: {
      return { ...state, isUploading: false, docUploadStatus: 'fail', uploadErrorMessage: action.payload }
    }
    case documentImportActions.UPLOAD_SUCCESS: {
      return { ...state, isUploading: false, docUploadStatus: 'success' }
    }
    case documentImportActions.UPLOAD_RESET: {
      return { ...state, isUploading: false, docUploadStatus: null, uploadErrorMessage: '' }
    }
    case documentImportActions.FETCH_START:
      return {
        ...state,
        loading: true
      }
    case documentImportActions.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        paging: {
          ...action.paging
        },
        loading: false
      }
    case documentImportActions.FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case documentImportActions.DOWNLOAD_SUCCESS:
      return {
        ...state,
        success: action.payload
      }
    case documentImportActions.DOWNLOAD_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}
export default reducer
