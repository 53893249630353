// fetch polyfill
import 'whatwg-fetch'
import store from '../../src'
import { isLogin } from '../redux/modules/user/actions'

// Client-side timeout for requests
export const REQUEST_TIMEOUT = 30000

export function timedRequest (ms, promise) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request Timeout'))
    }, ms)
    promise.then(
      res => {
        clearTimeout(timeoutId)
        resolve(res)
      },
      err => {
        clearTimeout(timeoutId)
        reject(err)
      }
    )
  })
}

const checkStatus = response => {
  const isDownloadResponse = response.headers.has('Content-Disposition')
  const status = response.status
  // Everything is all well and good
  if (status === 204) {
    return { success: true }
  }
  if (status >= 200 && status < 300) {
    return isDownloadResponse ? response.blob() : response.json()
  } else if (status === 401) {
    store.dispatch({
      type: 'user/LOGOUT'
    })
    const error = new Error('Incorrect username or password')
    error.code = 401
    throw error
    // Validation error
  } else if (status >= 400 && status < 500) {
    return response.json()
  } else {
    // Something very bad happened
    const error = new Error(
      'There was an error while processing your request! Please try again later.'
    )
    error.code = 500
    error.response = response
    throw error
  }
}

// please refer to https://github.com/github/fetch/issues/256
// this will polyfill to support passing query parameter in an object
function formatQueryParams (params) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
}

export function request (url, options, type = 'DEFAULT', timeout = REQUEST_TIMEOUT) {
  return new Promise((resolve, reject) => {
    if (!url) reject(new Error('Request url is a required field'))
    if (!options) reject(new Error('Request options is a required field'))
    const reqOptions = {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      ...options
    }

    if (options.queryParams) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + formatQueryParams(options.queryParams)
      delete options.queryParams
    }

    timedRequest(timeout, fetch(url, reqOptions))
      .then(response => type === 'CHECK_OLD_PASSWORD' ? response.json() : checkStatus(response))
      .then(resolve)
      .catch(reject)
  })
}
/*
* A request specifically tied to our api that requires authentication headers
* Dynamically injects the jwt from the app state
*/
export function apiRequest (url, options, timeout) {
  // We could adapt this to be injected during middleware
  // but then the request payloads need to be bindable
  // check isAuthorized including token expiry before calling API
  if (isLogin()) {
    const accessToken = localStorage.accessToken
    return request(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      timeout
    }).catch(error => {
      throw new Error(error.message)
    })
  } else {
    store.dispatch({ type: 'user/LOGOUT' })
  }
}
export function documentApi (url, options, timeout) {
  if (isLogin()) {
    const accessToken = localStorage.accessToken
    return request(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      timeout
    }).catch(error => {
      throw new Error(error.message)
    })
  } else {
    store.dispatch({ type: 'user/LOGOUT' })
  }
}

export default function downloadRequest (url, options, fileName) {
  let header = null
  return new Promise((resolve, reject) => {
    if (!url) reject(new Error('Request url is a required field'))
    const reqOptions = {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      ...options
    }
    fetch(url, reqOptions)
      .then((response) => {
        header = response.headers.get('content-type')
        return response.blob()
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response)
        const link = document.createElement('a')
        link.href = url
        if (fileName) {
          link.setAttribute('download', `${fileName}`)
          link.click()
          URL.revokeObjectURL(link)
        } else {
          return { contentType: header, url }
        }
      })
      .then(resolve)
      .catch(reject)
  })
}

export function downloadDocumentApi (url, options, fileName) {
  if (isLogin()) {
    const accessToken = localStorage.accessToken
    return downloadRequest(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }, fileName).catch(error => {
      throw new Error(error.message)
    })
  } else {
    store.dispatch({ type: 'user/LOGOUT' })
  }
}

export function previewRequest (url, options, fileName) {
  return new Promise((resolve, reject) => {
    if (!url) reject(new Error('Request url is a required field'))
    const reqOptions = {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      ...options
    }
    fetch(url, reqOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        window.open(url, 'download_window', 'toolbar=0,location=no,directories=0,status=0,scrollbars=1,resizeable=1,width=1000,height=600,top=0,left=0')
        window.focus()
      })
      .then(resolve)
      .catch(reject)
  })
}

export function previewDocumentApi (url, options, fileName) {
  if (isLogin()) {
    const accessToken = localStorage.accessToken
    return previewRequest(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }, fileName).catch(error => {
      throw new Error(error.message)
    })
  } else {
    store.dispatch({ type: 'user/LOGOUT' })
  }
}
