import searchbarActions from './actions'

const initialState = {
  products: [],
  companies: [],
  loading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case searchbarActions.PRODUCTS_FETCH_SUCCESS: {
      return { ...state, products: action.payload, loading: false }
    }
    case searchbarActions.PRODUCTS_FETCH_FAILURE: {
      return { ...state, error: action.payload, loading: false }
    }
    case searchbarActions.PRODUCTS_FETCH_INIT: {
      return { ...state, loading: true }
    }
    case searchbarActions.COMPANIES_FETCH_SUCCESS: {
      return { ...state, companies: action.payload, loading: false }
    }
    case searchbarActions.COMPANIES_FETCH_FAILURE: {
      return { ...state, error: action.payload, loading: false }
    }
    case searchbarActions.COMPANIES_FETCH_INIT: {
      return { ...state, loading: true }
    }
    default:
      return state
  }
}

export default reducer
