import React, { Component } from 'react'
import styled from 'styled-components'
import orderBy from 'lodash/orderBy'
import { Link } from 'react-router-dom'
import { Icon, Popover, Badge, Divider } from 'antd'
import { connect } from 'react-redux'
import { getAllAlerts } from '../../../redux/modules/alerts/actions'
import { dateTimeFormatter } from '../../../utility'

const BadgeEx = styled(Badge)`
  .ant-badge-count{
    background: #2AC940;
    padding: 0px 5px;
  }
`
const IconEx = styled(Icon)`
    font-size: 22px;
    cursor: pointer;
    color: #bdbdbd;
`

const NotificationContainer = styled.div`
    width: 350px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #ddd;
`

const NotificationHeader = styled.div`
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 10px 0px;
`
const DismissButton = styled.div`
    cursor: pointer;
    color: #3570FF;
    font-size: 13px;
`
const Title = styled.div`
    font-size: 13px;
    font-weight: normal;
    color: #212121;
`

const ViewAllButton = styled.div`
    font-size: 11px;
    color: #2593FC;
    text-align: center;
    padding: 9px 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
    background-color: white;
`
const OuterCard = styled.div`
  background-color: white;
  padding: 2px;
`
const DividerEx = styled(Divider)`
  margin:0;
`
const NotificationCard = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    border-left: 4px solid ${props => (props.cardtype === '1' ? '#73C5C5' : '#F0AB00')};
    padding: 4px 8px;
    background-color: white;
`

const ContentLeft = styled.div`
    flex: 1;
    padding: 0 15px;
    font-size: 12px;
    color: #000000;
`
const ContentRight = styled.div`
    font-size: 11px;
    color: #757575;
`
const IconContainer = styled.div`
    background-color: ${props => (props.cardtype === '1' ? '#73C5C5' : '#F0AB00')};
    border-radius: 50%;
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
`

const CardIcon = styled(Icon)`
    font-size: 10px;
    color: ${props => (props.cardtype === '1' ? '#559292' : 'b27f00')};
`

const CardContainer = styled.div``

const ALERT_REFRESH_INTERVAL = 60 * 1000 // one-minute

class NotificationPannel extends Component {
  state = {
    showPopover: false
  }
   handleDismiss = () => {
     console.log('clicked')
   }
   togglePopOver = () => {
     this.setState({ showPopover: !this.state.showPopover })
   }

   loadAlerts = () => {
     this.props.getAllAlerts()
     setTimeout(this.loadAlerts, ALERT_REFRESH_INTERVAL)
   }

   componentDidMount () {
     this.loadAlerts()
   }

   render () {
     let title = <span />; let content = <span />; let count = 0
     const { notifications, paging: { totalElements } = {} } = this.props
     if (notifications) {
       count = totalElements
       title = (
         <NotificationHeader>
           <Title>
              Notifications ({totalElements})
           </Title>
           <DismissButton onClick={this.handleDismiss}>Dismiss All</DismissButton>
         </NotificationHeader>
       )

       content = (
         <NotificationContainer>
           <CardContainer>
             { notifications.slice(0, 5).map((notification, index) => {
               return (
                 <div key={index}>
                   <OuterCard>
                     <NotificationCard cardtype={notification.type} >
                       <IconContainer cardtype={notification.type}>
                         <CardIcon cardtype={notification.type} type={notification.type === '1' ? 'bell' : 'exclamation'} />
                       </IconContainer>
                       <ContentLeft>
                         {notification.context && notification.context.failure ? notification.context.failure.reason : ''}
                       </ContentLeft>
                       <ContentRight>{dateTimeFormatter(notification.createdAt)}</ContentRight>
                     </NotificationCard>
                   </OuterCard>
                   <DividerEx />
                 </div>
               )
             })
             }
           </CardContainer>
           <Link to='/alerts/details'>
             <ViewAllButton onClick={this.togglePopOver}>
                View All
             </ViewAllButton>
           </Link>
         </NotificationContainer>
       )
     }
     return (
       <BadgeEx className='site-badge-count-109' count={count}>
         <Popover
           visible={notifications && notifications.length > 0 && this.state.showPopover}
           placement='bottomRight'
           content={content}
           trigger='click'
           title={title}
           onVisibleChange={this.togglePopOver}
         >
           <IconEx type='bell' />
         </Popover>
       </BadgeEx>
     )
   }
}

const mapDispatchToProps = {
  getAllAlerts
}

const mapStateToProps = ({ alerts: { notifications, paging } }) => {
  return {
    notifications: orderBy(notifications, 'createdAt', 'desc'),
    paging
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPannel)
