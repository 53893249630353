import { put, call, all, takeLatest } from 'redux-saga/effects'
import authActions from './actions'
import { request, apiRequest, downloadDocumentApi } from '../../../utility/fetch'
import { BASE_URLS } from '../../../environment/api'
import store from '../../../../src'

function * doLogin (action) {
  const { username, password, history } = action.data
  try {
    const response = yield call(request, `${BASE_URLS.AUTH_URL}/authorize`, {
      method: 'POST',
      body: JSON.stringify({ username, password })
    })
    if (response.success) {
      yield put({ type: authActions.LOGIN_SUCCESS, payload: response.data })

      // Fetch tenant's information
      yield put({ type: authActions.TENANT_INFO })
    } else {
      yield put({ type: authActions.LOGIN_ERROR, payload: response.message })
      yield put({ type: authActions.LOGIN_FAIL })
    }
    // get tenantDetails
    history.push('/')
  } catch (err) {
    yield put({ type: authActions.LOGIN_ERROR, payload: err.message })
    yield put({ type: authActions.LOGIN_FAIL })
  }
}

function * doCheckOldPassword (action) {
  const { username, password, newPassword } = action.data
  const credentials = { username: username, password: password, newPassword: newPassword }
  try {
    const response = yield call(request, `${BASE_URLS.AUTH_URL}/authorize`, {
      method: 'POST',
      body: JSON.stringify({ username, password })
    }, 'CHECK_OLD_PASSWORD')
    if (response.success) {
      yield put({ type: authActions.CHECK_OLD_PASSWORD_SUCCESS, payload: response.data })
      yield put({ type: authActions.LOGIN_SUCCESS, payload: response.data })
      yield put({ type: authActions.CHANGE_PASSWORD, data: credentials })
    } else {
      yield put({ type: authActions.CHECK_OLD_PASSWORD_ERROR, payload: 'Invalid old password' })
      yield put({ type: authActions.CHECK_OLD_PASSWORD_FAIL })
    }
  } catch (err) {
    yield put({ type: authActions.CHECK_OLD_PASSWORD_ERROR, payload: err.message })
    yield put({ type: authActions.CHECK_OLD_PASSWORD_FAIL })
  }
}

function * doChangePasswordSaga (action) {
  const { username, newPassword } = action.data
  try {
    const response = yield call(apiRequest, `${BASE_URLS.USERS_URL}/${username}/password/update`, {
      method: 'POST',
      body: JSON.stringify({ 'newPassword': newPassword })
    })
    if (response.success) {
      yield put({ type: authActions.CHANGE_PASSWORD_SUCCESS, payload: response.data })
    } else {
      yield put({ type: authActions.CHANGE_PASSWORD_ERROR, payload: response.message })
      yield put({ type: authActions.CHANGE_PASSWORD_FAIL })
    }
  } catch (err) {
    yield put({ type: authActions.CHANGE_PASSWORD_ERROR, payload: err.message })
    yield put({ type: authActions.CHANGE_PASSWORD_FAIL })
  }
}

function * fetchTenantInfo (action) {
  const { app_metadata: { tenantId, locationId } } = store.getState().user
  try {
    const [tenantInfoResponse, locationResponse] = yield all([
      call(apiRequest, `${BASE_URLS.TENANTS_URL}/${tenantId}`, {
        method: 'GET'
      }),
      call(apiRequest, `${BASE_URLS.LOCATIONS_URL}/${locationId}`, {
        method: 'GET'
      })
    ])
    if (tenantInfoResponse.success && locationResponse.success) {
      yield put({ type: authActions.TENANT_INFO_SUCCESS,
        payload: { tenantInfo: tenantInfoResponse.data, location: locationResponse.data }
      })
      // initial load tenant logo from document id
      yield put({ type: authActions.LOAD_LOGO, data: { documentId: tenantInfoResponse.data.documentId } })
    } else {
      yield put({ type: authActions.TENANT_INFO_FAIL, payload: `${tenantInfoResponse.message}, ${locationResponse.message} ` })
    }
  } catch (err) {
    yield put({ type: authActions.TENANT_INFO_FAIL, payload: err.message })
  }
}

function * loadTenantLogo (action) {
  const { documentId } = action.data
  try {
    const response = yield call(downloadDocumentApi, `${BASE_URLS.API_PLATFORM_DOCUMENTS_URL}/documents/${documentId}/download`, {
      method: 'GET'
    })
    if (response.contentType && response.contentType.includes('image')) {
      yield put({ type: authActions.LOAD_LOGO_SUCCESS, payload: { logoUrl: response.url } })
    } else {
      yield put({ type: authActions.LOAD_LOGO_FAIL, payload: 'Invalid' })
    }
  } catch (err) {
    console.log('error while documents fetch', err)
    yield put({ type: authActions.LOAD_LOGO_FAIL, payload: err })
  }
}

export default function * actionWatcher () {
  yield takeLatest(authActions.LOGIN, doLogin)
  yield takeLatest(authActions.CHECK_OLD_PASSWORD, doCheckOldPassword)
  yield takeLatest(authActions.CHANGE_PASSWORD, doChangePasswordSaga)
  yield takeLatest(authActions.TENANT_INFO, fetchTenantInfo)
  yield takeLatest(authActions.LOAD_LOGO, loadTenantLogo)
}
