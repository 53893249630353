// @flow
import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import EntityManagement from './EntityManagement'

const EntityContainer = styled(Col)``

type Props = {
  data: any,
  documentId: string,
  entity: string,
  subtitle: string,
  tableTitle: string,
  icon: React.Component<*>,
  information: React.Component<*>,
  showTemperature: boolean
}

export default (props: Props) => {
  const { entity } = props
  return (
    <>
      <Row>
        <EntityContainer>
          <EntityManagement title={entity} {...props} />
        </EntityContainer>
      </Row>
    </>
  )
}
