import React from 'react'
import styled from 'styled-components'
import { Select, Button, DatePicker, Row, Col, Tooltip } from 'antd'
import moment from 'moment'
import { DATE_FORMAT } from '../../../utility'

const { RangePicker } = DatePicker

const FilterContainer = styled(Row)`
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
`
const LeftCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`
const RightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`
const StyledSelect = styled(Select)`
  width: 140px;
  margin-right: 10px;
`
const Label = styled.div`
  margin: 0 10px;
`
const ButtonEx = styled(Button)`
  margin-left: 10px;
  color: #2593FC;
  .anticon{
    line-height: 2;
  }
  .ant-btn-link:hover, .ant-btn-link:focus{
    color: #2593FC;
  }
`

export default function AlertsFilters () {
  const { Option } = Select
  const dateRange = [moment(), moment()]

  return (
    <FilterContainer>
      <LeftCol span={12}>
        <StyledSelect
          showSearch
          defaultValue='All'
        >
          <Option value='All'>All</Option>
        </StyledSelect>
        <Label>Customer</Label>
        <StyledSelect
          showSearch
          defaultValue='All'
        >
          <Option value='All'>All Customers</Option>
        </StyledSelect>
      </LeftCol>
      <RightCol span={12}>
        <Label>Date</Label>
        <RangePicker
          ranges={{
            Today: [moment().startOf('day'), moment().endOf('day')],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'This Month': [moment().startOf('month'), moment().endOf('month')]
          }}
          format={DATE_FORMAT}
          value={dateRange}
        />
        <Tooltip placement='top' title='Reset filters'>
          <ButtonEx icon='reload' />
        </Tooltip>
      </RightCol>
    </FilterContainer>
  )
}
