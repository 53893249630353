import React from 'react'
import styled from 'styled-components'
import { Icon } from 'antd'
import { FormattedMessage } from 'react-intl'

const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const NotFoundIcon = styled(Icon)`
    font-size: 60px;
`

// Temporary page, need UI mockup.
export default () => {
  return <NotFoundContainer>
    <h2><FormattedMessage id='notFound!' defaultMessage='404 - Not found!' /></h2>
    <br />
    <NotFoundIcon type='meh' />
  </NotFoundContainer>
}
