import React from 'react'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import { getCompanyPostalAddress } from './utility'

const MarkerContainer = styled.div`
    width: auto;
    height: auto;
    padding: 3px 7px;
    background-color: #fff;
`
const FlexRow = styled.div`
    display: flex;
`
const FlexChild = styled.div`
    flex: 1;
    box-sizing: border-box;
    word-break: break-all;
    padding: 10px;
`
const FlexChildLeft = styled.div`
  flex: 1;
  box-sizing: border-box;
  word-break: break-all;
  min-width: 140px;
  border-right: ${prop => prop.manufacturer ? 0 : '2px solid #dbdbdb'};
`

const CustomerAddress = styled.div`
    box-sizing: border-box;
    word-break: break-word;
    color: #000000;
    font-size: 11px;
    line-height: 1.6

`
const CustomerName = styled.div`
  color: #3570ff;
  font-size: 13px;
  font-weight: bold;
`
const Customer = styled.div`
  color: #053771;
  font-size: 13px;
  text-transform: uppercase;
`

const ObjectCount = styled.div`
  color: #3570FF;
  font-size: 14px;
  text-shadow: 0 0 #3570FF;
`

const getAddress = (opt) => opt.manufacturer ? opt.data.postalAddress : getCompanyPostalAddress(opt.data)

export default function MarkerDetails (props) {
  const { addressLocality, addressRegion, postalCode, addressCountry, streetAddress } = getAddress(props.options)
  return (
    <>
      <MarkerContainer>
        <FlexRow>
          <FlexChildLeft manufacturer={props.options.manufacturer}>
            <Customer>
              {props.options.manufacturer ? 'MANUFACTURER' : 'CUSTOMER'}
            </Customer>
            <CustomerName>{props.options.data.name}</CustomerName>
            <CustomerAddress>
              {addressLocality} <br />
              {streetAddress} <br />
              {addressRegion} <br />
              {postalCode} <br />
              {addressCountry}
            </CustomerAddress>
          </FlexChildLeft>
          {
            !props.options.manufacturer &&
            <FlexChild>
              <ObjectCount>{capitalize(props.options.criteria)} ({props.options.count})</ObjectCount>
            </FlexChild>
          }
        </FlexRow>
      </MarkerContainer>
    </>
  )
}
