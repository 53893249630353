import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Upload, Button, message } from 'antd'
import { onCreateDocument, resetDocUploadVar } from '../../../redux/modules/documentImport/actions'
import { doLoadDocuments } from '../../../redux/modules/entities/actions'
import styled from 'styled-components'

const UploadButton = styled(Button)`
`
const CoaUpload = (props) => {
  const [info, setInfo] = useState(null)
  useEffect(() => {
    if (!info) props.resetDocUploadVar()
    else if (props.docUploadStatus === 'success') info.onSuccess('ok')
    else if (props.docUploadStatus === 'fail') info.onError(props.uploadErrorMessage)
  })
  const customRequest = (info) => {
    if (info.file) {
      setInfo(info)
      props.onCreateDocument({ file: info.file,
        data: JSON.stringify({
          documentType: 'COA',
          gdti: 'gdtivV',
          parent: {
            parentId: props.parentId,
            parentType: 'event'
          },
          grants: ['Create', 'Read']
        })
      })
    }
  }
  const uploadOptions = {
    showUploadList: false,
    customRequest: customRequest,
    onChange: ({ file }) => {
      if (info && file.status === 'done') {
        props.doLoadDocuments(props.parentId)
        message.success('Document Uploaded Successfully')
        setInfo(null)
      }
      if (info && file.status === 'error') {
        message.error(`Document Upload Failed: ${file.error}`)
        setInfo(null)
      }
    }
  }

  return (
      <>
        <Upload {...uploadOptions}>
          <UploadButton loading={props.isUploading}>
              Upload Document
          </UploadButton>
        </Upload>
      </>
  )
}
const mapStateToProps = ({ documentImport: { isUploading, docUploadStatus, uploadErrorMessage } }) => {
  return {
    isUploading,
    docUploadStatus,
    uploadErrorMessage
  }
}
const mapDispatchToProps = {
  onCreateDocument,
  resetDocUploadVar,
  doLoadDocuments
}

export default connect(mapStateToProps, mapDispatchToProps)(CoaUpload)
