import React, { Component } from 'react'
import styled from 'styled-components'
import { Row, Col, Typography, Spin, Select, Icon } from 'antd'
import { connect } from 'react-redux'
import FastFactsCard from './widgets/fastFactsCard'
import { RANGE_SELECTOR } from '../../../utility'
import { getStatistics } from '../../../redux/modules/statistics/actions'
import { FormattedMessage } from 'react-intl'
import validate from 'uuid-validate'

const { Option } = Select
const { Text } = Typography

const SelectEx = styled(Select)`
    min-width: 120px;
    .ant-select-selection {
      background: none;
      box-shadow: none;
      margin-left: -10px;
      border: none;
    }
    .ant-select-selection-selected-value{
      font-size:13px;
      color: #757575;
    }
`
const ContainerEx = styled.div`
  background-color: white;
  box-shadow: 0 0 3px #ddd;
  margin-left: 22px;
  border-radius: 3px;
  `
const Header = styled(Row)`
    padding: 16px;
  `

const CardContainer = styled(Row)`
  padding: 10px;
  height: 74vh;
  @media (max-height: 768px) {
    height: 63vh;
  }
  overflow-y: auto;
  background-color: #ebebeb;
  &::webkit-scrollbar-track {
    width: 6px;
    height: 0px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
    padding: -10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
`
const Footer = styled(Row)`
  min-height: 6.8vh;
`

const SpinEx = styled(Spin)`
  margin: 25vh 6vw;
`
const TitleText = styled(Text)`
  font-size: 16px; 
  font-size: 15px;
  font-weight: normal;
  color: #212121;
  display: block;
  text-transform: uppercase;
`
const SettingIcon = styled(Icon)`
  padding: 0 5px;
  font-size: 20px;
  color: #bdbdbd;
`
class FastFacts extends Component {
  buildOptions = (ranges) => {
    return Object.keys(ranges).map((range, index) => {
      return <Option key={index} value={range}>{range}</Option>
    })
  }

  componentDidMount () {
    this.props.getStatistics(this.getDefaultDateRange())
  }

  componentDidUpdate (prevProps) {
    if (this.props.criteria && prevProps.criteria !== this.props.criteria) {
      this.props.getStatistics(this.getDefaultDateRange())
    }
  }

  handleRangeChange = value => {
    this.props.getStatistics(value)
  }

  getDefaultDateRange = () => {
    return this.props.range || 'Today'
  }

  render () {
    const { statistics, criteria, loading } = this.props
    const criteriaName = validate(criteria) ? 'items' : criteria

    const dateRange = this.getDefaultDateRange()
    const { totalCount = 0, stats = [] } = statistics[criteria] || {}
    const Cards = (
      <>
        <FastFactsCard id={`total${criteriaName}`} value={totalCount} defaultMessage={`Total ${criteriaName}`} />
        {
          stats.map(({ key, value, unit, displayName, type }) =>
            <FastFactsCard key={key} id={key} value={value} defaultMessage={displayName} unit={unit} type={type} />
          )
        }
      </>
    )
    return (
      <ContainerEx>
        <Header>
          <Col span={20}>
            <TitleText ><FormattedMessage id='fastFacts' defaultMessage='Fast Facts' /></TitleText>
            <SelectEx
              bordered={false}
              value={dateRange}
              onSelect={this.handleRangeChange}>
              {this.buildOptions(RANGE_SELECTOR)}
            </SelectEx>
          </Col>
          <Col span={4}>
            <SettingIcon type='setting' />
          </Col>
        </Header>
        <CardContainer>
          { loading ? <SpinEx /> : Cards }
        </CardContainer>
        <Footer />
      </ContainerEx>
    )
  }
}

const mapStateToProps = ({ statistics, statistics: { range, error, loading }, dashboard: { criteria } }) => {
  return {
    range,
    statistics,
    criteria,
    error,
    loading
  }
}

const mapDispatchToProps = {
  getStatistics
}

export default connect(mapStateToProps, mapDispatchToProps)(FastFacts)
