// @flow
// Set the upstream api via canonical url only
const BASE_URLS_CONFIG = {
  dev: {
    API_PLATFORM_BASE_URL: 'https://platform.dev.smartcosmos.net/v1',
    API_PLATFORM_TENANTS_URL: 'https://tenants.dev.smartcosmos.net/v1',
    API_PLATFORM_DOCUMENTS_URL: 'https://documents.dev.smartcosmos.net/v1',
    API_TRUSTLINK_OPERATIONS_URL: 'https://trustlink-operations.dev.smartcosmos.net/v1',
    API_TRUSTLINK_EVENTS_URL: 'https://trustlink-events.dev.smartcosmos.net/v1',
    API_TRUSTLINK_MASTER_DATA_URL: 'https://trustlink-master-data.dev.smartcosmos.net/v1',
    API_TRUSTLINK_ALERTS_URL: 'https://alerts.dev.smartcosmos.net/v1'
  },
  qa: {
    API_PLATFORM_BASE_URL: 'https://platform.qa.smartcosmos.net/v1',
    API_PLATFORM_TENANTS_URL: 'https://tenants.qa.smartcosmos.net/v1',
    API_PLATFORM_DOCUMENTS_URL: 'https://documents.qa.smartcosmos.net/v1',
    API_TRUSTLINK_OPERATIONS_URL: 'https://trustlink-operations.qa.smartcosmos.net/v1',
    API_TRUSTLINK_EVENTS_URL: 'https://trustlink-events.qa.smartcosmos.net/v1',
    API_TRUSTLINK_MASTER_DATA_URL: 'https://trustlink-master-data.qa.smartcosmos.net/v1',
    API_TRUSTLINK_ALERTS_URL: 'https://alerts.qa.smartcosmos.net/v1'
  },
  demo: {
    API_PLATFORM_BASE_URL: 'https://platform.demo.smartcosmos.net/v1',
    API_PLATFORM_TENANTS_URL: 'https://tenants.demo.smartcosmos.net/v1',
    API_PLATFORM_DOCUMENTS_URL: 'https://documents.demo.smartcosmos.net/v1',
    API_TRUSTLINK_OPERATIONS_URL: 'https://trustlink-operations.demo.smartcosmos.net/v1',
    API_TRUSTLINK_EVENTS_URL: 'https://trustlink-events.demo.smartcosmos.net/v1',
    API_TRUSTLINK_MASTER_DATA_URL: 'https://trustlink-master-data.demo.smartcosmos.net/v1',
    API_TRUSTLINK_ALERTS_URL: 'https://alerts.demo.smartcosmos.net/v1'
  },
  prod: {
    API_PLATFORM_BASE_URL: 'https://platform.smartcosmos.net/v1',
    API_PLATFORM_TENANTS_URL: 'https://tenants.smartcosmos.net/v1',
    API_PLATFORM_DOCUMENTS_URL: 'https://documents.smartcosmos.net/v1',
    API_TRUSTLINK_OPERATIONS_URL: 'https://trustlink-operations.smartcosmos.net/v1',
    API_TRUSTLINK_EVENTS_URL: 'https://trustlink-events.smartcosmos.net/v1',
    API_TRUSTLINK_MASTER_DATA_URL: 'https://trustlink-master-data.smartcosmos.net/v1',
    API_TRUSTLINK_ALERTS_URL: 'https://alerts.smartcosmos.net/v1'
  }
}

export const determineEnvConfigsByLocation = function ({ origin, host }) {
  let envConfig

  switch (host) {
    case 'trustlink-workbench.dev.smartcosmos.net':
      envConfig = { baseUrls: BASE_URLS_CONFIG.dev, env: 'dev' }
      break
    case 'trustlink-workbench.qa.smartcosmos.net':
      envConfig = { baseUrls: BASE_URLS_CONFIG.qa, env: 'qa' }
      break
    case 'trustlink-workbench.demo.smartcosmos.net':
      envConfig = { baseUrls: BASE_URLS_CONFIG.demo, env: 'demo' }
      break
    case 'trustlink-workbench.smartcosmos.net':
      envConfig = { baseUrls: BASE_URLS_CONFIG.prod, env: 'prod' }
      break
    default:
      // You can change here for different environment
      envConfig = { baseUrls: BASE_URLS_CONFIG.dev, env: 'dev' }
  }

  return envConfig
}

// Added this flow type will help your IDE to auto populate the code completion.
type BaseUrlsType = {
  API_PLATFORM_BASE_URL: string,
  API_PLATFORM_TENANTS_URL: string,
  API_TRUSTLINK_OPERATIONS_URL: string,
  API_PLATFORM_DOCUMENTS_URL: string,
  API_TRUSTLINK_EVENTS_URL:string,
  API_TRUSTLINK_ALERTS_URL: string,
  JWT: {
    AUTH_NS: string,
    USER_NS: string
  },
  AUTH_URL: string,
  USERS_URL: string,
  TENANTS_URL: string,
  LOCATIONS_URL: string
}

const selectedBaseUrls = determineEnvConfigsByLocation(window.location).baseUrls

export const BASE_URLS: BaseUrlsType = {
  // Platform
  USERS_URL: `${selectedBaseUrls.API_PLATFORM_BASE_URL}/users`,
  TENANTS_URL: `${selectedBaseUrls.API_PLATFORM_BASE_URL}/tenants`,
  LOCATIONS_URL: `${selectedBaseUrls.API_PLATFORM_BASE_URL}/locations`,

  AUTH_URL: `${selectedBaseUrls.API_PLATFORM_TENANTS_URL}/auth`,
  JWT: {
    // Base namespace for our custom claims of the jwt
    AUTH_NS: 'https://smartcosmos.net/authorization',
    USER_NS: 'https://smartcosmos.net/user'
  },
  ...selectedBaseUrls
}
