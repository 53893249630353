import localeActions from './actions'
import reduce from 'lodash/reduce'
import { localeConfigs } from '../../../i18n'

const languages = reduce(localeConfigs, function (result, value, key) {
  result[key] = value.name
  return result
}, {})

const initialState = {
  locale: 'en-US',
  languages
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case localeActions.CHANGE_LOCALE:
      return { ...state, locale: action.locale }
    default:
      return state
  }
}
export default reducer
