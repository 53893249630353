import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import app from './app/reducer'
import entities from './entities/reducer'
import dashboard from './dashboard/reducer'
import user from './user/reducer'
import locale from './locale/reducer'
import userSaga from './user/saga'
import dashboardSaga from './dashboard/saga'
import entitiesSaga from './entities/saga'
import authActions from './user/actions'
import eventTimeline from './eventTimeline/reducer'
import eventTimelineSaga from './eventTimeline/saga'
import searchbarSaga from './searchbar/saga'
import searchBar from './searchbar/reducer'
import documentImport from './documentImport/reducer'
import documentImportSags from './documentImport/saga'
import statistics from './statistics/reducer'
import statisticsSaga from './statistics/saga'
import alerts from './alerts/reducer'
import alertsSaga from './alerts/saga'

/*
* Unify module reducers for a single state tree
*/
const appReducers = combineReducers({
  app,
  locale,
  entities,
  user,
  dashboard,
  eventTimeline,
  documentImport,
  searchBar,
  statistics,
  alerts
})

export const reducers = (state, action) => {
  if (action.type === authActions.LOGOUT) {
    state = {}
  }

  return appReducers(state, action)
}

export const sagas = function * () {
  yield all([
    userSaga(),
    dashboardSaga(),
    entitiesSaga(),
    eventTimelineSaga(),
    documentImportSags(),
    searchbarSaga(),
    statisticsSaga(),
    alertsSaga()
  ])
}
