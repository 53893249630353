import objects from '../objects'

const ROLE = {
  searchCriteria: [
    {
      sequence: 0,
      ...objects.SHIPMENTS
    },
    {
      sequence: 1,
      ...objects.LOADS
    }
  ],
  tenantFilter: {
    customer: [
      {
        name: 'All',
        value: ''
      },
      {
        name: 'Rochester Wholesaler',
        value: 'rchstr_whlslr'
      },
      {
        name: 'Dr.Pepper Man',
        value: 'dr_pepper'
      }
    ]
  },
  dateRange: [
    {
      name: 'This week',
      value: '7'
    },
    {
      name: 'This month',
      value: '30'
    }
  ]
}
export default ROLE
