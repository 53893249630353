import React, { Component } from 'react'
import orderBy from 'lodash/orderBy'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Select, Input, Button, Divider, DatePicker, Row, Col, Typography, Tooltip } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import './SearchBar.css'

import {
  onCriteriaChange,
  onCustomerChange,
  onQueryChange,
  onRangeChange,
  onResetFilter,
  onInitialLoad,
  onProductChange
} from '../../../redux/modules/dashboard/actions'
import { onProductsFetch, onCompaniesFetch } from '../../../redux/modules/searchbar/actions'
import { DATE_FORMAT, RANGE_SELECTOR } from '../../../utility'
import { placeHolderInput, filterDateKey } from '../../../configs/objects'

const { Search, Group: InputGroup } = Input
const { Text } = Typography
const { Option, OptGroup } = Select
const { RangePicker } = DatePicker

const OPTION_ALL_COMPANIES = { id: '0', name: 'All companies' }
const OPTION_ALL_PRODUCTS = { id: '0', name: 'All products' }

const ColEx = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const DateCol = styled(ColEx)`
  justify-content: flex-end;
`
const RowEx = styled(Row)`
  margin-bottom: 20px;
`
const InputGroupEx = styled(InputGroup)`
  display: flex !important;
`
const LabelEx = styled(Text)`
  padding: 0 10px 0 0;
  font-size: 15px;
  color: #212121;
`

const SelectEx = styled(Select)`
    color: #333;
    .ant-select-selection {
      border-radius: 0px !important;
      width: 140px;
    }
    .ant-select-arrow{
      color: #333
    }
`
const SearchEx = styled(Search)`
  .ant-input{
    border-radius: 0;
  }
`

const SelectCustomer = styled(Select)`
    .ant-select-selection{
      border-radius: 0;
      width: 150px;
      margin-right: -24px;
    }
`
const SelectProduct = styled(SelectCustomer)``

const RangePickerEx = styled(RangePicker)`
  flex-basis: 50%;
  .ant-calendar-picker-input {
    border-radius: 0;
  }
`

const VerticalDivider = styled(Divider)`
    height: 1.5em;
`

const ResetBtn = styled(Button)`
    border-radius: 0;
    margin-left:5px;
    font-size: 16px;
    color: #bdbdbd;
`

class SearchBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchQuery: this.props.query
    }
  }

    buildOptions = (menu) => {
      let businessEntities = menu.splice(0, 4)
      let digitelItems = menu
      let optionsGroups = []
      optionsGroups.push(
        <OptGroup key='1' label='Business Entities'>
          {businessEntities.map((m, index) => <Option key={index} value={m.value}><Tooltip title={m.name}>{m.name}</Tooltip></Option>)}
        </OptGroup>
      )
      optionsGroups.push(
        <OptGroup key='2' label='Digital Items'>
          {digitelItems.map((m, index) => <Option key={index} value={m.value}><Tooltip title={m.name}>{m.name}</Tooltip></Option>)}
        </OptGroup>
      )
      return optionsGroups
    }

    buildCompaniesOptions = (companies = []) => {
      const sortedCompanies = orderBy(companies, ['name'], ['asc'])
      const allCompanies = [
        OPTION_ALL_COMPANIES,
        ...sortedCompanies
      ]

      return allCompanies.map((company, index) => {
        return <Option key={index} value={company.id}><Tooltip title={company.name}>{company.name}</Tooltip></Option>
      })
    }

    buildProductOptions = (products = []) => {
      const sortedProducts = orderBy(products, ['name'], ['asc'])
      const allProducts = [
        OPTION_ALL_PRODUCTS,
        ...sortedProducts
      ]
      return allProducts.map((product, index) => {
        return <Option key={index} value={product.id}>{product.name}</Option>
      })
    }

    getDefaultValue = (menu) => {
      return this.props.criteria ? this.props.criteria : orderBy(menu, ['sequence'])[0].value
    }

    getDefaultCustomerValue = () => {
      return this.props.customer ? this.props.customer : '0'
    }

    getDefaultProductValue () {
      return this.props.product ? this.props.product : '0'
    }

    getDefaultDateRange = () => {
      return this.props.range
        ? [this.props.range[0], this.props.range[1]] : [moment().startOf('day').format(), moment().endOf('day').format()]
    }

    handleSearch = e => {
      this.props.onQueryChange(e)
    }

    handleSelectionChange = selected => {
      const { onCriteriaChange, onProductsFetch, onCompaniesFetch } = this.props
      selected === 'batches' ? onProductsFetch() : onCompaniesFetch()
      onCriteriaChange(selected)
      this.setState(
        { searchQuery: null },
        () => {
          this.props.onQueryChange(null)
        }
      )
    }

    handleCustomerSelectionChange = e => {
      this.props.onCustomerChange(e)
    }

    handleProductChange = e => {
      this.props.onProductChange(e)
    }

    handleOnDateChange = (dates) => {
      this.props.onRangeChange([
        dates[0].format(),
        dates[1].format()
      ])
    }
    handleReset = async () => {
      await this.props.onResetFilter()
      this.props.onInitialLoad(this.getDefaults())
    }

    handleSearchQueryChange = (e) => {
      this.setState({ searchQuery: e.target.value })
    }

    getDefaults () {
      const { searchCriteria } = this.props
      const criteria = this.getDefaultValue(searchCriteria)
      const customer = this.getDefaultCustomerValue()
      const product = this.getDefaultProductValue()
      const range = this.getDefaultDateRange()
      const query = this.state.searchQuery
      const tableFilters = this.props.tableFilters
      return { criteria, customer, range, query, product, tableFilters }
    }

    componentDidMount () {
      const { onInitialLoad, criteria, onProductsFetch, onCompaniesFetch } = this.props
      onInitialLoad(this.getDefaults())
      criteria === 'batches' ? onProductsFetch() : onCompaniesFetch()
    }

    componentDidUpdate (prevProps) {
      if (prevProps.query !== this.props.query) {
        this.setState({
          searchQuery: this.props.query
        })
      }
    }

    render () {
      const { searchCriteria, criteria, companies, products, loading } = this.props
      const { criteria: defaultCriteria, query: defaultQuery, customer: defaultCustomer, range: defaultDateRange, product: defaultProduct } = this.getDefaults()
      const dateRange = [moment(defaultDateRange[0]), moment(defaultDateRange[1])]
      const criteriaLookUp = ['orders', 'loads', 'shipments', 'batches'].includes(criteria) ? criteria : 'items'
      const placeholderText = placeHolderInput[criteriaLookUp.toUpperCase()]
      const dateFilter = filterDateKey[criteriaLookUp.toUpperCase()]

      const tooltipTitle = defaultQuery ? (
        <span>{defaultQuery}</span>
      ) : (
        placeholderText
      )

      return (
        <RowEx>
          <ColEx span={8}>
            <InputGroupEx compact>
              <SelectEx
                value={defaultCriteria}
                onSelect={this.handleSelectionChange}>
                {this.buildOptions(orderBy(searchCriteria, ['sequence']))}
              </SelectEx>
              <Tooltip
                trigger={['focus']}
                title={tooltipTitle}
                placement='topLeft'
              >
                <SearchEx
                  placeholder={placeholderText} value={defaultQuery} onChange={this.handleSearchQueryChange}
                  onSearch={value => this.handleSearch(value)} />
              </Tooltip>
            </InputGroupEx>
            <VerticalDivider type='vertical' />
          </ColEx>
          { criteria !== 'batches' &&
            <ColEx span={5}>
              <LabelEx><FormattedMessage id='customer' defaultMessage='Customer' /></LabelEx>
              <SelectCustomer
                value={defaultCustomer}
                onSelect={this.handleCustomerSelectionChange}
                loading={loading}
              >
                {this.buildCompaniesOptions(companies)}
              </SelectCustomer>
            </ColEx>
          }
          {criteria === 'batches' &&
            <ColEx span={5}>
              <LabelEx><FormattedMessage id='products' defaultMessage='Products' /></LabelEx>
              <SelectProduct
                value={defaultProduct}
                onSelect={this.handleProductChange}
                loading={loading}
              >
                {this.buildProductOptions(products)}
              </SelectProduct>
            </ColEx>
          }
          <DateCol span={11}>
            <LabelEx>{dateFilter}</LabelEx>
            <RangePickerEx
              ranges={RANGE_SELECTOR}
              format={DATE_FORMAT}
              value={dateRange}
              onChange={this.handleOnDateChange}
            />
            <Tooltip placement='top' title='Reset filters'>
              <ResetBtn shape='circle' icon='reload' onClick={this.handleReset} />
            </Tooltip>
            <Tooltip placement='top' title='Bookmark'>
              <ResetBtn shape='circle' icon='book' />
            </Tooltip>
          </DateCol>
        </RowEx>
      )
    }
}

const mapStateToProps = ({ dashboard: { query, criteria, customer, product, range, tableFilters }, searchBar: { companies, products, loading } }) => {
  return {
    query,
    criteria,
    customer,
    product,
    range,
    products,
    companies,
    loading,
    tableFilters
  }
}

const mapDispatchToProps = {
  onInitialLoad,
  onQueryChange,
  onCriteriaChange,
  onCustomerChange,
  onRangeChange,
  onResetFilter,
  onProductChange,
  onProductsFetch,
  onCompaniesFetch
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBar))
