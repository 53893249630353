import moment from 'moment'
import validate from 'uuid-validate'

const isUuidValid = (uuid) => validate(uuid)
const isDateValid = (date) => moment(date, moment.ISO_8601).isValid()
const isEmailValid = (email) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
const isExclusiveMinimumZero = (number) => number >= 0

const isNumber = (number) => {
  try {
    return typeof JSON.parse(number) === 'number'
  } catch {
    return false
  }
}

const requiredError = (headerName, rowNumber, columnNumber) => {
  return {
    rowNumber: rowNumber,
    message: `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
  }
}

const validateError = (headerName, rowNumber, columnNumber, msg) => {
  return {
    rowNumber: rowNumber,
    message: `${headerName} ${msg} in the ${rowNumber} row / ${columnNumber} column`
  }
}

const headerError = (headerName, expectedName) => {
  return {
    headerError: true,
    message: `Header Error: ${headerName ? headerName + ' is incorrect' : 'Header name is missing'} expected ${expectedName}`
  }
}

const Utility = {
  isUuidValid,
  isDateValid,
  isEmailValid,
  requiredError,
  validateError,
  headerError,
  isNumber,
  isExclusiveMinimumZero
}
export default Utility
