import React from 'react'
import uuidv4 from 'uuid/v4'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table } from 'antd'
import { TableUtils } from '../../components'
import { doLoadShipmentItems } from '../../../redux/modules/entities/actions'

const { TableStyled, renderScannedQtyUOM, renderQtyUOM, customSorter } = TableUtils

const columns = [
  { title: 'Product Code', dataIndex: 'productCode', key: 'productCode', sorter: customSorter('productCode') },
  { title: 'Shipment Quantity', dataIndex: 'shipmentQuantity', key: 'shipmentQuantity', render: renderQtyUOM, sorter: customSorter('shipmentQuantity') },
  { title: 'Scanned Quantity', dataIndex: 'scannedQuantity', key: 'scannedQuantity', render: renderScannedQtyUOM, sorter: customSorter('scannedQuantity') },
  { title: 'Status', dataIndex: 'status', key: 'status', sorter: customSorter('status') },
  { title: 'Rejection Status', dataIndex: 'rejectionStatus', key: 'rejectionStatus', sorter: customSorter('rejectionStatus') },
  { title: 'Notes', dataIndex: 'notes', key: 'notes', sorter: customSorter('notes') }
]

const ShipmentDetailTable = (props) => {
  const { shipmentItems = [], loading } = props
  return (<TableStyled>
    <Table
      scroll={{ y: 'auto' }}
      columns={columns}
      dataSource={shipmentItems}
      size='small'
      rowKey={() => uuidv4()}
      loading={loading}
    />
  </TableStyled>
  )
}

const mapStateToProps = ({ entities: { loading, shipmentItems } }) => {
  return {
    shipmentItems,
    loading: loading
  }
}

const mapDispatchToProps = {
  loadOrderItems: doLoadShipmentItems
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShipmentDetailTable))
