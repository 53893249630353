import React from 'react'
import { Table } from 'antd'
import { TableUtils } from '../../components'
import uuidv4 from 'uuid/v4'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'

const { TableStyled, renderDate, renderTemperature, renderCustomerName, dateSorter, customSorter } = TableUtils

const LoadDetailTable = (props) => {
  const { loading, loads, match } = props
  const { loadNumber } = match.params
  const load = _.find(loads, { number: loadNumber })

  const columns = [
    { title: 'Drop', dataIndex: 'number', key: 'number', sorter: customSorter('number') },
    { title: 'Drop Dead Time', dataIndex: 'deadTime', key: 'deadTime', render: renderDate, sorter: dateSorter('deadTime') },
    { title: 'City', dataIndex: 'city', key: 'city', sorter: customSorter('city') },
    { title: 'State', dataIndex: 'state', key: 'state', sorter: customSorter('state') },
    { title: 'Seal Number', dataIndex: 'sealNumber', key: 'sealNumber', sorter: customSorter('sealNumber') },
    { title: 'Customer', dataIndex: 'customerId', key: 'customerId', render: renderCustomerName(), sorter: customSorter('customerId') },
    { title: 'Status', dataIndex: 'status', key: 'status', sorter: customSorter('status') },
    { title: 'Temperature Set', dataIndex: 'temperatureSet', key: 'temperatureSet', render: renderTemperature, sorter: customSorter('temperatureSet') },
    { title: 'Delivery Status', dataIndex: 'deliveryStatus', key: 'deliveryStatus', sorter: customSorter('deliveryStatus') },
    { title: 'Rejection Status', dataIndex: 'rejectionStatus', key: 'rejectionStatus', sorter: customSorter('rejectionStatus') }
  ]

  return (
    <TableStyled>
      <Table
        scroll={{ y: 'auto' }}
        columns={columns}
        dataSource={load.drops}
        size='small'
        rowKey={() => uuidv4()}
        loading={loading}
      />
    </TableStyled>
  )
}

const mapStateToProps = ({ entities: { loading, loads } }) => {
  return {
    loads,
    loading: loading
  }
}

const mapDispatchToProps = {
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoadDetailTable))
