import React from 'react'
import ConfTable from './ConfTable'
import { TableUtils } from '../../../components'
import TableStyled from '../../../components/TableUtils/TableStyled'
import { FormattedMessage } from 'react-intl'
import { SettingOutlined } from '@ant-design/icons'

const { renderDate, renderLink, dateSorter, customSorter } = TableUtils
const filterOptions = {
  dataIndex: 'configuration',
  key: 'configuration',
  filterIcon: <SettingOutlined />,
  filters: [
    { text: 'Load #', value: 'number' },
    { text: 'Carrier', value: 'carrier' },
    { text: 'Trailer ID', value: 'trailerId' },
    { text: 'Shipped Date', value: 'shippedDate' },
    { text: 'Delivery Date', value: 'deliveryDate' },
    { text: 'Status', value: 'status' },
    { text: 'Delivery Status', value: 'deliveryStatus' },
    { text: 'Rejection Status', value: 'rejectionStatus' }
  ],
  width: '2%'
}
const columns = [
  {
    title: <FormattedMessage id='LoadNumber' defaultMessage='Load #' />,
    dataIndex: 'number',
    key: 'number',
    render: renderLink,
    sorter: customSorter('number')
  },
  {
    title: <FormattedMessage id='carrier' defaultMessage='Carrier' />,
    key: 'carrier',
    dataIndex: 'carrier',
    sorter: customSorter('carrier')
  },
  {
    title: <FormattedMessage id='trailerID' defaultMessage='Trailer ID' />,
    key: 'trailerId',
    dataIndex: 'trailerId',
    sorter: customSorter('trailerId')
  },
  {
    title: <FormattedMessage id='shippedDate' defaultMessage='Shipped Date' />,
    key: 'shippedDate',
    dataIndex: 'shippedDate',
    render: renderDate,
    sorter: dateSorter('shippedDate')
  },
  {
    title: <FormattedMessage id='deliveryDate' defaultMessage='Delivery Date' />,
    key: 'deliveryDate',
    dataIndex: 'deliveryDate',
    render: renderDate,
    sorter: dateSorter('deliveryDate')
  },
  {
    title: <FormattedMessage id='status' defaultMessage='Status' />,
    dataIndex: 'status',
    key: 'status',
    sorter: customSorter('status')
  },
  {
    title: <FormattedMessage id='deliveryStatus' defaultMessage='Delivery Status' />,
    key: 'deliveryStatus',
    dataIndex: 'deliveryStatus',
    sorter: customSorter('deliveryStatus')

  },
  {
    title: <FormattedMessage id='rejectionStatus' defaultMessage='Rejection Status' />,
    key: 'rejectionStatus',
    dataIndex: 'rejectionStatus',
    sorter: customSorter('rejectionStatus')

  }
]

const LoadTable = function (props) {
  const { entities, criteria, loading } = props
  const dataSource = entities[criteria]

  return (
    <TableStyled>
      <ConfTable
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        criteria={criteria}
        options={props}
        filterOptions={filterOptions}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => props.history.push(`loadDetail/${record.number}`)
          }
        }}
        saveFilters={(filters) => props.saveFilters(filters)}
      />
    </TableStyled>
  )
}

export default LoadTable
