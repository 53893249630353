import jwtDecode from 'jwt-decode'
import { BASE_URLS } from '../../../environment/api'
import authActions from './actions'

const initialState = {
  email: null,
  nickname: null,
  picture: null,
  roles: null,
  permissions: null,
  id: null,
  loading: false,
  logoLoading: false,
  changePassloading: false,
  isChangePassSuccess: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.LOGIN:
      return { ...state, loading: true }
    case authActions.LOGIN_SUCCESS: {
      const decodedJwt = jwtDecode(action.payload.accessToken)
      localStorage.setItem('accessToken', action.payload.accessToken)
      // store userEmail and exp so that don't have to decode later as it will be used frequent in
      // checking isAuthenticated
      localStorage.setItem('userEmail', decodedJwt[BASE_URLS.JWT.USER_NS].email)
      localStorage.setItem('tokenExp', decodedJwt.exp)
      return {
        ...state,
        // Extract user namespace information and inject into user state
        // this also includes app_metadata { tenantId, sectorId, locationId }
        ...decodedJwt[BASE_URLS.JWT.USER_NS],
        // Extract user authorizations - roles
        ...decodedJwt[BASE_URLS.JWT.AUTH_NS],
        // Extract user permissions
        permissions: decodedJwt.permissions,
        loading: false
      }
    }
    case authActions.LOGOUT: {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userEmail')
      localStorage.removeItem('tokenExp')
      return initialState
    }
    case authActions.LOGIN_FAIL: {
      return initialState
    }

    case authActions.TENANT_INFO: {
      return {
        ...state,
        loading: true
      }
    }
    case authActions.TENANT_INFO_SUCCESS: {
      const { tenantInfo, location } = action.payload
      return {
        ...state,
        tenant: tenantInfo,
        location: location,
        loading: false
      }
    }
    case authActions.TENANT_INFO_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    }

    case authActions.LOAD_LOGO: {
      return {
        ...state,
        logoLoading: true
      }
    }
    case authActions.LOAD_LOGO_FAIL: {
      return {
        ...state,
        tenantLogoUrl: null,
        logoLoading: false
      }
    }
    case authActions.LOAD_LOGO_SUCCESS: {
      const { logoUrl } = action.payload
      return {
        ...state,
        tenantLogoUrl: logoUrl,
        logoLoading: false
      }
    }

    case authActions.CHECK_OLD_PASSWORD:
      return { ...state, changePassloading: true, isChangePassSuccess: false }
    case authActions.CHECK_OLD_PASSWORD_FAIL:
      return { ...state, changePassloading: false }
    case authActions.CHECK_OLD_PASSWORD_SUCCESS:
      return { ...state, changePassloading: false }
    case authActions.CHANGE_PASSWORD:
      return { ...state, changePassloading: true }
    case authActions.CHANGE_PASSWORD_FAIL:
      return { ...state, changePassloading: false }
    case authActions.CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePassloading: false, isChangePassSuccess: true }
    case authActions.CLEAR_SUCCESS:
      return { ...state, isChangePassSuccess: false }
    default:
      return state
  }
}
export default reducer
