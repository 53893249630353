import actions from './actions'

const initialState = {
  events: [],
  status: 'start'
}
const entitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.TIMELINE_INIT:
      return {
        ...state
      }
    case actions.TIMELINE_START:
      return {
        ...state,
        status: 'start'
      }
    case actions.TIMELINE_SUCCESS:
      const events = action.payload.events
      return {
        ...state,
        status: 'success',
        events: Array.isArray(events) ? [...action.payload.events] : []
      }
    case actions.TIMELINE_FAIL:
      return {
        ...state,
        status: 'fail'
      }
    default:
      return state
  }
}
export default entitiesReducer
