import React, { Component } from 'react'
import styled from 'styled-components'
import orderBy from 'lodash/orderBy'
import AlertsFilters from './filters'
import { Icon, Button, Spin, Divider } from 'antd'
import { connect } from 'react-redux'
import { getAllAlerts } from '../../../redux/modules/alerts/actions'
import { dayDateTimeFormatter } from '../../../utility'

const AlertsHeader = styled.div`
  padding: 14.5px 20px;
  display: flex;
  background-color: #FAFAFA;
  height: 48px;
`
const SpinEx = styled(Spin)`
  margin: 20vh 42vw;
`
const Reason = styled.div`
  font-size: 14px;
  color: #212121;
`
const Description = styled.div``

const Container = styled.div`
  padding: 20px 22px;
`
const AlertContainer = styled.div`
  box-shadow: 0 0 3px #ddd;
  border-radius: 4px;
  background-color: ##f2f2f2;
`
const NotificationCardContainer = styled.div`
max-height: 77vh;
@media (max-height: 768px) {
  max-height: 64vh;
  }
  overflow: hidden;
  overflow-y: auto;
  padding: 20px;
`

const NotificationCard = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #D9DADB;
    border-left: 4px solid ${props => (props.cardtype === '1' ? '#73C5C5' : '#F0AB00')};
    padding: 6px 15px;
    margin-bottom: 3px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: white;
`

const ContentLeft = styled.div`
    flex: 2;
    padding: 0 15px;
    font-size: 12px;
    color: #000000;
`
const ContentRight = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
const IconContainer = styled.div`
    background-color: ${props => (props.cardtype === '1' ? '#73C5C5' : '#F0AB00')};
    border-radius: 50%;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 2;
`

const CardIcon = styled(Icon)`
    font-size: 10px;
    color: ${props => (props.cardtype === '1' ? '#559292' : 'b27f00')};
`
const Time = styled.div`
    color: #757575;
    font-size: 12px;
    line-height: 1;
`
const StyledButton = styled(Button)`
  align-self: center;
  color: #2593FC;
  .ant-btn-link:hover, .ant-btn-link:focus{
    color: #2593FC;
  }
`
const StyledClose = styled(Button)`
    min-width: 0;
    width: 18px;
    height: 18px;
    font-size: 8px;
    background-color: #ebebeb;
    &:hover {
        background-color: #ebebeb;
    }
`
const Title = styled.div`
    font-size: 16px;
    color: #212121;
    flex: 5;
`

class AlertsDetails extends Component {
  componentDidMount () {
    const { getAllAlerts } = this.props
    getAllAlerts()
  }

  render () {
    const { notifications: notificationData } = this.props

    return (
      <Container>
        <AlertsFilters />
        <AlertContainer>
          <AlertsHeader>
            <Title>Alerts and Notifications</Title>
            <StyledButton type='link'>Dismiss All</StyledButton>
          </AlertsHeader>
          <NotificationCardContainer>
            { !notificationData ? <SpinEx /> : notificationData.map((notification, index) => {
              return (
                <NotificationCard cardtype={notification.type} key={index}>
                  <IconContainer cardtype={notification.type}>
                    <CardIcon cardtype={notification.type} type={notification.type === '1' ? 'bell' : 'exclamation'} />
                  </IconContainer>
                  <ContentLeft>
                    <Reason>
                      {notification.context && notification.context.failure ? notification.context.failure.reason : ''}
                    </Reason>
                    <Description>
                      {notification.context && notification.context.failure ? notification.context.failure.description : ''}
                    </Description>
                    <Time> <Icon type='clock-circle' /> {dayDateTimeFormatter(notification.createdAt)}</Time>
                  </ContentLeft>
                  <ContentRight>
                    <StyledButton type='link'>Notify</StyledButton>
                    <Divider type='verticle' />
                    <StyledButton type='link'>Resolve</StyledButton>
                    <Divider type='verticle' />
                    <StyledClose shape='circle' icon='close' />
                  </ContentRight>
                </NotificationCard>
              )
            })}
          </NotificationCardContainer>
        </AlertContainer>
      </Container>
    )
  }
}

const mapDispatchToProps = {
  getAllAlerts
}

const mapStateToProps = ({ alerts: { notifications } }) => {
  return {
    notifications: orderBy(notifications, 'createdAt', 'desc')
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsDetails)
