import React from 'react'
import { SideLinks } from '../../components'
import { dateFormatter } from '../../../utility'

const { SideLink, getDatesRangeText } = SideLinks

export default (props) => {
  const { batches, batchId, selectBatch } = props

  const menus = batches.map(({ id, carrier, number, status, createdAt }) => {
    return {
      id,
      title: number,
      status,
      content: 'Text',
      dateTitle: 'Created At',
      date: dateFormatter(createdAt)
    }
  })

  return <SideLink menus={menus} title='Related Batches' subTitle={`${getDatesRangeText(batches, 'createdAt')}`} selectedId={batchId} handleSelect={selectBatch} />
}
