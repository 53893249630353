import React from 'react'
import styled from 'styled-components'
import { Row, Col, Statistic } from 'antd'
import groupBy from 'lodash/groupBy'
import startCase from 'lodash/startCase'

const CardsContainer = styled.div`
  height: 83vh;
  @media (max-height: 768px) {
    height: 76.8vh;
  }
  overflow-y: auto;
  &::webkit-scrollbar-track {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    padding: -10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
`
const StatisticEx = styled(Statistic)`
  margin: 0 16px 5px 16px;
  background-color: white;
  padding: 10px;
  .ant-statistic-content{
    color: #212121;
    font-size: 15px;
  }
  .ant-statistic-title {
    margin-bottom: 4px;
    color: #757575;
    font-size: 13px;
}
`
export default function StatisticCards (props) {
  const { widgetData } = props
  const statusLookUp = groupBy(widgetData, 'status')

  const totalBatches = widgetData.length
  let totalItems = 0
  widgetData.map((data) => {
    totalItems = totalItems + data.items.length
    return totalItems
  })
  const cardStatus = Object.keys(statusLookUp).map(status => {
    return {
      title: `${startCase(status)} Batches`,
      cardValue: statusLookUp[status].length
    }
  })

  const cards = [
    {
      title: 'Total Batches',
      cardValue: totalBatches
    },
    {
      title: 'Total Items',
      cardValue: totalItems
    },
    ...cardStatus
  ]

  return (
    <Row>
      <Col>
        <CardsContainer>
          { cards.map((card, index) => <StatisticEx key={index} value={card.cardValue} title={card.title} />)}
        </CardsContainer>
      </Col>
    </Row>
  )
}
