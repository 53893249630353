import React from 'react'
import styled from 'styled-components'
import { Table, Icon, Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { doPreviewDocument } from '../../../../redux/modules/entities/actions'
import { downloadDocument } from '../../../../redux/modules/documentImport/actions'

const TableEx = styled(Table)`
  .ant-table-tbody > tr > td {
  padding: 10px 7px;
  }
  .ant-btn-icon-only {
    width: 26px;
    height: 26px;
  }
`
const DateDiv = styled.div`
  color: darkgrey;
`

const DescriptionDiv = styled.div`
  display: grid
`

function DocumentsPreview (props) {
  const { documents, loading } = props

  const columns = [
    { title: <FormattedMessage id='icon' defaultMessage='icon' />, key: 'icon', render: renderIcon },
    { title: <FormattedMessage id='description' defaultMessage='documentType' />, dataIndex: 'documentType', key: 'documentType' },
    { title: <FormattedMessage id='description' defaultMessage='description' />, dataIndex: 'description', key: 'description', render: renderDescription },
    {
      title: 'preview',
      dataIndex: 'preview',
      key: 'preview',
      render: renderPreview
    },
    {
      title: 'download',
      dataIndex: 'download',
      key: 'download',
      render: renderDownload
    }
  ]

  function renderIcon (text, record = {}, index) {
    const { file } = record
    const type = file.substring(file.lastIndexOf('.') + 1, file.length)

    let icon

    if (type && (type.toLowerCase().includes('jpg') || type.toLowerCase().includes('png') || type.toLowerCase().includes('jpeg'))) {
      icon = <Icon type='file-image' />
    } else if (type && type.toLowerCase().includes('pdf')) {
      icon = <Icon type='file-pdf' />
    } else {
      icon = <Icon type='file-text' />
    }
    return icon
  }

  function renderDescription (text, record, index) {
    const { createTime, description } = record
    return <DescriptionDiv>
      <DateDiv>{createTime}</DateDiv>
      <div>{description}</div>
    </DescriptionDiv>
  }

  function renderDownload (text, record, index) {
    return <Button icon='download' onClick={() => props.downloadDocument({ documentId: record.id, fileName: record.file })} />
  }

  function renderPreview (text, record, index) {
    return <Button icon='search' onClick={() => props.doPreviewDocument({ documentId: record.id, fileName: record.file })} />
  }

  return <div>
    <TableEx
      rowKey='id'
      columns={columns}
      dataSource={documents}
      pagination={false}
      showHeader={false}
      loading={loading}
    />
  </div>
}

const mapStateToProps = ({ entities: { loading, document } }) => {
  return {
    document,
    loading: loading
  }
}

export default connect(mapStateToProps, { doPreviewDocument, downloadDocument })(DocumentsPreview)
