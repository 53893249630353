import React, { Component } from 'react'
import { Layout, Menu, Icon } from 'antd'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { version } from '../../../../package.json'
import SmartCosmosLogo from '../../../assets/SC_logo.svg'
import SmartCosmosLogoWithText from '../../../assets/SC_logo_text.svg'

const { Sider } = Layout

const Name = styled.span`
`
const PoweredBy = styled.div`
  border-top: 1px solid #1976D2;
`
const Logo = styled.img``

const AppVersion = styled.div``

const AppSider = styled(Sider)`
  overflow: "auto";
  height: "100vh";
  position: "sticky";
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #2196F3;
  width: 100% !important;
  max-width: 100% !important;
  ul {
    margin-top: 40px;
    width: 100%;
  }
  transition: all 0.2s ease-in-out;
  .ant-layout-sider-trigger {
    background: none;
    height: 95px;
  }
`
const MenuIconEx = styled.span`
  z-index: 2;
  width: 0;
  height: 0;
  top: 2px;
  right: -3.6px;
  font-size: 20px;
  background: none;
  :hover {
    background: none;
  }
  opacity: ${({ show }) => show};
`
const MenuEx = styled(Menu)`
  //display: ${props => props.show === 'yes' ? 'block' : 'none'};
  background: none;
  .ant-menu-item-selected{
    border-left: 4px solid #fff;
    background: #1976D2 !important;
  }
`
const StyledIcon = styled(Icon)`
  margin-left: -17px !important;
  `
const MenuItemEx = styled(Menu.Item)`
  border-left: 4px solid #2196F3;
`
class Sidebar extends Component {
  state = {
    collapsed: true,
    showButton: true
  }

  onClick = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  redirect = (path) => this.props.history.push(path)
  render () {
    const activeUrl = window.location.href
    let activeKey = activeUrl.split('/')[3]
    activeKey = ['dashboard', 'analytics', 'administration', 'dataManagement'].includes(activeKey) ? activeKey : 'dashboard'
    return (
      <AppSider trigger={<AppVersion>
          v{version}
        <PoweredBy>
          <Logo src={this.state.collapsed ? SmartCosmosLogo : SmartCosmosLogoWithText} alt='Smart Cosmos Logo' />
        </PoweredBy>
      </AppVersion>} collapsible width={165} collapsedWidth={55} collapsed={this.state.collapsed}>
        <MenuIconEx show={this.state.showButton ? 1 : 0} className={'ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left'}>
          <StyledIcon type={this.state.collapsed ? 'right-circle' : 'left-circle'} theme='filled' onClick={this.onClick} />
        </MenuIconEx>
        <MenuEx
          show={this.state.collapsed ? '' : 'yes'}
          theme='dark'
          selectedKeys={activeKey}
          mode='inline'
        >
          <MenuItemEx key='dashboard' onClick={() => this.redirect('/dashboard')}>
            <StyledIcon type='home' />
            <Name><FormattedMessage id='dashboard' defaultMessage='dashboard' /></Name>
          </MenuItemEx>

          <MenuItemEx key='analytics' onClick={() => this.redirect('/analytics')}>
            <StyledIcon type='area-chart' />
            <Name><FormattedMessage id='analytics' defaultMessage='Analytics' /></Name>
          </MenuItemEx>

          <MenuItemEx key='administration' onClick={() => this.redirect('/administration')}>
            <StyledIcon type='setting' />
            <Name><FormattedMessage id='administration' defaultMessage='Administration' /></Name>
          </MenuItemEx>

          <MenuItemEx key='dataManagement' onClick={() => this.redirect('/dataManagement')}>
            <StyledIcon type='database' />
            <Name><FormattedMessage id='dataManagement' defaultMessage='Import Data' /></Name>
          </MenuItemEx>

        </MenuEx>
      </AppSider>
    )
  }
}

export default withRouter(Sidebar)
