import Status from '../../components/Status'
import React from 'react'
import Information from '../../components/EntityDetailView/Information'
import { TableUtils } from '../../components'

const { renderDate } = TableUtils

export default function (props) {
  const { data, documentId } = props
  const { status, weight, weightUOM, volume, volumeUOM, shipmentDate, deliveryDate, poReference } = data

  const headerInfo = { label: 'Shipment Status', documentId: documentId, status: status }
  const listOfInfo = [
    {
      label: 'Shipment Status',
      value: <Status status={status} />
    }, {
      label: 'Weight',
      value: `${weight} ${weightUOM}`
    }, {
      label: 'Item Count',
      value: `${volume} ${volumeUOM}`
    }, {
      label: 'Shipment Date',
      value: renderDate(shipmentDate)
    }, {
      label: 'Delivery Date',
      value: renderDate(deliveryDate)
    }, {
      label: 'Customer PO',
      value: poReference || 'Unavailable',
      isFooter: true
    }
  ]

  return <Information listOfInfo={listOfInfo} headerInfo={headerInfo} />
}
