import dashboardActions from './actions'

const initialState = {
  query: null,
  criteria: null,
  customer: null,
  range: null,
  pageNumber: null,
  pageSize: null,
  product: null,
  tableFilters: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardActions.PAGE_SIZE_CHANGE: {
      return { ...state, pageSize: action.data }
    }
    case dashboardActions.SELECT_CRITERIA: {
      return { ...state, criteria: action.data }
    }
    case dashboardActions.QUERY: {
      return { ...state, query: action.data }
    }
    case dashboardActions.CUSTOMER: {
      return { ...state, customer: action.data }
    }
    case dashboardActions.PRODUCT: {
      return { ...state, product: action.data }
    }
    case dashboardActions.RANGE: {
      return { ...state, range: action.data }
    }
    case dashboardActions.RESET: {
      return { ...state, ...initialState }
    }
    case dashboardActions.INITIAL_LOAD: {
      return { ...state, ...action.data }
    }
    case dashboardActions.SEARCH_SUCCESS: {
      return { ...state, searchResult: action.payload }
    }
    case dashboardActions.SEARCH_FAILURE: {
      return { ...state, error: action.payload }
    }
    case dashboardActions.SEARCH_CRITERIA_START: {
      return { ...state, loading: true }
    }
    case dashboardActions.SEARCH_CRITERIA_SUCCESS: {
      return { ...state, searchCriteria: action.payload, loading: false }
    }
    case dashboardActions.SAVE_FILTERS: {
      let _filters = state.tableFilters
      _filters[action.data.key] = action.data.filters
      return { ...state, tableFilters: _filters }
    }
    default:
      return state
  }
}
export default reducer
