import React from 'react'
import _ from 'lodash'
import { Empty } from 'antd'
import { SideLinks, Container, TableUtils } from '../../components'
import { dateFormatter } from '../../../utility'

const { SideLink, getDatesRangeText } = SideLinks
const { renderCustomerName } = TableUtils

export default (props) => {
  const { orders, orderId, selectOrder } = props
  if (_.isEmpty(orders)) {
    return <Container><Empty /></Container>
  }

  const menus = orders.map(({ id, number, status, orderDate, customerId }) => {
    return {
      id: id,
      title: number,
      status: status,
      content: renderCustomerName()(customerId),
      dateTitle: 'Order Date',
      date: dateFormatter(orderDate)
    }
  })

  return <SideLink menus={menus} title='Related Orders' subTitle={`${getDatesRangeText(orders, 'orderDate')}`} selectedId={orderId} handleSelect={selectOrder} />
}
