import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { message, Button, Select, Input, Popover, Form, Divider } from 'antd'
import styled from 'styled-components'
import { initiateBatchUpdateStatus, resetBatchUpdateStatus } from '../../../../redux/modules/entities/actions'
import { FormattedMessage } from 'react-intl'

const { Option } = Select
const { TextArea } = Input
const { Item } = Form

const DividerEx = styled(Divider)`
  margin: 9px 0;
`
const ButtonEx = styled(Button)`
   padding: 0 12px;
   background-color: #3570FF;
   border-color: #3570FF;
   :hover{
    background-color: #3570FF;
    border-color: #3570FF;
   }
`
const FormEx = styled(Form)`
  padding: 12px;
  padding-bottom: 0px;
`

const ItemEx = styled(Item)`
  font-weight: 500;
  margin-bottom: 5px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const PopupButton = styled(Button)`
  margin: 0 10px;
`
const SelectStatus = styled(Select)`
  width: 216px !important;
`
const InitiateRecall = (props) => {
  const { form, form: { getFieldDecorator }, data: { id: batchId, status }, initiateBatchUpdateStatus, resetBatchUpdateStatus, recallStatus, loading, batchStatus } = props
  const [visible, setVisible] = useState(false)
  const availableStatus = batchStatus.filter((elm) => elm.text !== status)

  useEffect(() => {
    if (recallStatus === 'success') {
      message.success('Batch status updated')
      setVisible(false)
      resetBatchUpdateStatus()
    } else if (recallStatus === 'fail') {
      message.error('Unable to update batch status')
      resetBatchUpdateStatus()
    }
  }, [recallStatus, resetBatchUpdateStatus])

  const buildStatusOptions = (status) => status.map((status, index) => <Option key={index} value={status.text}>{status.text}</Option>)

  const handleSubmit = () => {
    const { status } = form.getFieldsValue()
    initiateBatchUpdateStatus({ batchId, status }) // TO-DO notes
  }

  const handleVisibleChange = (vis) => {
    form.resetFields()
    setVisible(vis)
  }

  const popoverContent = availableStatus.length > 0 ? (
    <>
      <FormEx>
        <ItemEx label={<FormattedMessage id='status' defaultMessage='Choose State*' />}>
          { getFieldDecorator('status', { initialValue: availableStatus[0].text })(<SelectStatus>
            { buildStatusOptions(availableStatus) }
          </SelectStatus>
          )}
        </ItemEx>
        <ItemEx label={<FormattedMessage id='notes' defaultMessage='Notes' />}>
          {getFieldDecorator('notes')(<TextArea rows={4} />)}
        </ItemEx>
      </FormEx>
      <DividerEx />
      <ButtonContainer>
        <PopupButton type='link' onClick={() => handleVisibleChange(false)}>Cancel</PopupButton>
        <PopupButton type='link' loading={loading} onClick={handleSubmit}>Submit</PopupButton>
      </ButtonContainer>
      </>
  ) : null
  return (
    <Popover title={<FormattedMessage id='updateBatchState' defaultMessage='Update Batch State' trigger='click' />} content={popoverContent} trigger='click' placement='left' visible={visible} onVisibleChange={handleVisibleChange}>
      <ButtonEx disabled={availableStatus.length === 0} type='primary' onClick={() => setVisible(true)}><FormattedMessage id='updateBatchState' defaultMessage='Update Batch State' /></ButtonEx>
    </Popover>
  )
}

const mapDispatchToProps = {
  initiateBatchUpdateStatus,
  resetBatchUpdateStatus
}

const mapStatusToProps = ({ entities: { recallStatus, recallLoading } }) => {
  return {
    recallStatus,
    loading: recallLoading
  }
}

export default withRouter(
  connect(mapStatusToProps, mapDispatchToProps)(Form.create({ name: 'change_password' })(InitiateRecall))
)
