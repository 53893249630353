import React from 'react'
import ConfTable from './ConfTable'
import { SettingOutlined } from '@ant-design/icons'
import { TableUtils } from '../../../components'
import { FormattedMessage } from 'react-intl'
const { TableStyled, renderDate, renderCurrency, renderLink, dateSorter, renderCustomerName, customSorter } = TableUtils
const filterOptions = {
  dataIndex: 'configuration',
  key: 'configuration',
  filterIcon: <SettingOutlined />,
  filters: [
    { text: 'Order #', value: 'number' },
    { text: 'PO #', value: 'poReference' },
    { text: 'Customer', value: 'customerId' },
    { text: 'Order Date', value: 'orderDate' },
    { text: 'Planned Delivery Date', value: 'plannedDeliveryDate' },
    { text: 'Delivery Date', value: 'deliveryDate' },
    { text: 'Order Value', value: 'price' },
    { text: 'Order Status', value: 'status' },
    { text: 'Delivery Status', value: 'deliveryStatus' }
  ],
  width: '2%'
}
const columns = () => {
  return [
    {
      title: 'Order #',
      dataIndex: 'number',
      key: 'number',
      index: 0,
      render: renderLink,
      sorter: customSorter('number')
    },
    {
      title: <FormattedMessage id='PO#' defaultMessage='PO #' />,
      key: 'poReference',
      dataIndex: 'poReference',
      ellipsis: true,
      sorter: dateSorter('poReference')
    },
    {
      title: <FormattedMessage id='customer' defaultMessage='Customer' />,
      key: 'customerId',
      dataIndex: 'customerId',
      ellipsis: true,
      render: renderCustomerName(),
      sorter: customSorter('customerId')
    },
    {
      title: <FormattedMessage id='orderDate' defaultMessage='Order Date' />,
      key: 'orderDate',
      dataIndex: 'orderDate',
      render: renderDate,
      sorter: dateSorter('orderDate')
    },
    {
      title: <FormattedMessage id='plannedDeliveryDate' defaultMessage='Planned Delivery Date' />,
      key: 'plannedDeliveryDate',
      dataIndex: 'plannedDeliveryDate',
      render: renderDate,
      sorter: dateSorter('plannedDeliveryDate')
    },
    {
      title: <FormattedMessage id='deliveryDate' defaultMessage='Delivery Date' />,
      key: 'deliveryDate',
      dataIndex: 'deliveryDate',
      render: renderDate,
      sorter: dateSorter('deliveryDate')
    },
    {
      title: <FormattedMessage id='orderValue' defaultMessage='Order Value' />,
      key: 'price',
      dataIndex: 'price',
      render: renderCurrency,
      sorter: customSorter('price')
    },
    {
      title: <FormattedMessage id='orderStatus' defaultMessage='Order Status' />,
      key: 'status',
      dataIndex: 'status',
      sorter: customSorter('status')
    },
    {
      title: <FormattedMessage id='deliveryStatus' defaultMessage='Delivery Status' />,
      key: 'deliveryStatus',
      dataIndex: 'deliveryStatus',
      sorter: customSorter('deliveryStatus')
    }
  ]
}

const OrderTable = function (props) {
  const { entities, criteria, loading } = props
  const dataSource = entities[criteria]

  return (
    <TableStyled>
      <ConfTable
        columns={columns()}
        dataSource={dataSource}
        loading={loading}
        criteria={criteria}
        options={props}
        filterOptions={filterOptions}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => props.history.push(`orderDetail/${record.number}`)
          }
        }}
        saveFilters={(filters) => props.saveFilters(filters)}
      />
    </TableStyled>
  )
}

export default OrderTable
