import Utility from './utility'

const { requiredError, headerError } = Utility

const CompaniesConfig = {
  headers: [
    {
      name: 'shortName',
      inputName: 'shortName',
      required: true,
      headerError: (headerName) => headerError(headerName, 'shortName'),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'name',
      inputName: 'name',
      required: true,
      headerError: (headerName) => headerError(headerName, 'name'),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    }

  ]
}
export default CompaniesConfig
