import Utility from './utility'
import CONST from './constants'

const { isUuidValid, isDateValid, isEmailValid, requiredError, validateError, headerError, isNumber } = Utility
const { SHIPMENT_STATUS, SUPPORTED_CURRENCIES, DELIVERY_STATUS, REJECTION_STATUS } = CONST

const OrdersConfig = {
  headers: [
    {
      name: 'number',
      inputName: 'number',
      required: true,
      headerError: (headerName) => headerError(headerName, 'number'),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'tenantId',
      inputName: 'tenantId',
      unique: false,
      validate: tenantId => isUuidValid(tenantId),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, 'should be valid uuid')
    },
    {
      name: 'description',
      inputName: 'description',
      unique: false
    },
    {
      name: 'poReference',
      inputName: 'poReference',
      unique: false,
      required: true,
      headerError: (headerName) => headerError(headerName, 'poReference'),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'customerId',
      inputName: 'customerId',
      required: true,
      headerError: (headerName) => headerError(headerName, 'customerId'),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'salesPerson',
      inputName: 'salesPerson',
      required: true,
      headerError: (headerName) => headerError(headerName, 'salesPerson'),
      validate: (salesPerson) => isEmailValid(salesPerson),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `should be valid email address`),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'salesArea',
      inputName: 'salesArea',
      required: true,
      headerError: (headerName) => headerError(headerName, 'salesArea'),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'status',
      inputName: 'status',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'status'),
      validate: (status) => SHIPMENT_STATUS.includes(status.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${SHIPMENT_STATUS.join()}]`),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'price',
      inputName: 'price',
      required: true,
      headerError: (headerName) => headerError(headerName, 'price'),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      validate: (price) => isNumber(price),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be number`)
    },
    {
      name: 'priceUnit',
      inputName: 'priceUnit',
      headerError: (headerName) => headerError(headerName, 'priceUnit'),
      validate: (priceUnit) => SUPPORTED_CURRENCIES.includes(priceUnit.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${SUPPORTED_CURRENCIES.join()}]`),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'deliveryStatus',
      inputName: 'deliveryStatus',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'deliveryStatus'),
      validate: (deliveryStatus) => DELIVERY_STATUS.includes(deliveryStatus.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${DELIVERY_STATUS.join()}]`),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'rejectionStatus',
      inputName: 'rejectionStatus',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'rejectionStatus'),
      validate: (rejectionStatus) => REJECTION_STATUS.includes(rejectionStatus.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${REJECTION_STATUS.join()}]`),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'notes',
      inputName: 'notes',
      unique: false
    },
    {
      name: 'documentIds',
      inputName: 'documentIds',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'documentIds'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'plannedDeliveryDate',
      inputName: 'plannedDeliveryDate',
      headerError: (headerName) => headerError(headerName, 'plannedDeliveryDate'),
      required: true,
      validate: plannedDeliveryDate => isDateValid(plannedDeliveryDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'orderDate',
      inputName: 'orderDate',
      unique: false,
      required: true,
      headerError: (headerName) => headerError(headerName, 'orderDate'),
      validate: orderDate => isDateValid(orderDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'deliveryDate',
      inputName: 'deliveryDate',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'deliveryDate'),
      validate: deliveryDate => isDateValid(deliveryDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'billToName',
      inputName: 'billToName',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'billToName'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'billToAddressCountry',
      inputName: 'billToAddressCountry',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'billToAddressCountry'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'billToAddressLocality',
      inputName: 'billToAddressLocality',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'billToAddressLocality'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'billToAddressRegion',
      inputName: 'billToAddressRegion',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'billToAddressRegion'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'billToPostalCode',
      inputName: 'billToPostalCode',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'billToPostalCode'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'billToStreetAddress',
      inputName: 'billToStreetAddress',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'billToStreetAddress'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'shipToName',
      inputName: 'shipToName',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'shipToName'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'shipToAddressCountry',
      inputName: 'shipToAddressCountry',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'shipToAddressCountry'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'shipToAddressLocality',
      inputName: 'shipToAddressLocality',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'shipToAddressLocality'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'shipToAddressRegion',
      inputName: 'shipToAddressRegion',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'shipToAddressRegion'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'shipToPostalCode',
      inputName: 'shipToPostalCode',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'shipToPostalCode'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'shipToStreetAddress',
      inputName: 'shipToStreetAddress',
      unique: false,
      headerError: (headerName) => headerError(headerName, 'billToStreetAddress'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    }
    // order items validation

    /* {
      name: 'orderId',
      inputName: 'orderId',
      headerError: (headerName) => headerError(headerName, 'orderId'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'productCode',
      inputName: 'productCode',
      headerError: (headerName) => headerError(headerName, 'productCode'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'quantity',
      inputName: 'quantity',
      headerError: (headerName) => headerError(headerName, 'quantity'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'quantityUOM',
      inputName: 'quantityUOM',
      headerError: (headerName) => headerError(headerName, 'quantityUOM'),
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    } */
  ]
}
export default OrdersConfig
