import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import isEmpty from 'lodash/isEmpty'
import OrderTable from './tables/OrderTable'
import ShipmentTable from './tables/ShipmentTable'
import LoadTable from './tables/LoadTable'
import ItemTable from './tables/ItemTable'
import BatchTable from './tables/BatchTable'
import MapComponent from '../map'
import TablePagination from '../../components/TableUtils/tablePagination'
import { onPageChange, onPageSizeChange, saveFilters } from '../../../redux/modules/dashboard/actions'

const PaginationContainer = styled.div`
  background: white;
  min-height: 6.3vh;
`
const LandingContainer = styled.div`
`
const TableContainer = styled.div`
  background-color: white;
`

const ColEx = styled(Col)`
  border-radius: 5px;
  overflow: hidden;
`
const MapElement = styled.div`
  height: ${prop => prop.height};
  border: 3px solid white;
  margin-bottom: 8px;
`

class DashboardLanding extends Component {
  handlePageChange = (page) => {
    this.props.onPageChange(page)
  }

  handlePageSizeChange = (current, size) => {
    this.props.onPageSizeChange(size)
    this.props.onPageChange(current)
  }

  handleSaveFilters = (filters) => {
    this.props.saveFilters(filters)
  };

  render () {
    const { selfContext, companies: customers, criteria, entities, paging } = this.props
    let TableComponent

    switch (this.props.criteria) {
      case 'loads':
        TableComponent = LoadTable
        break
      case 'shipments':
        TableComponent = ShipmentTable
        break
      case 'orders':
        TableComponent = OrderTable
        break
      case 'batches':
        TableComponent = BatchTable
        break
      default:
        TableComponent = ItemTable
    }

    const options = {
      ...this.props
    }

    return (
      <LandingContainer>
        <Row>
          <ColEx>
            { criteria !== 'batches' && selfContext.geoLocation &&
            <MapComponent
              googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyC9M9YpwL9pUB5BVuiNwkB0nSBh3ED3AtA&v=3.exp&libraries=geometry,drawing,places'
              loadingElement={<MapElement height='100%' />}
              containerElement={<MapElement height='36.5vh' />}
              mapElement={<MapElement height='100%' />}
              entities={entities}
              selfContext={selfContext}
              criteria={criteria}
              customers={customers}
            />
            }
            <TableContainer>
              <TableComponent {...options} saveFilters={(filters) => this.handleSaveFilters(filters)} />
              <PaginationContainer>
                {
                  !isEmpty(paging) && <TablePagination
                    paging={paging}
                    handlePageChange={this.handlePageChange}
                    handlePageSizeChange={this.handlePageSizeChange} />
                }
              </PaginationContainer>
            </TableContainer>
          </ColEx>
        </Row>
      </LandingContainer>
    )
  }
}

const mapStateToProps = ({ entities, dashboard: { criteria, tableFilters }, searchBar: { companies, products } }) => {
  return {
    entities,
    loading: entities.loading,
    paging: entities.paging,
    criteria,
    companies,
    products,
    tableFilters
  }
}

const mapDispatchToProps = {
  onPageChange,
  onPageSizeChange,
  saveFilters
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardLanding)
)
