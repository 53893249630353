const actions = {
  TIMELINE_INIT: 'eventTimeline/TIMELINE_INIT',
  TIMELINE_START: 'eventTimeline/TIMELINE_START',
  TIMELINE_SUCCESS: 'eventTimeline/TIMELINE_SUCCESS',
  TIMELINE_FAIL: 'eventTimeline/TIMELINE_FAIL'
}

export const getEventTimeline = (obj) => ({
  type: actions.TIMELINE_INIT,
  data: obj
})
export const resetEventTimeline = () => ({
  type: actions.TIMELINE_START
})

export default actions
