const actions = {
  FETCH_ALERTS_INIT: 'FETCH_ALERTS_INIT',
  FETCH_ALERTS_FAILED: 'FETCH_ALERTS_FAILED',
  FETCH_ALERTS_SUCCESS: 'FETCH_ALERTS_SUCCESS',

  FETCH_ENTITY_ALERTS_INIT: 'FETCH_ENTITY_ALERTS_INIT',
  FETCH_ENTITY_ALERTS_FAILED: 'FETCH_ENTITY_ALERTS_FAILED',
  FETCH_ENTITY_ALERTS_SUCCESS: 'FETCH_ENTITY_ALERTS_SUCCESS'
}

export const getAllAlerts = (paging) => ({
  type: actions.FETCH_ALERTS_INIT,
  paging
})

export const getEntityAlerts = (obj) => ({
  type: actions.FETCH_ENTITY_ALERTS_INIT,
  data: obj
})

export default actions
