import alertsActions from './actions'

const initialState = {
  notifications: null,
  entityAlerts: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case alertsActions.FETCH_ALERTS_INIT: {
      return { ...state, notifications: null }
    }
    case alertsActions.FETCH_ALERTS_SUCCESS: {
      return { ...state, notifications: action.payload, paging: action.paging }
    }
    case alertsActions.FETCH_ALERTS_FAILED: {
      return { ...state }
    }
    case alertsActions.FETCH_ENTITY_ALERTS_INIT: {
      return { ...state, entityAlerts: null }
    }
    case alertsActions.FETCH_ENTITY_ALERTS_SUCCESS: {
      return { ...state, entityAlerts: action.payload }
    }
    case alertsActions.FETCH_ENTITY_ALERTS_FAILED: {
      return { ...state }
    }
    default:
      return state
  }
}
export default reducer
