import Status from '../../components/Status'
import React from 'react'
import Information from '../../components/EntityDetailView/Information'
import { dateFormatter } from '../../../utility'

export default function (props) {
  const { data, documentId } = props
  const { status, shippedDate, trailerId, deliveryStatus, deliveryDate } = data

  const headerInfo = { label: 'Shipment Status', documentId: documentId, status: status }
  const listOfInfo = [
    {
      label: 'Shipment Status',
      value: <Status status={status} />
    }, {
      label: 'Trail ID',
      value: trailerId
    }, {
      label: 'Shipped Date',
      value: dateFormatter(shippedDate)
    }, {
      label: 'Delivery Status',
      value: <Status status={deliveryStatus} />
    }, {
      label: 'Delivery Date',
      value: dateFormatter(deliveryDate)
    }, {
      label: '',
      value: '',
      isFooter: true
    }
  ]

  return <Information listOfInfo={listOfInfo} headerInfo={headerInfo} />
}
