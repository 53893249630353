import React from 'react'
import ReactDOM from 'react-dom'
import { connect, Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import { App, Login, Logout } from './web/containers'
import * as serviceWorker from './web/serviceWorker'
import { configureStore } from './redux/store'
import { sagas as rootSaga } from './redux/modules/index'

import { localeConfigs } from './i18n'

// Put Unsecured Pages in below route, all routes configured inside AppComponent are secured
let LocalizedRoot = ({ locale }) => {
  const localConfig = localeConfigs[locale] || localeConfigs['en-US']
  const messages = localConfig.intl
  const antLocale = localConfig.antd
  return <IntlProvider locale={locale} key={locale} messages={messages}>
    <ConfigProvider locale={antLocale}>
      <Router>
        <Switch>
          <Route path='/login' component={Login} />
          <Route exact path='/logout' component={Logout} />
          <Route path='*' component={App} />
        </Switch>
      </Router>
    </ConfigProvider>
  </IntlProvider>
}

const mapStateToProps = ({ locale: { locale } }) => ({ locale })
LocalizedRoot = connect(mapStateToProps)(LocalizedRoot)

const sagaMiddleware = createSagaMiddleware()

// Initialize the redux store
const { store, persistor } = configureStore({ middleware: sagaMiddleware })
sagaMiddleware.run(rootSaga)

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizedRoot />
    </PersistGate>
  </Provider>
)

const containerElement = document.querySelector('#root')
if (containerElement) {
  ReactDOM.render(<Root />, containerElement)
} else {
  throw new Error('Could not find any html section with ID "root", Can not start the application')
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

export default store
