import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'

const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  color: #757575;
`
const BulletSpan = styled.div`
  display: flex;
  align-items: center;
  &::${props => props.relatedWindow ? 'before' : 'after'} {
    display: inline-block;
    content: "";
    border-radius: 0.375rem;
    margin: 0.3rem;
    padding: 5px;
    margin-left: ${props => props.relatedWindow ? '1px' : '6px'};
    background-color: ${props => props.backgroundColor};
  }
`

type Props = {
  status: string
}

const Status = function (props: Props) {
  const { status = '', showDot = true, relatedWindow = false } = props

  if (!status) {
    return <span>N/A</span>
  }

  let backgroundColor

  // We can add more colors in below based on all kinds of status
  switch (status.toLowerCase()) {
    case 'none':
      backgroundColor = 'lightgray'
      break
    case 'delivered':
      backgroundColor = 'green'
      break
    default:
      backgroundColor = '#52c41a'
  }

  return showDot ? <BulletSpan relatedWindow={relatedWindow}backgroundColor={backgroundColor}><Tooltip title={status}><Text>{status}</Text></Tooltip></BulletSpan> : <Text><Tooltip title={status}>{status}</Tooltip></Text>
}

export default Status
