import React from 'react'
import { Table } from 'antd'
import { TableUtils } from '../../../components'
import TableStyled from '../../../components/TableUtils/TableStyled'
import { FormattedMessage } from 'react-intl'
import uuidv4 from 'uuid/v4'
import { SettingOutlined } from '@ant-design/icons'
import ConfTable from './ConfTable'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import styled from 'styled-components'
const { renderCustomerName, renderIdentification, renderDate, renderState, dateSorter, customSorter, identificationSorter } = TableUtils

const ItemSubTable = styled(Table)`
.ant-table-body {
  min-height: 0vh;
}
`
const filterOptions = {
  dataIndex: 'configuration',
  key: 'configuration',
  filterIcon: <SettingOutlined />,
  filters: [
    { text: 'Identification', value: 'identification.instance' },
    { text: 'Current Custody', value: 'currentCustody' },
    { text: 'Produced By', value: 'producedBy' },
    { text: 'Created At', value: 'digitizedTimestamp' },
    { text: 'Status', value: 'status' },
    { text: 'Owner', value: 'owner' },
    { text: 'State', value: 'state' }
  ],
  width: '2%'
}
const columns = () => {
  return [
    {
      title: <FormattedMessage id='identification' defaultMessage='Identification' />,
      key: 'identification.instance',
      dataIndex: 'identification.instance',
      render: renderIdentification,
      sorter: identificationSorter('identification.instance')
    },
    {
      title: <FormattedMessage id='currentCustody' defaultMessage='Current Custody' />,
      key: 'currentCustody',
      dataIndex: 'currentCustody.tenantName',
      sorter: customSorter('currentCustody.tenantName')
    },
    {
      title: <FormattedMessage id='producedBy' defaultMessage='produced By' />,
      key: 'producedBy',
      dataIndex: 'producedBy.tenantName',
      ellipsis: true,
      sorter: customSorter('producedBy.tenantName')
    },
    {
      title: <FormattedMessage id='digitizedTimestamp' defaultMessage='Created At' />,
      key: 'digitizedTimestamp',
      dataIndex: 'digitizedTimestamp',
      render: renderDate,
      sorter: dateSorter('digitizedTimestamp')
    },
    {
      title: <FormattedMessage id='status' defaultMessage='Status' />,
      dataIndex: 'status',
      key: 'status',
      sorter: customSorter('status')
    },
    {
      title: <FormattedMessage id='owner' defaultMessage='Owner' />,
      dataIndex: 'owner.tenantId',
      key: 'owner',
      render: renderCustomerName(),
      sorter: customSorter('owner.tenantId')
    },
    {
      title: <FormattedMessage id='state' defaultMessage='State' />,
      dataIndex: 'state.name',
      key: 'state',
      render: renderState,
      sorter: customSorter('state.name')
    }
  ]
}

function expandedRowTitle (data) {
  const columns = []
  Object.keys(data).forEach(key => {
    columns.push({
      title: startCase(key),
      dataIndex: key,
      key: key,
      render: includes(key.toLowerCase(), 'date') && renderDate
    })
  })
  return columns
}

const expandedRowRender = (record, index, indent, expanded) => {
  const rowDataSource = { ...record.metadata, ...record.identification.class, ...record.identification.instance }
  const rowColumns = expandedRowTitle(rowDataSource)
  return <ItemSubTable size='small' columns={rowColumns} dataSource={[rowDataSource]} pagination={false} rowKey={() => uuidv4()} />
}

const ItemTable = function (props) {
  const { entities, loading } = props
  const dataSource = entities.items
  return (
    <TableStyled>
      <ConfTable
        columns={columns()}
        dataSource={dataSource}
        loading={loading}
        criteria={'items'}
        options={props}
        filterOptions={filterOptions}
        expandedRowRender={expandedRowRender}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              const primaryKey = Object.keys(record.identification.instance)[0]
              if (record.parent.id) {
                // parent id exists, identifier is identification.instance.epc for 6pack || identification.instance.SSCC128 for shipping case
                props.history.push(`itemDetail/${record.identification.instance[primaryKey]}`)
              } else {
                // else trailer identifier is CODE128
                props.history.push(`caseDetail/${record.identification.instance[primaryKey]}`)
              }
            }
          }
        }}
        saveFilters={(filters) => props.saveFilters(filters)}
      />
    </TableStyled>
  )
}

export default ItemTable
