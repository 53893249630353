import React from 'react'
import { Table } from 'antd'
import TableStyled from '../../components/TableUtils/TableStyled'
import uuidv4 from 'uuid/v4'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { doLoadItems } from '../../../redux/modules/entities/actions'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import { TableUtils } from '../../components'
const { renderDate } = TableUtils

function columnsData (data) {
  let columns = []
  const dataSource = { ...data.metadata, ...data.identification.class, ...data.identification.instance }
  Object.keys(dataSource).forEach(key => {
    columns.push({
      title: startCase(key),
      dataIndex: key,
      key: key,
      render: includes(key.toLowerCase(), 'date') && renderDate
    })
  })
  return columns
}

function tableData (data) {
  let dataSource = []
  data.forEach(xs => {
    dataSource.push({
      ...xs.metadata,
      ...xs.identification.class,
      ...xs.identification.instance
    })
  })
  return dataSource
}

const CaseDetailTable = (props) => {
  const { loading, childrenItems } = props

  return <>
    <TableStyled>
      <Table
        scroll={{ y: 'auto' }}
        columns={childrenItems && childrenItems.length ? columnsData(childrenItems[0]) : []}
        dataSource={childrenItems && childrenItems.length ? tableData(childrenItems) : []}
        size='small'
        rowKey={() => uuidv4()}
        loading={loading}
      />
    </TableStyled>
  </>
}

const mapStateToProps = ({ entities: { loading, childrenItems } }) => {
  return {
    childrenItems,
    loading: loading
  }
}

const mapDispatchToProps = {
  loadCaseItems: doLoadItems
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CaseDetailTable))
