import Utility from './utility'
import CONST from './constants'

const { isUuidValid, isDateValid, requiredError, validateError, headerError, isNumber, isExclusiveMinimumZero } = Utility
const { SHIPMENT_STATUS, REJECTION_STATUS } = CONST

const ShipmentsConfig = {
  headers: [
    {
      name: 'orderId',
      inputName: 'orderId',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'orderId'),
      validate: orderId => isUuidValid(orderId),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, 'should be valid uuid')
    },
    {
      name: 'orderNumber',
      inputName: 'orderNumber',
      unique: false,
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'orderNumber')
    },
    {
      name: 'customerId',
      inputName: 'customerId',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'customerId'),
      validate: customerId => isUuidValid(customerId),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, 'should be valid uuid')
    },
    {
      name: 'volume',
      inputName: 'volume',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'volume'),
      validate: (volume) => isNumber(volume) && isExclusiveMinimumZero(volume),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be a positive number`)
    },
    {
      name: 'volumeUOM',
      inputName: 'volumeUOM',
      unique: false,
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'volumeUOM')
    },
    {
      name: 'weight',
      inputName: 'weight',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'weight'),
      validate: (weight) => isNumber(weight) && isExclusiveMinimumZero(weight),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be a positive number`)
    },
    {
      name: 'weightUOM',
      inputName: 'weightUOM',
      unique: false,
      required: true,
      headerError: (headerName) => headerError(headerName, 'weightUOM'),
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber)
    },
    {
      name: 'shipmentDate',
      inputName: 'shipmentDate',
      unique: false,
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'shipmentDate'),
      validate: shipmentDate => isDateValid(shipmentDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`)
    },
    {
      name: 'deliveryDate',
      inputName: 'deliveryDate',
      unique: false,
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'deliveryDate'),
      validate: deliveryDate => isDateValid(deliveryDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`)
    },
    {
      name: 'plannedDeliveryDate',
      inputName: 'plannedDeliveryDate',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'plannedDeliveryDate'),
      validate: plannedDeliveryDate => isDateValid(plannedDeliveryDate),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `date should be an ISO date time format`)
    },
    {
      name: 'status',
      inputName: 'status',
      unique: false,
      validate: (status) => SHIPMENT_STATUS.includes(status.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${SHIPMENT_STATUS.join()}]`)
    },
    {
      name: 'rejectionStatus',
      inputName: 'rejectionStatus',
      unique: false,
      validate: (rejectionStatus) => REJECTION_STATUS.includes(rejectionStatus.trim()),
      validateError: (headerName, rowNumber, columnNumber) => validateError(headerName, rowNumber, columnNumber, `has to be one of these values: [${REJECTION_STATUS.join()}]`)
    },
    {
      name: 'number',
      inputName: 'number',
      required: true,
      requiredError: (headerName, rowNumber, columnNumber) => requiredError(headerName, rowNumber, columnNumber),
      headerError: (headerName) => headerError(headerName, 'number')
    },
    {
      name: 'notes',
      inputName: 'notes',
      unique: false
    }
    // shipment-items validation
  ]
}
export default ShipmentsConfig
