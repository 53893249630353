import { call, put, takeLatest } from 'redux-saga/effects'
import statActions from './actions'
import { dateStatisticsKey } from '../../../configs/objects'
import { apiRequest } from '../../../utility/fetch'
import { RANGE_SELECTOR } from '../../../utility'
import { BASE_URLS } from '../../../environment/api'
import store from '../../../../src'
import validate from 'uuid-validate'
import isEmpty from 'lodash/isEmpty'

const FILTER_NULLS = (key, value) => {
  if (!isEmpty(value)) return value
}

function * fetchStatistics (action) {
  let { dashboard: { criteria } } = store.getState()
  let baseUrl
  let criteriaName; let template = {}
  if (validate(criteria)) {
    criteriaName = 'items'
    baseUrl = BASE_URLS.API_PLATFORM_BASE_URL
    template = {
      id: criteria
    }
  } else {
    criteriaName = criteria
    baseUrl = BASE_URLS.API_TRUSTLINK_OPERATIONS_URL
  }
  const rangeValue = RANGE_SELECTOR[action.range]
  const dateIdentifier = dateStatisticsKey[criteriaName.toUpperCase()]

  const url = `${baseUrl}/${criteriaName}/statistics`
  const options = {
    method: 'POST',
    body: JSON.stringify({
      query: {
        [dateIdentifier]: {
          from: rangeValue[0],
          to: rangeValue[1]
        },
        template
      }
    }, FILTER_NULLS)
  }
  yield put({ type: statActions.FETCH_STAT_START, criteria: criteria, range: action.range })
  try {
    const response = yield call(apiRequest, url, options)
    if (response.success) {
      yield put({ type: statActions.FETCH_STAT_SUCCESS, payload: response.data, criteria: criteria })
    } else {
      yield put({ type: statActions.FETCH_STAT_FAILED, payload: response.message })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: statActions.FETCH_STAT_FAILED, payload: err })
  }
}

export default function * actionWatcher () {
  yield takeLatest(statActions.FETCH_STAT_INIT, fetchStatistics)
}
