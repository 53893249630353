const actions = {
  CHANGE_LOCALE: 'locale/CHANGE_LOCALE'
}

export const changeLocale = (locale) => ({
  type: actions.CHANGE_LOCALE,
  locale
})

export default actions
