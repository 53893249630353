import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import UserProfileDetails from './UserProfileDetails'

class Profile extends Component {
  render () {
    const { user } = this.props
    return (
      <UserProfileDetails user={user} />
    )
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user
  }
}

export default withRouter(connect(mapStateToProps)(Profile))
