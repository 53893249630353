// @flow
import styled from 'styled-components'
import React from 'react'
import type SideLinkMenusType from './types'
import { Status } from '../index'
import { Row, Col, Tooltip } from 'antd'

const MenuItemContainer = styled.div`
  background-color: white;
  padding: 10px;
  line-height: 15px;
  cursor: pointer;
  margin-top: 8px;
  border-radius: 3px;
  border: ${props => props.active ? '#2196F3 1px solid' : 'none'};
  box-shadow: ${props => props.active ? '0 0 4px 0 rgba(33,150,243,0.26)' : 'none'};
`
const StatusContainer = styled.div`
  margin: 1px 0;
`

const NumberField = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #212121;
`

const Content = styled.div`
  padding:2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  color: #757575;
`

const DateTitle = styled.div`
  font-size: 11px;
  color: #757575;
`

const DateField = styled.div`
  font-size: 11px;
  color: #757575;
`

const MenuContainer = styled.div`
  position: relative;
`

const MenusContainer = styled.div`
  overflow-y: auto;
  height: 84vh;
  @media(max-height:768px){
    height: 77.5vh;
  }
  padding:6px;
  box-shadow: 0 0 2px #ddd;
  &::-webkit-scrollbar {
    display: none;
  }
`
const DateCol = styled(Col)`
  display: flex;
    flex-direction: column;
    align-items: flex-end;
`
const InfoCol = styled(Col)`
`

type Props = {
  menus: SideLinkMenusType,
  selectedId: string,
  handleSelect: any
}

export default (props: Props) => {
  const { menus, selectedId, handleSelect } = props
  return <MenusContainer>
    {menus.map((menu) => {
      const { id, title, status, content, dateTitle, date } = menu
      const isActive = id === selectedId

      return <MenuContainer key={id}>
        <MenuItemContainer onClick={() => handleSelect(id)} active={isActive}>
          <Row>
            <InfoCol span={12}>
              <Tooltip placement='right' title={title}><NumberField >{title}</NumberField></Tooltip>
              <Tooltip placement='right' title={content}><Content >{content}</Content></Tooltip>
              <StatusContainer><Status status={status} relatedWindow /></StatusContainer>
            </InfoCol>
            <DateCol span={12}>
              <DateTitle>{dateTitle}</DateTitle>
              <DateField>{date}</DateField>
            </DateCol>
          </Row>
        </MenuItemContainer>
      </MenuContainer>
    })}
  </MenusContainer>
}
