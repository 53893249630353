const actions = {
  SELECT_CRITERIA: 'search/SELECT_CRITERIA',
  QUERY: 'search/QUERY',
  CUSTOMER: 'search/CUSTOMER',
  RANGE: 'search/RANGE',
  RESET: 'search/RESET',
  SEARCH_SUCCESS: 'search/SUCCESS',
  SEARCH_FAILURE: 'search/FAILURE',
  INITIAL_LOAD: 'search/INIT',
  PAGE_CHANGE: 'options/PAGE_CHANGE',
  PAGE_SIZE_CHANGE: 'options/PAGE_SIZE_CHANGE',
  SEARCH_CRITERIA_INIT: 'search/SEARCH_CRITERIA',
  SEARCH_CRITERIA_START: 'search/SEARCH_CRITERIA_START',
  SEARCH_CRITERIA_SUCCESS: 'search/SEARCH_CRITERIA_SUCCESS',
  FETCH_START: 'search/FETCH_START',
  PRODUCT: 'search/PRODUCT',
  SAVE_FILTERS: 'search/SAVE_FILTERS'
}

export const onProductChange = (obj) => ({
  type: actions.PRODUCT,
  data: obj
})

export const onSearchCriteria = () => ({
  type: actions.SEARCH_CRITERIA_INIT
})

export const onCriteriaChange = (obj) => ({
  type: actions.SELECT_CRITERIA,
  data: obj
})

export const onQueryChange = (obj) => ({
  type: actions.QUERY,
  data: obj
})

export const onCustomerChange = (obj) => ({
  type: actions.CUSTOMER,
  data: obj
})

export const onRangeChange = (obj) => ({
  type: actions.RANGE,
  data: obj
})

export const onResetFilter = (obj) => ({
  type: actions.RESET
})

export const onInitialLoad = (obj) => ({
  type: actions.INITIAL_LOAD,
  data: obj
})

export const onPageChange = (page) => ({
  type: actions.PAGE_CHANGE,
  data: page
})

export const onPageSizeChange = (pageSize) => ({
  type: actions.PAGE_SIZE_CHANGE,
  data: pageSize
})

export const saveFilters = (obj) => ({
  type: actions.SAVE_FILTERS,
  data: obj
})
export default actions
