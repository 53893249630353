// @flow
import React from 'react'
import styled from 'styled-components'
import TableUtils from './TableUtils'
const { renderCustomerName } = TableUtils

const AddressContainer = styled.div`
  line-height: 18px;
`
const Details = styled.div`
  padding: 10.5px;
  max-height:20vh;
  overflow: auto;
`
const Title = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: lighter;
  color: black;
`

const Name = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin: 3px 21px;
`

const AddressItem = styled.div`
  font-size: 13px;
  margin: 0 21px;
  color: #757575;
`

// const Email = styled.a``

type Address = {
  title: string,
  addressCountry: String,
  addressLocality: string,
  addressRegion: string,
  postalCode: string,
  streetAddress: string,
  name: string,
  customerId: string
}

export default (props: any) => {
  const address: Address = props.address
  const { title, addressCountry, addressLocality, addressRegion, postalCode, streetAddress, name, customerId } = address

  return <>
    <AddressContainer>
      <Title>{title}</Title>
      <Details>
        <Name>{name || renderCustomerName()(customerId)}</Name>
        {streetAddress ? <AddressItem>{`${streetAddress},`}</AddressItem> : <AddressItem>{'Address Unavailable'}</AddressItem>}
        {addressLocality && <AddressItem>{addressLocality}</AddressItem>}
        {addressRegion && <AddressItem>{`${addressRegion} ${postalCode}`}</AddressItem>}
        {addressCountry && <AddressItem>{addressCountry.toUpperCase()}</AddressItem>}
        {/* {phone && <AddressItem>{phone}</AddressItem>}
      {fax && <AddressItem>Fax: {fax}</AddressItem>}
      {email && <Email>{email}</Email>}
      {contact && <AddressItem>{contact}</AddressItem>} */}
      </Details>
    </AddressContainer>
  </>
}
