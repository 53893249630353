const actions = {
  FETCH_STAT_INIT: 'statistics/FETCH_STAT_INIT',
  FETCH_STAT_START: 'statistics/FETCH_STAT_START',
  FETCH_STAT_FAILED: 'statistics/FETCH_STAT_FAILED',
  FETCH_STAT_SUCCESS: 'statistics/FETCH_STAT_SUCCESS',

  RANGE_CHANGE: 'statistics/RANGE_CHANGE'
}
export const getStatistics = (range) => ({
  type: actions.FETCH_STAT_INIT,
  range
})

export default actions
