import actions from './actions'

const initialState = {
  orders: [],
  address: {},
  loading: false,
  error: null,
  paging: {},
  recallLoading: false,
  treeHierarchy: [],
  batchStatus: []
}
const entitiesReducer = (state = initialState, action) => {
  const entityType = action.entityType
  switch (action.type) {
    case actions.FETCH_START:
      return {
        ...state,
        loading: true
      }
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        [entityType]: action.payload,
        paging: {
          ...action.paging
        },
        loading: false
      }
    case actions.FETCH_FAILURE:
      return {
        ...state,
        [entityType]: [],
        error: action.payload,
        loading: false
      }
    case actions.BATCH_STATUS_UPDATE_INIT:
      return {
        ...state,
        recallLoading: true,
        recallStatus: null
      }
    case actions.BATCH_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        recallStatus: 'fail',
        recallLoading: false
      }
    case actions.BATCH_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        recallStatus: 'success',
        recallLoading: false
      }
    case actions.BATCH_STATUS_UPDATE_RESET:
      return {
        ...state,
        recallStatus: null,
        recallLoading: false
      }
    case actions.ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.payload
      }
    case actions.ADDRESS_FAILURE:
      return {
        ...state,
        address: {},
        error: action.payload
      }
    case actions.LOAD_BATCH_STATUS_INIT:
      return { ...state, batchStatus: [], recallLoading: false }
    case actions.LOAD_BATCH_STATUS_SUCCESS:
      return { ...state, batchStatus: action.payload }
    case actions.LOAD_BATCH_STATUS_FAILED:
      return state
    default:
      return state
  }
}
export default entitiesReducer
