import React from 'react'
import _ from 'lodash'
import { Empty } from 'antd'
import { SideLinks, Container, TableUtils } from '../../components'
import { dateFormatter } from '../../../utility'

const { SideLink, getDatesRangeText } = SideLinks
const { renderCustomerName } = TableUtils

export default (props) => {
  const { shipments, shipmentId, selectShipment } = props

  if (_.isEmpty(shipments)) {
    return <Container><Empty /></Container>
  }

  const menus = props.shipments.map(({ id, number, status, dateTitle, date, customerId }) => {
    return {
      id: id,
      title: number,
      status: status,
      content: renderCustomerName()(customerId),
      dateTitle: 'Shipment Date',
      date: dateFormatter(date)
    }
  })

  return <SideLink
    menus={menus}
    title='Related Shipments'
    subTitle={`${getDatesRangeText(shipments, 'deliveryDate')}`}
    selectedId={shipmentId}
    handleSelect={selectShipment}
  />
}
