import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col, Spin } from 'antd'
import SearchBar from './SearchBar'
import LandingPage from './LandingPage'
import ROLES from '../../../configs/roles'
import { Container } from '../../components'
import { buildConfigBasedOptions } from '../../../utility/config'
import { onSearchCriteria } from '../../../redux/modules/dashboard/actions'
import { getLogo } from '../../../redux/modules/user/actions'
import styled from 'styled-components'
import FastFacts from './FastFacts'
import StatisticCards from './widgets/statisticCards'
import isEmpty from 'lodash/isEmpty'

const DashbordContainer = styled.div`
  padding: 20px 22px;
`
const FastFactsCol = styled(Col)`
  margin-top: -51.4px;
`
const RowEx = styled(Row)`
  background-color: #f2f2f2;
  padding-bottom: 0;
  // height: 90vh;
`
const SpinEx = styled(Spin)`
  margin: ${props => props.criteria !== 'batches' ? '35vh 38vw' : '35vh 46vw'};
`
class Dashboard extends Component {
  componentDidMount () {
    const { onSearchCriteria, getLogo, tenant: { documentId } = {} } = this.props
    documentId && getLogo({ documentId })
    onSearchCriteria()
  }

  generateSearchCriteria () {
    const { itemTemplates } = this.props
    const searchContext = ROLES.MANUFACTURER

    const configBasedEntities = buildConfigBasedOptions(searchContext.searchCriteria)
    itemTemplates && itemTemplates.forEach(criteria => {
      if (searchContext.searchCriteria.filter(xs => xs.value === criteria.value).length === 0) {
        const name = criteria.name.replace('-', ' ')
        configBasedEntities.push({ ...criteria, name: name })
      }
    })
    return configBasedEntities
  }

  getSelfTenantInfo () {
    const { location = {} } = this.props
    return {
      name: location.name,
      geoLocation: location.address && location.address.geoLocation,
      postalAddress: location.address && location.address.postalAddress
    }
  }

  render () {
    const { loading, criteria, entities } = this.props
    const searchCriteria = this.generateSearchCriteria()
    const selfContext = this.getSelfTenantInfo()

    return (
      <DashbordContainer>
        <RowEx>
          <Col span={criteria !== 'batches' ? 20 : 24}>
            <SearchBar searchCriteria={searchCriteria} />
          </Col>
        </RowEx>
        <RowEx>
          <Col span={criteria === 'batches' && isEmpty(entities.batches) ? 24 : 20}>
            <Container>
              { loading ? <SpinEx criteria={criteria} size='large' /> : <LandingPage selfContext={selfContext} /> }
            </Container>
          </Col>
          { criteria !== 'batches' && <FastFactsCol span={4}>
            <FastFacts />
          </FastFactsCol>
          }
          { (criteria === 'batches' && !isEmpty(entities.batches)) && <Col span={4}>
            <StatisticCards widgetData={entities.batches} />
          </Col>
          }
        </RowEx>
      </DashbordContainer>
    )
  }
}

const mapStateToProps = ({ entities, dashboard: { criteria, searchCriteria }, user: { tenant, location, loading: userLoading }, searchBar: { loading: companyLoading } }) => {
  return {
    tenant,
    itemTemplates: searchCriteria,
    location,
    loading: companyLoading || userLoading,
    criteria,
    entities
  }
}

const mapDispatchToProps = {
  onSearchCriteria,
  getLogo
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
)
