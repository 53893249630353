import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Barcode } from '@styled-icons/icomoon'
import { Container, EntityDetailView } from '../../components'
import BatchSideLink from './BatchSideLink'
import { entityTitle } from '../../../configs/objects'
import BatchDetailTable from './BatchDetailTable'
import { doLoadBatchItems, doLoadDocuments } from '../../../redux/modules/entities/actions'
import BatchStatusInformation from './BatchStatusInformation'
import { Row, Col, Icon } from 'antd'
import { getEntityAlerts } from '../../../redux/modules/alerts/actions'

const IconEx = styled(Icon)`
  margin-right: 12px;
  border: 1px solid #c5c5c5;
  padding: 3px;
  border-radius: 20px;
  color: #c5c5c5;
`
const RowEx = styled(Row)`
  max-height: 89vh;
  overflow: hidden;
`
const Title = styled.span`
  font-weight: normal;
  color: #212121;
  font-size: 15px;
`
const ColSideLink = styled(Col)`
  margin-right: -20px !important;
`
const ColDetails = styled(Col)`
  padding-left:0 !important;
`
const SideLinkContainer = styled(Container)`
  background-color: #F3F4F6;
  padding: 0;
`

const ContainerEx = styled(Container)`
  padding: 0;
  min-height: 400px;
  flex-grow: 1;
`
const BatchIcon = styled(Barcode)`
  color: #2196f3;
  width: 45px;
  padding: 5px;
  background-color: #f7f7f7;
`

class BatchDetailPage extends React.Component {
  componentDidMount () {
    const { match, loadBatchItems, loadDocuments, batches, getEntityAlerts } = this.props
    const { batchNumber } = match.params
    const batch = _.find(batches, { number: batchNumber })
    loadBatchItems(batchNumber)
    loadDocuments(batch.id)
    getEntityAlerts(batch.id)
  }

  selectBatch = (id) => {
    const { loadBatchItems, loadDocuments, history, batches, getEntityAlerts } = this.props
    const batch = _.find(batches, { id })
    history.push(`${batch.number}`)
    loadBatchItems(batch.number)
    loadDocuments(id)
    getEntityAlerts(id)
  }

  render () {
    const { batches, match, documents, batchStatus, entityAlerts } = this.props
    const { batchNumber } = match.params
    const batch = _.find(batches, { number: batchNumber })
    const showInitiateRecall = true
    const showAlert = true

    const address = [{
      title: <Title><IconEx type='user' />Created At</Title>
    }]

    return <RowEx>
      <ColSideLink span={5}>
        <SideLinkContainer>
          <BatchSideLink batches={batches} batchId={batch.id} selectBatch={this.selectBatch} />
        </SideLinkContainer>
      </ColSideLink>
      <ColDetails span={19}>
        <ContainerEx>
          <EntityDetailView data={batch} documentId={batchNumber} icon={BatchIcon}
            entity={entityTitle.BATCH} subtitle={{}} documents={documents} information={BatchStatusInformation}
            table={BatchDetailTable} tableTitle='Items' address={address} showInitiateRecall={showInitiateRecall} showAlert={showAlert} batchStatus={batchStatus} entityAlerts={entityAlerts} />
        </ContainerEx>
      </ColDetails>
    </RowEx>
  }
}

const mapStateToProps = ({ entities: { loading, batches, documents, batchStatus }, alerts: { entityAlerts } }) => {
  return {
    batches,
    documents,
    loading: loading,
    batchStatus,
    entityAlerts
  }
}

const mapDispatchToProps = {
  loadBatchItems: doLoadBatchItems,
  loadDocuments: doLoadDocuments,
  getEntityAlerts
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchDetailPage))
