import React from 'react'
import _ from 'lodash'
import { Empty } from 'antd'
import styled from 'styled-components'
import SideLinkHeader from './SideLinkHeader'
import SideLinkMenus from './SideLinkMenus'
import { Container } from '../index'
import { dateFormatter } from '../../../utility'

const SideLinkContainer = styled.div`
  position: relative;
  margin-right: 22px;
  padding: 0 22px;
`

const SideLink = (props) => {
  const { title, subTitle, menus, selectedId, handleSelect } = props

  if (_.isEmpty(menus)) {
    return <Container><Empty /></Container>
  }

  return <SideLinkContainer>
    <SideLinkHeader title={title} subTitle={subTitle} />
    <SideLinkMenus menus={menus} selectedId={selectedId} handleSelect={handleSelect} />
  </SideLinkContainer>
}

const getDatesRangeText = (listOfData, dateFieldName) => {
  const sortedList = _.sortBy(listOfData, dateFieldName)
  const firstItem = sortedList[0]
  const lastItem = _.last(sortedList)

  const startDate = dateFormatter(firstItem[dateFieldName])
  const endDate = dateFormatter(lastItem[dateFieldName])

  return startDate === endDate ? startDate : `${startDate} - ${endDate}`
}

export default {
  SideLink,
  getDatesRangeText
}
