/* global google */
import React from 'react'
import bluePin from '../../../assets/blue_pin.svg'
import greenPin from '../../../assets/green_pin.svg'
import { Marker, Polyline, InfoWindow } from 'react-google-maps'
import MarkerDetails from '../map/markerDetails'
import { getCompanyCoordinates } from './utility'

const polylineOptions = {
  strokeColor: '#808080',
  strokeOpacity: '1.0',
  strokeWeight: 2
}
const getPosition = (props) => props.manufacturer ? props.manufacturerLocation : getCompanyCoordinates(props.data)

export default props => (
  <Marker
    key={props.id}
    position={getPosition(props)}
    onClick={props.onClick}
    icon={{
      url: props.manufacturer ? greenPin : bluePin,
      scaledSize: new google.maps.Size(30, 34.66)
    }}
  >
    {props.selectedMarker === props.id && (
      <InfoWindow
        position={getPosition(props)}
        onCloseClick={props.onClick}
      >
        <MarkerDetails options={props} />
      </InfoWindow>
    )}
    <Polyline
      path={[getPosition(props), props.manufacturerLocation]}
      options={polylineOptions}
    />
  </Marker>
)
