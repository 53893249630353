import statActions from './actions'

const initialState = {
  error: null,
  loading: true,
  range: 'Today'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case statActions.FETCH_STAT_START: {
      return { ...state, loading: true, range: action.range }
    }
    case statActions.FETCH_STAT_SUCCESS: {
      return { ...state, loading: false, [action.criteria]: action.payload }
    }
    case statActions.FETCH_STAT_FAILED: {
      return { ...state, loading: false, error: action.payload }
    }

    default:
      return state
  }
}
export default reducer
