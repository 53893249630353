import React, { Component } from 'react'
import orderBy from 'lodash/orderBy'
import { Button, Spin, Divider, Row, Icon } from 'antd'
import styled from 'styled-components'
import { dayDateTimeFormatter } from '../../../utility'

const NotificationCardContainer = styled.div`
  max-height: 38vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 20px;
`
const NotificationCard = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #D9DADB;
    padding: 6px 15px;
    margin-bottom: 3px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: white;
    border-left: 4px solid #73C5C5;
`
const ContentLeft = styled.div`
    flex: 2;
    padding: 0 15px;
    font-size: 12px;
    color: #000000;
`
const ContentRight = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
const IconContainer = styled.div`
  border-radius: 50%;
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  background-color: #73C5C5;
  font-size: 13px;
`

const CardIcon = styled(Icon)`
    font-size: 10px;
`
const Time = styled.div`
    color: #757575;
    font-size: 12px;
    line-height: 1;
`
const StyledButton = styled(Button)`
  align-self: center;
  color: #2593FC;
  .ant-btn-link:hover, .ant-btn-link:focus{
    color: #2593FC;
  }
`
const StyledClose = styled(Button)`
    min-width: 0;
    width: 18px;
    height: 18px;
    font-size: 8px;
    background-color: #ebebeb;
    &:hover {
        background-color: #ebebeb;
    }
`
const Reason = styled.div`
  font-size: 14px;
  color: #212121;
`
const Description = styled.div``

class Alert extends Component {
  render () {
    const { entityAlerts } = this.props
    let alerts = <Spin />
    console.log(entityAlerts)
    if (entityAlerts) {
      let sortedentityAlerts = orderBy(entityAlerts, 'createdAt', 'desc')
      alerts = sortedentityAlerts.map((alert, index) => {
        return (
          <NotificationCard key={index}>
            <IconContainer>
              <CardIcon type='bell' />
            </IconContainer>
            <ContentLeft>
              <Reason>
                {alert.context && alert.context.failure ? alert.context.failure.reason : ''}
              </Reason>
              <Description>
                {alert.context && alert.context.failure ? alert.context.failure.description : ''}
              </Description>
              <Time> <Icon type='clock-circle' /> {dayDateTimeFormatter(alert.createdAt)}</Time>
            </ContentLeft>
            <ContentRight>
              <StyledButton type='link'>Notify</StyledButton>
              <Divider type='verticle' />
              <StyledButton type='link'>Resolve</StyledButton>
              <Divider type='verticle' />
              <StyledClose shape='circle' icon='close' />
            </ContentRight>
          </NotificationCard>
        )
      })
    }
    return (
      <Row>
        <NotificationCardContainer> {alerts} </NotificationCardContainer>
      </Row>
    )
  }
}

export default Alert
