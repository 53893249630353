import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TableUtils } from '../../../components'
import { SettingOutlined } from '@ant-design/icons'
import ConfTable from './ConfTable'
import { Table } from 'antd'
import styled from 'styled-components'

const TableEx = styled(Table)`
  .ant-table-placeholder{
    margin-top: -40vh;
    @media (max-height: 768px) {
      margin-top: -16vh;
  }
`
const { renderDate, renderLink, TableStyled, dateSorter, renderCustomerName, customSorter } = TableUtils

const renderShipmentVolumeUOM = (value, row, index) => {
  return `${row.volume} ${row.volumeUOM}`
}

const renderShipmentWeightUOM = (value, row, index) => {
  return `${row.weight} ${row.weightUOM}`
}
const filterOptions = {
  dataIndex: 'configuration',
  key: 'configuration',
  filterIcon: <SettingOutlined />,
  filters: [
    { text: 'Shipment #', value: 'number' },
    { text: 'Customer', value: 'customerId' },
    { text: 'Status', value: 'status' },
    { text: 'Volume', value: 'volume' },
    { text: 'Weight', value: 'weight' },
    { text: 'Delivery Date', value: 'deliveryDate' },
    { text: 'Shipment Date', value: 'shipmentDate' }
  ],
  width: '2%'
}
const columns = () => {
  return [
    {
      title: 'Shipment #',
      dataIndex: 'number',
      key: 'number',
      render: renderLink,
      sorter: customSorter('number')
    },
    {
      title: <FormattedMessage id='customer' defaultMessage='Customer' />,
      key: 'customerId',
      dataIndex: 'customerId',
      render: renderCustomerName(),
      ellipsis: true,
      sorter: customSorter('customerId')
    },
    {
      title: <FormattedMessage id='status' defaultMessage='Status' />,
      key: 'status',
      dataIndex: 'status',
      sorter: customSorter('status')
    },
    {
      title: <FormattedMessage id='volume' defaultMessage='Volume' />,
      key: 'volume',
      dataIndex: 'volume',
      render: renderShipmentVolumeUOM,
      sorter: customSorter('volume')
    },
    {
      title: <FormattedMessage id='weight' defaultMessage='Weight' />,
      key: 'weight',
      dataIndex: 'weight',
      render: renderShipmentWeightUOM,
      sorter: customSorter('weight')
    },
    {
      title: <FormattedMessage id='shipmentDate' defaultMessage='Shipment Date' />,
      key: 'shipmentDate',
      dataIndex: 'shipmentDate',
      render: renderDate,
      sorter: dateSorter('shipmentDate')
    },
    {
      title: <FormattedMessage id='deliveryDate' defaultMessage='Delivery Date' />,
      key: 'deliveryDate',
      dataIndex: 'deliveryDate',
      render: renderDate,
      sorter: dateSorter('deliveryDate')
    }
  ]
}

const ShipmentTable = function (props) {
  let { entities, criteria, loading, dataSource, isDetailsTable = false } = props
  if (!dataSource) {
    dataSource = entities[criteria]
  }
  if (isDetailsTable) {
    // table used in order details
    return (
      <TableStyled>
        <TableEx
          size='small'
          pagination={false}
          scroll={{ y: 'auto' }}
          columns={columns()}
          dataSource={dataSource}
          loading={loading}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => props.history.push(`/shipment/${record.number}`)
            }
          }}
        />
      </TableStyled>)
  }
  return (
    <TableStyled>
      <ConfTable
        columns={columns()}
        dataSource={dataSource}
        loading={loading}
        criteria={criteria}
        options={props}
        filterOptions={filterOptions}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => props.history.push(`/shipment/${record.number}`)
          }
        }}
        saveFilters={(filters) => props.saveFilters(filters)}
      />
    </TableStyled>
  )
}

export default ShipmentTable
