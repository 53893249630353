import React, { Component } from 'react'
import styled from 'styled-components'
import { Tabs, Row, Col } from 'antd'
import DataWizard from './import/dataWizard'
import ImportFile from './import/importFile'
import ExportData from './export'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { documentsFetch } from '../../../redux/modules/documentImport/actions'

const { TabPane } = Tabs

const FlexContainer = styled.div`
    display: flex;
    height: 100%;
`
const LeftChild = styled.div`
    flex: 1;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 5px;
    padding: 28px 20px;
`

class DataMgmt extends Component {
  constructor (props) {
    super(props)
    this.state = {
      importWizard: false
    }
  }

  componentDidMount () {
    this.props.documentsFetch()
  }

  handleImportNew = () => {
    this.setState({
      importWizard: !this.state.importWizard
    })
  }
   closeImportNew = () => {
     this.setState({
       importWizard: !this.state.importWizard
     })
   }

  handleTabClick = (key) => {
    this.props.documentsFetch()
  }

  render () {
    const { data } = this.props
    const documentsData = data
    return (
      <FlexContainer>
        <LeftChild>
          <Row>
            <Col>
              <Tabs defaultActiveKey='selectEntity' type='card' onTabClick={this.handleTabClick} size='default'>
                <TabPane tab='Data Imports' key='selectEntity'>
                  {this.state.importWizard && <ImportFile closeImportNew={this.closeImportNew} />}
                  {!this.state.importWizard && <DataWizard tableData={documentsData} handleImportNew={this.handleImportNew} />}
                </TabPane>
                <TabPane tab='Data Exports' key='dataExport'>
                  <ExportData />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </LeftChild>
      </FlexContainer>
    )
  }
}

const mapStateToProps = ({ documentImport: { loading, data, paging } }) => {
  return {
    loading,
    paging,
    data
  }
}

const mapDispatchToProps = {
  documentsFetch
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataMgmt))
