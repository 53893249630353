import enUS from 'antd/es/locale/en_US'
import frFR from 'antd/es/locale/fr_FR'

import en from './en-US.json'
import fr from './fr-FR.json'

export const localeConfigs = {
  'en-US': {
    intl: en,
    antd: enUS,
    name: 'English'
  },
  'fr-FR': {
    intl: fr,
    antd: frFR,
    name: 'Française'
  }
}
