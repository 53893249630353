const objects = {
  ORDERS: {
    name: 'Orders',
    value: 'orders'
  },
  SHIPMENTS: {
    name: 'Shipments',
    value: 'shipments'
  },
  LOADS: {
    name: 'Loads',
    value: 'loads'
  },
  BATCHES: {
    name: 'Batches',
    value: 'batches'
  }
}

export const entityTitle = {
  ORDER: 'Order',
  LOAD: 'Load',
  SHIPMENT: 'Shipment',
  CASE: 'Case',
  ITEM: 'Item',
  BATCH: 'Batch'
}

export const dateKey = {
  ORDERS: 'orderDate',
  SHIPMENTS: 'shipmentDate',
  LOADS: 'shippedDate',
  BATCHES: 'createdAt'
}

export const dateStatisticsKey = {
  ORDERS: 'orderDate',
  SHIPMENTS: 'shipmentDate',
  LOADS: 'deliveryDate',
  ITEMS: 'digitizedTimestamp'
}

export const placeHolderInput = {
  ORDERS: 'Enter order number...',
  SHIPMENTS: 'Enter shipment number...',
  LOADS: 'Enter loads number...',
  BATCHES: 'Enter batch number...',
  ITEMS: 'Enter item identifier...'
}

export const filterDateKey = {
  ORDERS: 'Order Date',
  SHIPMENTS: 'Shipment Date',
  LOADS: 'Shipped Date',
  BATCHES: 'Creation Date',
  ITEMS: 'Digitized Date'

}
export default objects
