import { put, call, takeLatest } from 'redux-saga/effects'
import actions from './actions'
import dashboardActions from '../dashboard/actions'
import { apiRequest, previewDocumentApi } from '../../../utility/fetch'
import { BASE_URLS } from '../../../environment/api'
import { has } from 'lodash'
function * loadOrderItems (action) {
  const { orderId, page = 1 } = action
  try {
    yield put({ type: actions.FETCH_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_TRUSTLINK_OPERATIONS_URL}/orderItems`, {
      method: 'Get',
      queryParams: {
        orderId,
        page
      }
    })
    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, entityType: 'orderItems' })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message, entityType: 'orderItems' })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * loadShipmentItems (action) {
  const { page = 1, type, ...query } = action
  const body = JSON.stringify({ query })
  try {
    yield put({ type: actions.FETCH_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_TRUSTLINK_OPERATIONS_URL}/shipmentItems/searches`, {
      method: 'POST',
      queryParams: {
        page
      },
      body
    })
    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, entityType: 'shipmentItems' })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message, entityType: 'shipmentItems' })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * loadShipments (action) {
  const { page = 1, orderId } = action
  const body = JSON.stringify({
    query: {
      orderId
    }
  })
  try {
    yield put({ type: actions.FETCH_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_TRUSTLINK_OPERATIONS_URL}/shipments/searches`, {
      method: 'POST',
      queryParams: {
        page
      },
      body
    })
    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, entityType: 'shipments' })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message, entityType: 'shipments' })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * loadDrops (action) {
  const { loadId, page = 1 } = action
  try {
    yield put({ type: actions.FETCH_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_TRUSTLINK_OPERATIONS_URL}/drops/searches`, {
      method: 'POST',
      queryParams: {
        page
      },
      body: JSON.stringify({
        query: {
          loadId
        }
      })
    })
    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, entityType: 'drops' })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message, entityType: 'drops' })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * loadItems (action) {
  const { objectId, page = 1 } = action
  try {
    yield put({ type: actions.FETCH_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_PLATFORM_BASE_URL}/items/retrieve`, {
      method: 'POST',
      queryParams: {
        page
      },
      body: JSON.stringify({
        parent: { id: objectId }
      })
    })
    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, entityType: 'childrenItems' })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message, entityType: 'childrenItems' })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * loadTreeHierarchy (action) {
  const { hierarchyRootNode, page = 1 } = action
  try {
    yield put({ type: actions.FETCH_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_PLATFORM_BASE_URL}/items/retrieve`, {
      method: 'POST',
      queryParams: { page },
      body: JSON.stringify({
        hierarchyRootNode,
        hierarchyDirection: 'up'
      })
    })
    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, entityType: 'treeHierarchy' })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message, entityType: 'treeHierarchy' })
    }
  } catch (err) {
    console.log('error calling item tree search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * loadBatchItems (action) {
  const { batchId, page = 1 } = action
  try {
    yield put({ type: actions.FETCH_START })
    yield put({ type: actions.LOAD_BATCH_STATUS_INIT })
    const response = yield call(apiRequest, `${BASE_URLS.API_PLATFORM_BASE_URL}/items/retrieve`, {
      method: 'POST',
      queryParams: {
        page
      },
      body: JSON.stringify({
        identification: { batchOrLot: { batchNo: batchId } }
      })
    })

    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, entityType: 'batchItems' })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message, entityType: 'batchItems' })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * loadDocuments (action) {
  const { parentId, page = 1 } = action
  try {
    yield put({ type: actions.FETCH_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_PLATFORM_DOCUMENTS_URL}/documents/retrieve`, {
      method: 'POST',
      queryParams: {
        page
      },
      body: JSON.stringify({
        parentId
      })
    })
    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, entityType: 'documents' })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message, entityType: 'documents' })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * previewDocument (action) {
  const { documentId, fileName } = action.data
  try {
    yield call(previewDocumentApi, `${BASE_URLS.API_PLATFORM_DOCUMENTS_URL}/documents/${documentId}/download`, {
      method: 'GET'
    }, fileName)
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * batchUpdateStatus (action) {
  console.log(action)
  const { batchId, status } = action.data
  try {
    const response = yield call(apiRequest, `${BASE_URLS.API_TRUSTLINK_OPERATIONS_URL}/batches/${batchId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          status: status
        })
      })
    if (response.success) {
      // action dispatch to update store with updates status for batch
      yield put({ type: dashboardActions.INITIAL_LOAD })
      yield put({ type: actions.BATCH_STATUS_UPDATE_SUCCESS, payload: response.data })
    } else {
      yield put({ type: actions.BATCH_STATUS_UPDATE_FAILURE, payload: response.message })
    }
  } catch (err) {
    console.log('Error while initiating Recall', err)
    yield put({ type: actions.BATCH_STATUS_UPDATE_FAILURE, payload: err })
  }
}

function * loadAddress (action) {
  const { locationId } = action
  try {
    const response = yield call(apiRequest, `${BASE_URLS.API_PLATFORM_TENANTS_URL}/locations/${locationId}`,
      {
        method: 'GET'
      })
    if (response.success) {
      yield put({ type: actions.ADDRESS_SUCCESS, payload: response.data })
    } else {
      yield put({ type: actions.ADDRESS_FAILURE, payload: response.message })
    }
  } catch (err) {
    console.log('error calling location service')
    yield put({ type: actions.ADDRESS_FAILURE, payload: err })
  }
}

function * loadBatchStatus () {
  try {
    const response = yield call(apiRequest, `${BASE_URLS.API_TRUSTLINK_OPERATIONS_URL}/configs/searches`,
      {
        method: 'POST',
        body: JSON.stringify({
          query: {}
        })
      })
    if (response.success) {
      let _batchStatus = []
      if (response.data[0] && has(response.data[0], ['services', 'batches', 'schema', 'status'])) { _batchStatus = response.data[0].services.batches.schema.status }
      yield put({ type: actions.LOAD_BATCH_STATUS_SUCCESS, payload: _batchStatus })
    } else {
      yield put({ type: actions.LOAD_BATCH_STATUS_FAILED, payload: response.message })
    }
  } catch (err) {
    console.log('error calling location service')
    yield put({ type: actions.LOAD_BATCH_STATUS_FAILED, payload: err })
  }
}

export default function * actionWatcher () {
  yield takeLatest(actions.BATCH_STATUS_UPDATE_INIT, batchUpdateStatus)
  yield takeLatest(actions.LOAD_ORDER_ITEMS, loadOrderItems)
  yield takeLatest(actions.LOAD_SHIPMENT_ITEMS, loadShipmentItems)
  yield takeLatest(actions.LOAD_SHIPMENTS, loadShipments)
  yield takeLatest(actions.LOAD_DROPS, loadDrops)
  yield takeLatest(actions.LOAD_ITEMS, loadItems)
  yield takeLatest(actions.LOAD_BATCH_ITEMS, loadBatchItems)
  yield takeLatest(actions.LOAD_DOCUMENTS, loadDocuments)
  yield takeLatest(actions.PREVIEW_DOCUMENT, previewDocument)
  yield takeLatest(actions.LOAD_ADDRESS, loadAddress)
  yield takeLatest(actions.LOAD_TREE, loadTreeHierarchy)
  yield takeLatest(actions.LOAD_BATCH_STATUS_INIT, loadBatchStatus)
}
