import React, { useState, useEffect } from 'react'
import Marker from './marker'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import intersection from 'lodash/intersection'
import { getCompanyCoordinates, convertLonToLng } from './utility'
import './index.css'
/* global google */

const MapComponent = withScriptjs(withGoogleMap((props) => {
  const defaultOptions = {
    mapTypeControl: true,
    mapTypeControlOptions: {},
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    },
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP
    },
    fullscreenControl: false
  }

  const customers = props.customers
  const manufacturer = props.selfContext
  let criteria = props.criteria
  const entities = props.entities
  const [selectedMarker, setSelectedMarker] = useState(null)
  criteria = ['orders', 'loads', 'shipments', 'batches'].includes(criteria) ? criteria : 'items'
  function handleMarkerSelect (marker) {
    if (marker === selectedMarker) {
      setSelectedMarker(null)
    } else {
      setSelectedMarker(marker)
    }
  }

  useEffect(() => {
    // to reset selected marker on data change
    setSelectedMarker(null)
  }, [entities])

  const customerLookUp = criteria === 'loads'
  // Since loads have no customer data, We flat loads.drops to display on the map
    ? groupBy(entities[criteria] && entities[criteria].map(x => {
      return x.drops
    }).flat(), 'customerId')
    : groupBy(entities[criteria], criteria === 'items' ? 'currentCustody.tenantId' : 'customerId')

  const customerAllLookUp = keyBy(customers, 'id')
  // set bounds only if data present to be marked and the custody is within available companies
  const shouldSetBound = !isEmpty(customerLookUp) && intersection(Object.keys(customerLookUp), Object.keys(customerAllLookUp)).length > 0

  // Calculate bounds for all the markers
  const bounds = new window.google.maps.LatLngBounds()

  // manufacturer pin
  const { lat, lng } = convertLonToLng(manufacturer.geoLocation)
  const manufacturerlatLng = new window.google.maps.LatLng(lat, lng)
  bounds.extend(manufacturerlatLng)

  // customer pins
  Object.keys(customerLookUp).forEach(customer => {
    const { lat, lng } = getCompanyCoordinates(customerAllLookUp[customer])
    const latLng = new window.google.maps.LatLng(lat, lng)
    lat && bounds.extend(latLng)
  })

  return (
    <GoogleMap
      defaultOptions={defaultOptions}
      defaultZoom={8}
      defaultCenter={convertLonToLng(manufacturer.geoLocation)}
      ref={map => map && shouldSetBound && map.fitBounds(bounds)}
    >
      <Marker
        key={manufacturer.name}
        manufacturer
        data={manufacturer}
        id={manufacturer.name}
        manufacturerLocation={convertLonToLng(manufacturer.geoLocation)}
        onClick={() => handleMarkerSelect(manufacturer.name)}
        selectedMarker={selectedMarker}
      />
      {
        Object.keys(customerLookUp).map(customerId => {
          return (
            customerAllLookUp[customerId] && (
              <Marker
                key={customerId}
                count={customerLookUp[customerId].length}
                criteria={criteria === 'loads' ? 'drops' : criteria}
                id={customerId}
                data={customerAllLookUp[customerId]}
                manufacturerLocation={convertLonToLng(manufacturer.geoLocation)}
                onClick={() => handleMarkerSelect(customerId)}
                selectedMarker={selectedMarker}
              />
            )
          )
        }
        )}
    </GoogleMap>
  )
}
))

export default MapComponent
