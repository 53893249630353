const actions = {
  // === Reserved for set entities with list of result ===
  FETCH_SUCCESS: 'entity/FETCH_SUCCESS',
  FETCH_START: 'entity/FETCH_START',
  FETCH_FAILURE: 'entity/FETCH_FAILURE',
  // =================================================

  LOAD_ORDER_ITEMS: 'LOAD_ORDER_ITEMS',
  LOAD_SHIPMENT_ITEMS: 'LOAD_SHIPMENT_ITEMS',
  LOAD_SHIPMENTS: 'LOAD_SHIPMENTS',
  LOAD_DROPS: 'LOAD_DROPS',
  LOAD_ITEMS: 'LOAD_ITEMS',
  LOAD_TREE: 'LOAD_TREE',
  LOAD_BATCH_ITEMS: 'LOAD_BATCH_ITEMS',
  LOAD_DOCUMENTS: 'LOAD_DOCUMENTS',
  PREVIEW_DOCUMENT: 'PREVIEW_DOCUMENT',
  LOAD_ADDRESS: 'address/LOAD_ADDRESS',
  ADDRESS_FAILURE: 'address/ADDRESS_FAILURE',
  ADDRESS_SUCCESS: 'address/ADDRESS_SUCCESS',

  BATCH_STATUS_UPDATE_INIT: 'batch/BATCH_STATUS_UPDATE_INIT',
  BATCH_STATUS_UPDATE_SUCCESS: 'batch/BATCH_STATUS_UPDATE_SUCCESS',
  BATCH_STATUS_UPDATE_FAILURE: 'batch/BATCH_STATUS_UPDATE_FAILURE',
  BATCH_STATUS_UPDATE_RESET: 'batch/BATCH_STATUS_UPDATE_RESET',

  LOAD_BATCH_STATUS_INIT: 'LOAD_BATCH_STATUS_INIT',
  LOAD_BATCH_STATUS_SUCCESS: 'LOAD_BATCH_STATUS_SUCCESS',
  LOAD_BATCH_STATUS_FAILED: 'LOAD_BATCH_STATUS_FAILED'

}

export const fetchEntities = (entityType, obj) => ({
  entityType,
  type: actions.FETCH_SUCCESS,
  data: obj
})

export const initiateBatchUpdateStatus = (obj) => ({
  type: actions.BATCH_STATUS_UPDATE_INIT,
  data: obj
})

export const resetBatchUpdateStatus = () => ({
  type: actions.BATCH_STATUS_UPDATE_RESET
})

export const doLoadOrderItems = (orderId, page) => ({
  type: actions.LOAD_ORDER_ITEMS,
  orderId,
  page
})

export const doLoadDrops = (loadId, page) => ({
  type: actions.LOAD_DROPS,
  loadId,
  page
})

export const doLoadShipmentItems = ({ shipmentId, orderItemId }, page) => ({
  type: actions.LOAD_SHIPMENT_ITEMS,
  shipmentId,
  orderItemId,
  page
})

export const doLoadItems = (objectId, page) => ({
  type: actions.LOAD_ITEMS,
  objectId,
  page
})

export const doLoadTree = (hierarchyRootNode, page) => ({
  type: actions.LOAD_TREE,
  hierarchyRootNode,
  page
})

export const doLoadDocuments = (parentId, page) => ({
  type: actions.LOAD_DOCUMENTS,
  parentId,
  page
})

export const doLoadShipments = (orderId, page) => ({
  type: actions.LOAD_SHIPMENTS,
  orderId,
  page
})

export const doPreviewDocument = (obj) => ({
  type: actions.PREVIEW_DOCUMENT,
  data: obj
})

export const doLoadBatchItems = (batchId, page) => ({
  type: actions.LOAD_BATCH_ITEMS,
  batchId,
  page
})

// this is not required anymore but keeping for now
export const doLoadAddress = (locationId, entity, page) => ({
  type: actions.LOAD_ADDRESS,
  locationId,
  entity,
  page
})

export default actions
