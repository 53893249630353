import React from 'react'
import {
  Menu, Dropdown,
  Avatar
} from 'antd'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

const DropdownEx = styled(Dropdown)`
    align-items: center;
    display: flex;
`
const UserInfoContainer = styled.div`
  cursor: pointer;
`

const UserInfo = styled.div`
  line-height: 16px;
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 10px;
  max-width: 95px;
  transition: max-width 0.3s;
  
  &:hover {
    max-width: 200px;
  }
`

const UserName = styled.div`
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: #212121;
`
const Role = styled.div`
  font-size: 11px;
  color: #757575;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const menu = (
  <Menu>
    <Menu.Item>
      <a href='/profile'><FormattedMessage id='profile' defaultMessage='My Profile' /></a>
    </Menu.Item>
    <Menu.Item>
      <a href='/logout'><FormattedMessage id='logOut' defaultMessage='Sign Out' /></a>
    </Menu.Item>
  </Menu>

)

const UserDropdownMenu = ({ user }) => (
  <DropdownEx overlay={menu} trigger={['click']}>
    <UserInfoContainer>
      <UserInfo>
        <UserName>{user.name}</UserName>
        {user.roles && <Role title={user.roles[0]}>{user.roles[0]}</Role>}
      </UserInfo>
      <Avatar icon='user' />
    </UserInfoContainer>
  </DropdownEx>
)

export default UserDropdownMenu
