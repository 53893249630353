import React from 'react'
import styled from 'styled-components'
import { Button, Table } from 'antd'
import { downloadDocument, onDocumentsPageSizeChange, onDocumentsPageChange } from '../../../../redux/modules/documentImport/actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TableUtils } from '../../../components'
import TablePagination from '../../../components/TableUtils/tablePagination'
import isEmpty from 'lodash/isEmpty'

const { renderDateTime, dateSorter } = TableUtils

const ImportButton = styled(Button)`
    background-color: #3570FF;
    border: 0;
    font-size: 13px;
    font-weight: 400;
    width: 150px;
`
const ImportBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 3px rgba(0,0,0,0.22);
    border: 1px solid #D9DADB;
    border-radius: 5px;
    padding: 20px 20px;
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
`

const TableHeading = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 15px;
`

const DownloadBtn = styled.button`
  background-color: transparent;
  color: #3570FF;
  border: 0;
  outline: 0;
  cursor: pointer;
`

function DataWizard (props) {
  const { handleImportNew, tableData, paging } = props

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'file',
      key: 'file'
    },
    {
      title: 'File Type',
      dataIndex: 'documentType',
      key: `documentType`
    },
    {
      title: 'Entity Type',
      dataIndex: 'messageCategory',
      key: `messageCategory`
    },
    {
      title: 'Uploaded Date',
      dataIndex: 'createdAt',
      key: `createdAt`,
      render: renderDateTime,
      sorter: dateSorter('createdAt')
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'id',
      render: (id, record) => <DownloadBtn value={id} onClick={() => props.downloadDocument({ documentId: record.id, fileName: record.file })}>Download</DownloadBtn>
    }
  ]

  function handlePageChange (page) {
    props.onDocumentsPageChange(page)
  }

  function handlePageSizeChange (current, size) {
    props.onDocumentsPageSizeChange(size)
    props.onDocumentsPageChange(current)
  }

  return (
        <>
          <ImportBox>
            <div>Click the import button to add new file</div>
            <ImportButton
              onClick={handleImportNew}
              type='primary'>
                        Import New
            </ImportButton>
          </ImportBox>
          <TableHeading>IMPORTED DATA</TableHeading>
          <Table
            rowKey='id'
            dataSource={tableData}
            columns={columns}
            pagination={false}
          />
          {
            !isEmpty(paging) &&
            <TablePagination
              paging={paging}
              handlePageSizeChange={handlePageSizeChange}
              handlePageChange={handlePageChange}
            />
          }
        </>
  )
}

const mapStateToProps = ({ documentImport: { data, paging } }) => {
  return {
    data,
    paging
  }
}

const mapDispatchToProps = {
  downloadDocument,
  onDocumentsPageSizeChange,
  onDocumentsPageChange
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataWizard))
