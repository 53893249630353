import { call, put, takeLatest } from 'redux-saga/effects'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { BASE_URLS } from '../../../environment/api'
import { apiRequest, documentApi, downloadDocumentApi } from '../../../utility/fetch'
import actions, { BUSINESS_DOC_TYPE } from './actions'
import store from '../../../../src'

function * createDocument (action) {
  const { file, data } = action.data
  const formData = new FormData()
  formData.append('file', file)
  formData.append('data', data)
  try {
    const response = yield call(documentApi, `${BASE_URLS.API_PLATFORM_DOCUMENTS_URL}/documents`, {
      method: 'POST',
      body: formData
    })
    if (response.success) {
      yield put({ type: actions.UPLOAD_SUCCESS, payload: response.data })
    } else {
      yield put({ type: actions.UPLOAD_FAILURE, payload: response.message })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: actions.UPLOAD_FAILURE, payload: err })
  }
}

function * fetchDocumentApi (action) {
  const { pageSize: limit, pageNumber } = store.getState().documentImport
  const documentType = BUSINESS_DOC_TYPE
  const page = action.type === actions.DOCUMENTS_PAGE_CHANGE ? action.data : pageNumber

  try {
    yield put({ type: actions.FETCH_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_PLATFORM_DOCUMENTS_URL}/documents/retrieve`, {
      method: 'POST',
      queryParams: pickBy({
        page,
        limit
      }, identity),
      body: JSON.stringify({
        documentType
      })
    })
    if (response.success) {
      yield put({ type: actions.FETCH_SUCCESS, payload: response.data, paging: response.paging })
    } else {
      yield put({ type: actions.FETCH_FAILURE, payload: response.message })
    }
  } catch (err) {
    console.log('error while documents fetch', err)
    yield put({ type: actions.FETCH_FAILURE, payload: err })
  }
}

function * documentsDownload (action) {
  const { documentId, fileName } = action.data
  try {
    yield call(downloadDocumentApi, `${BASE_URLS.API_PLATFORM_DOCUMENTS_URL}/documents/${documentId}/download`, {
      method: 'GET'
    }, fileName)
  } catch (err) {
    console.log('error while documents fetch', err)
    yield put({ type: actions.DOWNLOAD_FAILURE, payload: err })
  }
}

export default function * actionWatcher () {
  yield takeLatest(actions.UPLOAD_INIT, createDocument)
  yield takeLatest([actions.FETCH_DOCUMENTS, actions.DOCUMENTS_PAGE_SIZE_CHANGE, actions.DOCUMENTS_PAGE_CHANGE], fetchDocumentApi)
  yield takeLatest(actions.DOWNLOAD_SINGLE, documentsDownload)
}
