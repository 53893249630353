import React, { useState } from 'react'
import { Layout, Descriptions } from 'antd'
import styled from 'styled-components'
import ChangePassword from '../auth/ChangePassword'
const UserProfileContainer = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  width: 50%;
  margin:auto;
  
  .ant-descriptions-item-label {
    min-width: 60px;
  }
`
const ChangePasswordLink = styled.a`
  float: right;
`

const UserProfileDetails = ({ user }) => {
  const [visible, toggleModelVisible] = useState(false)
  return (
    <Layout>
      <UserProfileContainer>
        <Descriptions title='My Profile'>
          <Descriptions.Item label='Name'>{user.name}</Descriptions.Item>
        </Descriptions>
        <Descriptions>
          <Descriptions.Item label='Email'>{user.email}</Descriptions.Item>
        </Descriptions>
        <Descriptions>
          <Descriptions.Item label='Roles'>{user.roles[0]}</Descriptions.Item>
        </Descriptions>
        <ChangePasswordLink onClick={() => toggleModelVisible(!visible)} >
          Change password
        </ChangePasswordLink>
        <ChangePassword isVisible={visible} onCancel={() => toggleModelVisible(!visible)} />
      </UserProfileContainer>
    </Layout>
  )
}

export default UserProfileDetails
