export const getCompanyPostalAddress = (company) => {
  return (company &&
      company.locations &&
      company.locations[0] &&
      company.locations[0].address &&
      company.locations[0].address.postalAddress
  )
    ? company.locations[0].address.postalAddress
    : {}
}

export const getCompanyCoordinates = (company) => {
  return (company &&
      company.locations &&
      company.locations[0] &&
      company.locations[0].address &&
      company.locations[0].address.geoLocation
  )
    ? convertLonToLng(company.locations[0].address.geoLocation)
    : {}
}

export function convertLonToLng (geo) {
  const { lat, lon: lng } = geo || {}
  return { lat, lng }
}
