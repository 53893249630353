import { call, put, takeLatest } from 'redux-saga/effects'
import alertsActions from './actions'
import { apiRequest } from '../../../utility/fetch'
import { BASE_URLS } from '../../../environment/api'
import store from '../../../../src'
import validate from 'uuid-validate'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

function * fetchAlerts (action) {
  const URL = `${BASE_URLS.API_TRUSTLINK_ALERTS_URL}/alert/notifications`
  const { paging: { page, limit } = {} } = action
  const options = {
    method: 'GET',
    queryParams: pickBy({
      page,
      limit
    }, identity)
  }
  try {
    const response = yield call(apiRequest, URL, options)
    if (response.success) {
      yield put({ type: alertsActions.FETCH_ALERTS_SUCCESS, payload: response.data, paging: response.paging })
    } else {
      yield put({ type: alertsActions.FETCH_ALERTS_FAILED, payload: response.message })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: alertsActions.FETCH_ALERTS_FAILED, payload: err })
  }
}

function * fetchEntityAlerts (action) {
  const URL = `${BASE_URLS.API_TRUSTLINK_ALERTS_URL}/alert/notifications`
  const context = validate(store.getState().dashboard.criteria) ? 'context.itemId' : 'context.businessObjectId'

  const itemId = action.data
  const options = {
    method: 'GET',
    queryParams: {
      [context]: itemId
    }
  }
  try {
    const response = yield call(apiRequest, URL, options)
    if (response.success) {
      yield put({ type: alertsActions.FETCH_ENTITY_ALERTS_SUCCESS, payload: response.data })
    } else {
      yield put({ type: alertsActions.FETCH_ENTITY_ALERTS_FAILED, payload: response.message })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: alertsActions.FETCH_ENTITY_ALERTS_FAILED, payload: err })
  }
}

export default function * actionWatcher () {
  yield takeLatest(alertsActions.FETCH_ALERTS_INIT, fetchAlerts)
  yield takeLatest(alertsActions.FETCH_ENTITY_ALERTS_INIT, fetchEntityAlerts)
}
