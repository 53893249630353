import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import _ from 'lodash'
import { PackageIcon } from '@styled-icons/boxicons-regular'
import { withRouter } from 'react-router-dom'
import { doLoadDocuments, doLoadTree } from '../../../redux/modules/entities/actions'
import ItemSideLink from '../caseDetail/SideLink'
import ItemStatusInformation from '../caseDetail/StatusInformation.js'
import { entityTitle } from '../../../configs/objects'
import ItemTreeDiagram from './ItemTreeDiagram'
import { Container, EntityDetailView } from '../../components'
import { Row, Col, Icon } from 'antd'
import { getEntityAlerts } from '../../../redux/modules/alerts/actions'

const IconEx = styled(Icon)`
  margin-right: 3.5px;
  border: 1px solid #c5c5c5;
  padding: 3px;
  border-radius: 20px;
  color: #c5c5c5;
`
const RowEx = styled(Row)`
  max-height: 90vh;
  overflow: hidden;
`
const ColSideLink = styled(Col)`
  margin-right: -20px !important;
`
const ColDetails = styled(Col)`
  padding-left:0 !important;
`
const Title = styled.span`
  font-weight: normal;
  color: #212121;
  font-size: 15px;
`
const RelatedItemContainer = styled(Container)`
  background-color: #F3F4F6;
  padding: 0;
`

const ContainerEx = styled(Container)`
  padding: 0;
  min-height: 400px;
  flex-grow: 1;
`

const ItemIcon = styled(PackageIcon)`
  color: #2196f3;
  width: 45px;
  padding: 5px;
  background-color: #f7f7f7
`
const TreeDiagram = (item, treeHierarchy) => {
  return <ItemTreeDiagram item={item} treeHierarchy={treeHierarchy} />
}
class ItemDetailPage extends React.Component {
  componentDidMount () {
    const { loadTree, loadDocuments, match, items, getEntityAlerts } = this.props
    const { itemNumber } = match.params
    const item = _.find(items, function (data) {
      const primaryKey = Object.keys(data.identification.instance)[0]
      return data.identification.instance[primaryKey] === itemNumber
    })
    loadTree(item.id)
    loadDocuments(item.id)
    getEntityAlerts(item.id)
  }

  selectitem = (id) => {
    const { loadDocuments, history, items, loadTree, getEntityAlerts } = this.props
    const item = _.find(items, { id }) || {}
    const primaryKey = Object.keys(item.identification.instance)[0]

    history.push(`${item.identification.instance[primaryKey]}`)
    loadTree(id)
    loadDocuments(id)
    getEntityAlerts(id)
  }

  render () {
    const { match, items, treeHierarchy, entityAlerts } = this.props
    const { itemNumber } = match.params

    const item = _.find(items, function (data) {
      const primaryKey = Object.keys(data.identification.instance)[0]
      return data.identification.instance[primaryKey] === itemNumber
    })
    const { currentCustody: { tenantName = '', address } } = item || {}
    const { postalAddress: { streetAddress, addressRegion, addressLocality, postalCode, addressCountry } = {} } = address || {}
    const tenantAddress = [{
      streetAddress,
      addressRegion,
      addressLocality,
      postalCode,
      addressCountry,
      name: tenantName,
      title: <Title><IconEx type='user' /> Current Custody</Title>
    }]
    const { id = '' } = item || {}

    return <RowEx>
      <ColSideLink span={5}>
        <RelatedItemContainer>
          <ItemSideLink items={items} itemId={id} selectitem={this.selectitem} />
        </RelatedItemContainer>
      </ColSideLink>
      <ColDetails span={19}>
        <ContainerEx>
          <EntityDetailView data={item} documentId={itemNumber} icon={ItemIcon}
            entity={entityTitle.ITEM} subtitle={{}} information={ItemStatusInformation}
            tree={() => TreeDiagram(item, treeHierarchy)} treeTitle='Item Hierarchy' address={tenantAddress} entityAlerts={entityAlerts} />
        </ContainerEx>
      </ColDetails>
    </RowEx>
  }
}

const mapStateToProps = ({ entities: { loading, items, treeHierarchy }, alerts: { entityAlerts } }) => {
  return {
    items,
    treeHierarchy,
    loading: loading,
    entityAlerts
  }
}

const mapDispatchToProps = {
  loadDocuments: doLoadDocuments,
  loadTree: doLoadTree,
  getEntityAlerts
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemDetailPage))
