const actions = {
  UPLOAD_INIT: 'document/UPLOAD_INIT',
  UPLOAD_SUCCESS: 'document/UPLOAD_SUCCESS',
  UPLOAD_FAILURE: 'document/UPLOAD_FAILURE',
  UPLOAD_RESET: 'document/UPLOAD_RESET',

  FETCH_SUCCESS: 'documents/FETCH_SUCCESS',
  FETCH_START: 'documents/FETCH_START',
  FETCH_FAILURE: 'documents/FETCH_FAILURE',
  DOWNLOAD_SUCCESS: 'document/DOWNLOAD_SUCCESS',
  DOWNLOAD_FAILURE: 'document/DOWNLOAD_FAILURE',

  FETCH_DOCUMENTS: 'documents/FETCH_DOCUMENTS',
  DOWNLOAD_SINGLE: 'DOWNLOAD_SINGLE',

  DOCUMENTS_PAGE_SIZE_CHANGE: 'options/DOCUENTS_PAGE_SIZE_CHANGE',
  DOCUMENTS_PAGE_CHANGE: 'options/DOCUMENTS_PAGE_CHANGE'
}

export const BUSINESS_DOC_TYPE = 'BusinessEntityDataImport'

export const onCreateDocument = (obj) => ({
  type: actions.UPLOAD_INIT,
  data: obj
})

export const onDocumentsPageSizeChange = (pageSize) => ({
  type: actions.DOCUMENTS_PAGE_SIZE_CHANGE,
  data: pageSize
})

export const onDocumentsPageChange = (page) => ({
  type: actions.DOCUMENTS_PAGE_CHANGE,
  data: page
})

export const resetDocUploadVar = () => ({
  type: actions.UPLOAD_RESET
})

export const documentsFetch = () => ({
  type: actions.FETCH_DOCUMENTS
})

export const downloadDocument = (obj) => ({
  type: actions.DOWNLOAD_SINGLE,
  data: obj
})

export const downloadScuccess = () => ({
  type: actions.DOWNLOAD_SUCCESS
})
export default actions
