import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CollectionsBookmark } from '@styled-icons/material-outlined'
import { Container, EntityDetailView } from '../../components'
import LoadSideLink from './LoadSideLink'
import { entityTitle } from '../../../configs/objects'
import LoadDetailTable from './LoadDetailTable'
import { doLoadDocuments } from '../../../redux/modules/entities/actions'
import LoadStatusInformation from './LoadStatusInformation'
import { Row, Col, Icon } from 'antd'
import { sortByKey } from '../../../utility'
import { getEntityAlerts } from '../../../redux/modules/alerts/actions'

const IconEx = styled(Icon)`
  margin-right: 3.5px;
  border: 1px solid #c5c5c5;
  padding: 3px;
  border-radius: 20px;
  color: #c5c5c5;
`
const RowEx = styled(Row)`
  max-height: 90vh;
  overflow: hidden;
`
const ColSideLink = styled(Col)`
  margin-right: -20px !important;
`
const ColDetails = styled(Col)`
  padding-left:0 !important;
`
const Title = styled.span`
  font-weight: normal;
  color: #212121;
  font-size: 15px;
`
const SideLinkContainer = styled(Container)`
  background-color: #F3F4F6;
  padding: 0;
`

const ContainerEx = styled(Container)`
  padding: 0;
  min-height: 400px;
  flex-grow: 1;
`
const LoadIcon = styled(CollectionsBookmark)`
  color: #2196f3;
  width: 45px;
  padding: 5px;
  background-color: #f7f7f7;
`
class LoadDetailPage extends React.Component {
  componentDidMount () {
    const { loads, match } = this.props
    const { loadNumber } = match.params
    console.log(loads)
    const load = _.find(loads, { number: loadNumber })
    getEntityAlerts(load.id)
  }
  selectLoad = (id) => {
    const { loadDocuments, loads, history, getEntityAlerts } = this.props
    const load = _.find(loads, { id })
    history.push(`${load.number}`)
    loadDocuments(load.id)
    getEntityAlerts(load.id)
  }

  render () {
    const { loads, documents, match, customers, entityAlerts } = this.props
    const { loadNumber } = match.params
    const load = _.find(loads, { number: loadNumber })
    const { drops } = load

    let address = []

    if (Array.isArray(drops) && drops.length) {
      const latestDrop = drops.length && (drops.sort(sortByKey('deliveryDate', 'desc'))[0] || drops[0])
      const { customerId } = latestDrop || ''
      const customer = customerId && _.find(customers, { id: customerId })
      const locations = customer && Array.isArray(customer.locations) && customer.locations.length
        ? customer.locations[0] : {}

      const { postalAddress } = locations.address || {}
      address = [{
        ...postalAddress,
        name: customer && customer.name,
        title: <Title><IconEx type='user' /> Latest Drop</Title>
      }]
    } else {
      address = [{
        title: <Title><IconEx type='user' /> Latest Drop</Title>
      }]
    }

    return <RowEx>
      <ColSideLink span={5}>
        <SideLinkContainer>
          <LoadSideLink loads={loads} loadId={load.id} selectLoad={this.selectLoad} />
        </SideLinkContainer>
      </ColSideLink>
      <ColDetails span={19}>
        <ContainerEx>
          <EntityDetailView data={load} documentId={loadNumber} icon={LoadIcon}
            entity={entityTitle.LOAD} subtitle={{}} documents={documents} information={LoadStatusInformation}
            table={LoadDetailTable} tableTitle='Drops' address={address} entityAlerts={entityAlerts} />
        </ContainerEx>
      </ColDetails>
    </RowEx>
  }
}

const mapStateToProps = ({ entities: { loading, loads, documents, drops }, searchBar: { companies: customers }, alerts: { entityAlerts } }) => {
  return {
    loads,
    documents,
    customers,
    drops,
    loading: loading,
    entityAlerts
  }
}

const mapDispatchToProps = {
  loadDocuments: doLoadDocuments,
  getEntityAlerts
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoadDetailPage))
