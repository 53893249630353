import objects from '../objects'

const ROLE = {
  searchCriteria: [
    {
      sequence: 0,
      ...objects.ORDERS
    },
    {
      sequence: 1,
      ...objects.SHIPMENTS
    },
    {
      sequence: 2,
      ...objects.LOADS
    },
    {
      sequence: 3,
      ...objects.BATCHES
    }
  ],
  dateRange: [
    {
      name: 'This week',
      value: '7'
    },
    {
      name: 'This month',
      value: '30'
    }
  ]
}
export default ROLE
