import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { Container, EntityDetailView } from '../../components'
import { doLoadItems, doLoadDocuments, doLoadTree } from '../../../redux/modules/entities/actions'
import CaseSideLink from './SideLink'
import { entityTitle } from '../../../configs/objects'
import CaseDetailTable from './CaseDetailTable'
import { Cabinet } from '@styled-icons/boxicons-regular'
import CaseStatusInformation from './StatusInformation'
import { Col, Row, Icon } from 'antd'
import { getEntityAlerts } from '../../../redux/modules/alerts/actions'
import ItemTreeDiagram from '../itemDetail/ItemTreeDiagram'

const IconEx = styled(Icon)`
  margin-right: 8.5px;
  border: 1px solid #c5c5c5;
  padding: 3px;
  border-radius: 20px;
  color: #c5c5c5;
`
const RowEx = styled(Row)`
  max-height: 90vh;
  overflow: hidden;
`
const Title = styled.span`
  font-weight: normal;
  color: #212121;
  font-size: 15px;
`
const ColSideLink = styled(Col)`
  margin-right: -20px !important;
`
const ColDetails = styled(Col)`
  padding-left:0 !important;
`
const RelatedCaseContainer = styled(Container)`
  background-color: #F3F4F6;
  padding: 0;
`

const ContainerEx = styled(Container)`
  padding: 0;
  min-height: 400px;
  flex-grow: 1;
`

const CaseIcon = styled(Cabinet)`
  color: #2196f3;
  width: 45px;
  padding: 5px;
  background-color: #f7f7f7;
`

const CaseTable = () => <CaseDetailTable />

const TreeDiagram = (item, treeHierarchy) => {
  return <ItemTreeDiagram item={item} treeHierarchy={treeHierarchy} />
}

class CaseDetailPage extends React.Component {
  componentDidMount () {
    const { loadItems, match, items, loadDocuments, loadTree, getEntityAlerts } = this.props
    const { caseNumber } = match.params
    const item = _.find(items, function (data) {
      const primaryKey = Object.keys(data.identification.instance)[0]
      return data.identification.instance[primaryKey] === caseNumber
    })

    loadItems(item.id)
    loadDocuments(item.id)
    loadTree(item.id)
    getEntityAlerts(item.id)
  }

  selectcase = (id) => {
    const { loadItems, loadDocuments, loadTree, history, items, getEntityAlerts } = this.props
    const item = _.find(items, { id }) || {}
    const primaryKey = Object.keys(item.identification.instance)[0]

    history.push(`${item.identification.instance[primaryKey]}`)
    loadItems(id)
    loadDocuments(id)
    loadTree(id)
    getEntityAlerts(id)
  }

  render () {
    const { match, items, treeHierarchy, entityAlerts } = this.props
    const { caseNumber } = match.params
    const item = _.find(items, function (data) {
      const primaryKey = Object.keys(data.identification.instance)[0]
      return data.identification.instance[primaryKey] === caseNumber
    })
    const { currentCustody: { tenantName = '', address } } = item || {}
    const { postalAddress: { streetAddress, addressRegion, addressLocality, postalCode, addressCountry } = {} } = address || {}
    const tenantAddress = [{
      streetAddress,
      addressRegion,
      addressLocality,
      postalCode,
      addressCountry,
      name: tenantName,
      title: <Title><IconEx type='user' />Current Custody</Title>
    }]
    const { id = '' } = item || {}

    return (
      <RowEx>
        <ColSideLink span={5}>
          <RelatedCaseContainer>
            <CaseSideLink items={items} itemId={id} selectitem={this.selectcase} />
          </RelatedCaseContainer>
        </ColSideLink>
        <ColDetails span={19}>
          <ContainerEx>
            <EntityDetailView data={item} documentId={caseNumber} icon={CaseIcon} showTemperature
              entity={entityTitle.CASE} subtitle={{}} information={CaseStatusInformation}
              table={() => CaseTable(item, treeHierarchy)} tableTitle='Items' address={tenantAddress} entityAlerts={entityAlerts}
              tree={() => TreeDiagram(item, treeHierarchy)} treeTitle='Item Hierarchy' />
          </ContainerEx>
        </ColDetails>
      </RowEx>)
  }
}

const mapStateToProps = ({ entities: { loading, items, address, treeHierarchy }, alerts: { entityAlerts } }) => {
  return {
    items,
    address,
    treeHierarchy,
    loading: loading,
    entityAlerts
  }
}

const mapDispatchToProps = {
  loadItems: doLoadItems,
  loadDocuments: doLoadDocuments,
  loadTree: doLoadTree,
  getEntityAlerts
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CaseDetailPage))
