import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
// defaults to localStorage for web and AsyncStorage for react-native
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { reducers as rootReducer } from '../modules'
import { isProduction } from '../../utility'

/**
 * Returns a redux store when running in development mode
 */
const developmentStore = (reducer, initialState, middleware) => {
  const enhancers = composeWithDevTools(
    applyMiddleware(middleware)
  )
  return createStore(reducer, initialState, enhancers)
}

/**
 * Returns a redux store when running in production mode
 */
const productionStore = (reducer, initialState, middleware) => {
  return createStore(reducer, initialState, applyMiddleware(middleware))
}

/*
* Persistance configuration
*/
const persistanceConfiguration = {
  key: 'web-bench-root',
  storage,
  stateReconciler: autoMergeLevel2,
  // Non-persistent state should go here so we can ensure
  // initial state trumps old state easier
  // app will have all the app related/ temporary errors which needs to be cleared at each re-load
  blacklist: ['app']
}

const persistedReducer = persistReducer(persistanceConfiguration, rootReducer)

/**
 * Returns a map containing a redux store and persistor based on `initialState` and `options`
 */
const configureStore = ({ middleware, initialState, options }) => {
  const store = isProduction
    ? productionStore(persistedReducer, initialState, middleware)
    : developmentStore(persistedReducer, initialState, middleware)
  return { store, persistor: persistStore(store) }
}

export { configureStore }
