import React, { Component } from 'react'
import styled from 'styled-components'
import { Select, Result, Button, DatePicker, Row, Typography, Tooltip } from 'antd'
import { apiRequest } from '../../../../utility/fetch'
import { BASE_URLS } from '../../../../environment/api'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import store from '../../../../../src'
import { jsonToCSV } from 'react-papaparse'
import { DATE_FORMAT, RANGE_SELECTOR } from '../../../../utility'
const { RangePicker } = DatePicker
const { Option } = Select
const { Text } = Typography

const FILTER_NULLS = (key, value) => {
  if (!isEmpty(value)) return value
}

const FilterBox = styled.div`
    border-right: ${props => props.resetBtn ? '0' : '1px solid #D9DADB'};
    padding-right: ${props => props.noRightPadding ? '0px' : '20px'};
    padding-left: ${props => props.noLeftPadding ? '0px' : '20px'};
`
const FilterContainer = styled(Row)`
    margin: 20px 0;
    padding: 0 0 20px;
    display: flex;
`
const LeftChild = styled.div`
    flex: 1;
    display: flex;
`

const LabelEx = styled(Text)`
  padding: 0 10px 0 0;
  font-size: 15px;
  color: #212121;
`

const StyledSelect = styled(Select)`
  color: #333;
  .ant-select-selection {
  border-radius: 0px !important;
  width: 140px;
  }
  .ant-select-arrow{
  color: #333
}
`

const GenerateCSVButton = styled(Button)`
    background-color: #3570FF;
    border: 0;
    font-size: 13px;
    font-weight: 400;
    width: 150px;
`

const EVENTS = [
  { id: 'net.smartcosmos.trustlink.de.container', name: 'Container' },
  { id: 'net.smartcosmos.trustlink.cte.receive', name: 'Receive' }
]

class ExportData extends Component {
  constructor (props) {
    super(props)
    const { dashboard: { searchCriteria: templates = [] } } = store.getState()
    this.state = {
      eventType: 'net.smartcosmos.trustlink.de.container',
      range: [moment(), moment()],
      template: templates[0].value,
      fetching: false
    }
  }

    formatDate = (dateString = 'NA') => {
      const date = moment(dateString, 'YYMMDD')
      if (date.isValid()) {
        return date.format('MM/DD/YY')
      }
      return dateString
    }

    setDisplayMsg = (status, title, subTitle) => {
      this.setState({
        fetching: false,
        result: {
          status,
          title,
          subTitle
        }
      })
    }

    handleGenerateCSV = async () => {
      this.setState({
        fetching: true,
        success: false
      })
      const { eventType, range, template: templateId } = this.state
      const fetchUrl = `${BASE_URLS.API_TRUSTLINK_EVENTS_URL}/events`
      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify({
          type: eventType,
          templateId: templateId,
          time: {
            from: range[0].format(),
            until: range[1].format()
          }
        }, FILTER_NULLS)
      }
      apiRequest(fetchUrl, fetchOptions)
        .then(
          res => {
            if (res.success) {
              if (isEmpty(res.data)) {
                this.setDisplayMsg('warning', 'No events found!', 'Try another filter combination')
              } else {
                this.formatData(res.data)
              }
            } else {
              this.setDisplayMsg('error', 'Unable to generate file', res.message)
            }
          },
          err => {
            this.setDisplayMsg('error', 'Unable to generate file', JSON.stringify(err))
          }
        )
    }

    formatData = (eventsData) => {
      const record = []
      for (let eventData of eventsData) {
        const jsonTemplate = {}
        jsonTemplate['Date'] = moment(get(eventData, 'time')).format('MM/DD/YYYY')
        jsonTemplate['SSCC'] = get(eventData, 'data.identification.instance.serialNumber')
        jsonTemplate['GLN (ship from)'] = get(eventData, 'location.address.locationNumber')
        jsonTemplate['GLN Extension (ship from)'] = ''
        jsonTemplate['Destination GLN'] = get(eventData, 'data.destination.locations[0].address.locationNumber')
        jsonTemplate['Destination GLN Extension'] = ''
        const children = get(eventData, 'data.children', [])
        for (let child of children) {
          jsonTemplate['GTIN'] = get(child, 'identification.class.GTIN')
          jsonTemplate['Product Lot'] = get(child, 'identification.batchOrLot.Lot')
          jsonTemplate['Product Serial Number'] = get(child, 'identification.instance.epc')
          jsonTemplate['Product Quantity Units'] = 'Cases'
          jsonTemplate['Product Quantity Amount'] = 1
          jsonTemplate['Expiration Date'] = this.formatDate(get(child, 'metadata.expiryDate'))
          record.push({ ...jsonTemplate })
        }
      }
      this.generateCSVLink(record)
    }

    generateFileName = () => {
      const { range, eventType } = this.state
      const eventOp = EVENTS.find(option => option.id === eventType) || { name: 'event' }
      return `smcfl_${eventOp.name}_${range[0].format('MMMM-DD-YYYY')}_${range[1].format('MMMM-DD-YYYY')}.csv`.toLowerCase()
    }

    generateCSVLink = (jsonData) => {
      const csvString = jsonToCSV(jsonData)
      var fileName = this.generateFileName()
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName) // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = fileName
        document.body.appendChild(a)
        a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a)
      }
      this.setDisplayMsg('success', 'Download started!', `Check downloads, locate ${fileName}.`)
    }

    handleSelectionChange = (selected) => {
      this.setState({
        eventType: selected
      })
    }

    handleOnDateChange = (dates) => {
      this.setState({
        range: [dates[0],
          dates[1]]
      })
    }

    handleTemplateChange = (template) => {
      this.setState({
        template
      })
    }

    buildEventsOption = () => {
      return EVENTS.map((event, index) => {
        return <Option key={index} value={event.id}><Tooltip title={event.name}>{event.name}</Tooltip></Option>
      })
    }

    buildTemplateOption = () => {
      const { dashboard: { searchCriteria: templates = [] } } = store.getState()
      return templates.map((template, index) => {
        return <Option key={index} value={template.value}><Tooltip title={template.name}>{template.name}</Tooltip></Option>
      })
    }

    render () {
      const { status, title, subTitle } = this.state.result || {}
      return (
        <>
          <FilterContainer>
            <LeftChild>
              <FilterBox>
                <LabelEx>Select event type </LabelEx>
                <StyledSelect
                  value={this.state.eventType}
                  onSelect={this.handleSelectionChange}
                >
                  {this.buildEventsOption()}
                </StyledSelect>
              </FilterBox>
              <FilterBox>
                <LabelEx>Select template </LabelEx>
                <StyledSelect
                  value={this.state.template}
                  onSelect={this.handleTemplateChange}
                >
                  {this.buildTemplateOption()}
                </StyledSelect>
              </FilterBox>
              <FilterBox>
                <LabelEx>Date Range </LabelEx>
                <RangePicker
                  ranges={RANGE_SELECTOR}
                  format={DATE_FORMAT}
                  value={this.state.range}
                  onChange={this.handleOnDateChange}
                />
              </FilterBox>

              <GenerateCSVButton
                onClick={this.handleGenerateCSV}
                type='primary'
                loading={this.state.fetching}
              >
            Generate
              </GenerateCSVButton>
            </LeftChild>
          </FilterContainer>
          { status && <Result
            status={status}
            title={title}
            subTitle={subTitle}
          />
          }
        </>
      )
    }
}

export default ExportData
