import React from 'react'
import * as G2Plot from '@antv/g2plot'
// import _ from 'lodash'

// function std (array) {
//   const avg = _.sum(array) / array.length
//   return Math.sqrt(_.sum(_.map(array, (i) => Math.pow((i - avg), 2))) / array.length)
// }

const CHART_CONFIG = {
  'title': {
    'visible': true,
    'text': 'Temperature'
  },
  'description': {
    'text': 'Temperature Graph'
  },
  'legend': {
    'flipPage': false
  },
  'xAxis': {
    'autoRotateLabel': true,
    'title': {
      'text': 'Temperature'
    }
  },
  'yAxis': {
    'title': {
      'visible': true,
      'text': 'Temperature (°F)'
    },
    min: 20,
    max: 60
  },
  'forceFit': false,
  'width': 560,
  'height': 376,
  'xField': 'date',
  'yField': 'y',
  'color': [
    '#5B8FF9'
  ]
}

const drawGraph = (data) => {
  // const all_temperatures = _.map(data, d => d.y)
  // const std_temperature = std(all_temperatures)
  // const mean_temperature = _.mean(all_temperatures)
  //
  // const real_data = _.map(data, d => ({ type: 'actual', ...d }))
  // const upper_std = _.map(data, d => ({ type: 'upper_std', date: d.date, y: (mean_temperature + std_temperature) }))
  // const below_std = _.map(data, d => ({ type: 'below_std', date: d.date, y: (mean_temperature - std_temperature) }))
  //
  // const new_data = real_data.concat(upper_std, below_std)

  // const min = _.min(all_temperatures)
  // const max = _.max(all_temperatures)
  const container = document.getElementById('temperature-graph')
  const plot = new G2Plot.Line(container, {
    data,
    ...CHART_CONFIG
  })
  plot.render()
}

class TemperatureViewer extends React.Component {
  componentDidMount (): void {
    const data = [
      {
        'date': '2018-12',
        'y': 30
      },
      {
        'date': '2019-01',
        'y': 45
      },
      {
        'date': '2019-03',
        'y': 33
      },
      {
        'date': '2019-04',
        'y': 40
      },
      {
        'date': '2019-05',
        'y': 37
      },
      {
        'date': '2019-06',
        'y': 30
      }
    ]
    drawGraph(data)
  }

  render () {
    return <>
      <div id='temperature-graph' />
    </>
  }
}

export default TemperatureViewer
