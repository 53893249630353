// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { doLogout, isAuthenticated } from '../../../redux/modules/user/actions'

type Props = {
  isAuthenticated: Boolean,
  onLogout: Function
}

class Logout extends Component<Props> {
  handleLogout = async ({ onLogout }) => {
    onLogout()
  };

  componentDidMount () {
    this.handleLogout(this.props)
  }

  render () {
    const { isAuthenticated } = this.props
    if (!isAuthenticated) {
      return <Redirect to='/login' />
    }
    return (
      <div />
    )
  }
}
const mapStateToProps = () => {
  return {
    isAuthenticated: isAuthenticated()
  }
}
const mapDispatchToProps = {
  onLogout: doLogout
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout))
