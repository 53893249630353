import store from '../../src'
import isEmpty from 'lodash/isEmpty'

export const getWorkbenchTenantConfig = () => {
  const { user: { tenant: { config: { config = {} } = {} } = {} } } = store.getState()

  // new schema for config has 'core' as distinguishing property
  const isNewSchema = config.hasOwnProperty('core')
  if (isNewSchema) {
    const { trustlink: { workbench } } = config
    return workbench
  } else {
    return {}
  }
}

export const buildConfigBasedOptions = (rawOptions) => {
  const { businessDataManagement } = getWorkbenchTenantConfig()

  const configBasedEntities = rawOptions.reduce(function (filtered, option) {
    if (businessDataManagement && businessDataManagement[option.value]) {
      const val = { name: option.name, value: option.value }
      filtered.push(val)
    }
    return filtered
  }, [])
  return isEmpty(configBasedEntities) ? rawOptions : configBasedEntities
}
