import React from 'react'
import styled from 'styled-components'

const TableStyled = styled.div`
  .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
      margin-right: 20px;
  }
  .ant-table-header {
    background-color: #fafafa !important;
  }
  .ant-table-row  {
    cursor: pointer;
  }
  .ant-table-thead {
    background-color: #FAFAFA;
    cursor: move;
  }
  .ant-table-body-inner {
    overflow-y: hidden !important;
    background-color: white;
    td{
      border:none;
    }
    .ant-tabl-row-hover {
        background-color: white !important;
      tr{
        :hover{
          background-color: white !important;
        }
      }
    }
  }
  .ant-table-placeholder{
    // margin-top: -25vh;
    padding: 0 !important;
    border-top: none;
  }
  .ant-table-column-title , .ant-table-row-cell-ellipsis {
    font-size: 13px;
    color: #212121;
    font-weight: normal;
  }
  .ant-table-row-cell-ellipsis{
    color: #757575;
  }
    `

// This is the table container that we should use everywhere
// As of now, it is empty, however, if we start to have some styling customization on Tables
// then we should use this to wrap original TableUtils
export default (props) => {
  return (
    <TableStyled>{props.children}</TableStyled>
  )
}
