// @flow

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { changeLocale } from '../../../redux/modules/locale/actions'

class Analytics extends Component {
  handleSelectChange = value => {
    this.props.onChangeLocale(value)
  }

  render () {
    const { user: { tenant: { reportLink } } } = this.props
    const powerBiLink = reportLink || 'https://app.powerbi.com/reportEmbed?reportId=3b7ebd80-6269-4c29-8335-7abda000a0ff&groupId=512e47ed-f5d1-4d81-8f74-149329fe572b&autoAuth=true&ctid=5da0212f-90cb-4cd0-8207-0115be4dacf1&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWNlbnRyYWwtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9'
    return <div><iframe title='power-bi' width='1800' height='1000' src={powerBiLink} frameborder='0' allowFullScreen='true' /></div>
  }
}

const mapStateToProps = ({ user, locale: { locale, languages } }) => {
  return {
    user,
    locale,
    languages
  }
}

const mapDispatchToProps = {
  onChangeLocale: changeLocale
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Analytics))
