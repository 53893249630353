import React from 'react'
import styled from 'styled-components'
import { Button, Icon, Divider, Popover, Row, Col, Tabs } from 'antd'
import { Thermometer } from '@styled-icons/boxicons-solid'
import DocumentsPreview from './popovers/DocumentPreview'
import TemperatureViewer from './popovers/TemperatureViewer'
import Address from '../Address'
import CoaUpload from './CoaUpload'
import InitiateRecall from './popovers/InitiateRecall'
import Alert from './Alert'
import EventTimeline from './EventTimeline'
import { FormattedMessage } from 'react-intl'

const TabsContainer = styled.div`
`

const RowContainer = styled(Row)`
  margin-top: 6px;
  background-color: white;
  height: 84vh;
  @media(max-height:768px){
    height: 77vh;
  }
`
const TableWrapper = styled.div`
.ant-table-placeholder{
  margin-top: -44vh;
  @media (max-height: 768px) {
    margin-top: -24vh;
  }
}
.ant-table-body {
  height: 44vh;
  @media (max-height: 768px) {
    height: 23vh;
  }
  &::webkit-scrollbar-track {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
}
`
const RowEx = styled(Row)`
  overflow: auto;
`
const ColEx = styled(Col)`
  display: flex;
  justify-content: flex-end;
  margin: auto;
`
const { TabPane } = Tabs

const TabsEx = styled(Tabs)`
  .ant-tabs-tab-active{
    color: #2196F3 !important;
    font-size: 15px;
    font-weight: normal;
  }
`

const Label = styled.span``

const TabPaneEx = styled(TabPane)``

const Container = styled.div`
  box-shadow: 0 0 3px #ddd;
`
const StatusTimelineCon = styled(Col)`
  background-color: #F3F4F6;
  padding-left: 0px !important;
`
const DetailsTable = styled(Row)``

const InfoHeaderContainer = styled(Row)`
  display:flex;
  align-items: flex-end;
  background-color: white;
  padding: 14px 20px;
  border-bottom: 1px solid #ddd;
`
const InfoContainer = styled(Col)`
  padding: 20px;
  background-color: white;
`
const TextContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  justify-content: flex-start;
  flex: 1;
  align-self: center;
`

const TitleContainer = styled(Col)`
  font-size: 15px;
  color: #212121;
  text-transform: uppercase;
  display: flex;
  align-self: flex-start;
`
const SubtitleContainer = styled.div`
  font-size: 13px;
  font-weight: normal;
  color: #000;
`

const IconEx = styled(Icon)`
  font-size: 21px;
  color: #888;
  margin-left: 16px;
  cursor:pointer;
`

const AddressContainer = styled(Col)`
  display: flex;
  flex: 1 auto;
  flex-flow: wrap;
`
const DocContainer = styled(Col)`
  padding: 0 12px;
`

const DividerEx = styled(Divider)`
  margin: 8px 0;
`
const IconContainer = styled(Col)``

const AlertContainer = styled.div``

const AddressEx = styled.div`
  flex: 0 0 50%;
  margin-bottom 0px;
`

const ThermometerEx = styled(Thermometer)`
  width: 20px;
  margin-left: -5px;
  margin-right: 5px;
  margin-top: -2px;
`
const BoldText = styled.span`
  font-weight: 500;
`
const InfoHeader = (props) => {
  const { documentId, title, subtitle, icon: Icon } = props
  return <InfoHeaderContainer>
    <IconContainer span={1}>{Icon && <Icon />}</IconContainer>
    <TextContainer>
      <TitleContainer span={18}> {title} <BoldText>-{documentId}</BoldText></TitleContainer>
      {subtitle.key && <SubtitleContainer>{subtitle.key || ''} <BoldText>-{subtitle.value || ''}</BoldText></SubtitleContainer>}
    </TextContainer>
    <ColEx span={5}>
      <IconEx type='flag' />
      <IconEx type='printer' />
    </ColEx>
  </InfoHeaderContainer>
}

const AddressWrap = ({ address }) => {
  return address ? address.map((value, index) => {
    return <AddressEx key={index}>
      <Address address={value} />
    </AddressEx>
  }) : 'N/A'
}

const DocumentsPreviewContent = (documents) => { return <DocumentsPreview documents={documents} /> }
const TemperatureViewerContent = <TemperatureViewer />

class EntityManagement extends React.Component {
  state = {
    showDocumentsPreview: false,
    showTemperature: false
  }

  handledocumentsPreviewClick = visible => {
    const { documents } = this.props
    if (documents && documents.length > 0) {
      this.setState({
        showDocumentsPreview: visible
      })
    }
  }

  handleTemperatureClick = visible => {
    this.setState({
      showTemperature: visible
    })
  }

  render () {
    const { data, documentId, title, subtitle, icon, address, showTemperature, documents = [], showInitiateRecall, batchStatus, entityAlerts, information: Information, entity, table: Table, tableTitle, tree: Tree, treeTitle } = this.props
    let count = 0
    count = entityAlerts ? entityAlerts.length : 0
    return <>
      <InfoHeader data={data} documentId={documentId} title={title} subtitle={subtitle} icon={icon} />
      <RowContainer>
        <InfoContainer span={17}>
          <RowEx>
            <AddressContainer span={18}>
              <AddressWrap address={address} />
            </AddressContainer>
            <DocContainer span={6}>
              <Popover
                title={<FormattedMessage id='documents' defaultMessage='Documents' />}
                content={DocumentsPreviewContent(documents)}
                placement='left'
                trigger='click'
                visible={this.state.showDocumentsPreview}
                onVisibleChange={this.handledocumentsPreviewClick}
              >
                <Button icon='file' type='link' disabled={!documents.length}>Documents({documents.length})</Button>
              </Popover>
              <DividerEx />
              {showInitiateRecall && <>
                <CoaUpload parentId={data.id} />
                <DividerEx />
                <InitiateRecall data={data} batchStatus={batchStatus} />
                <DividerEx />
              </>}
              {showTemperature && <><Popover
                content={TemperatureViewerContent}
                placement='right'
                trigger='click'
                visible={this.state.showTemperature}
                onVisibleChange={this.handleTemperatureClick}
              >
                <Button type='link'><ThermometerEx /> Temperature</Button>
              </Popover>
                <DividerEx />
              </>}
            </DocContainer>
          </RowEx>
          <TabsContainer>
            <TabsEx defaultActiveKey='1' type='card'>
              {Table && <TabPaneEx tab={tableTitle.toUpperCase()} key='1'>
                <DetailsTable>
                  <TableWrapper><Table /></TableWrapper>
                </DetailsTable>
              </TabPaneEx>}
              {Tree && <TabPaneEx tab={treeTitle.toUpperCase()} key='3'>
                <Tree />
              </TabPaneEx>}
              <TabPaneEx tab={<Label><FormattedMessage id='alertsAndNoti' defaultMessage='ALERTS AND NOTIFICATIONS ' />({count})</Label>} key='2'>
                <AlertContainer>
                  <Alert entityAlerts={entityAlerts} />
                </AlertContainer>
              </TabPaneEx>
            </TabsEx>
          </TabsContainer>
        </InfoContainer>
        <StatusTimelineCon span={7}>
          <Container>
            <Information data={data} documentId={documentId} />
            <EventTimeline itemId={data.id} entity={entity} />
          </Container>
        </StatusTimelineCon>
      </RowContainer>
    </>
  }
}

export default EntityManagement
