import { put, call, takeLatest } from 'redux-saga/effects'
import actions from './actions'
import { apiRequest } from '../../../utility/fetch'
import { BASE_URLS } from '../../../environment/api'

function * fetchEventTimeline (action) {
  const { itemId } = action.data
  try {
    yield put({ type: actions.TIMELINE_START })
    const response = yield call(apiRequest, `${BASE_URLS.API_TRUSTLINK_EVENTS_URL}/eventTimeline`, {
      method: 'GET',
      queryParams: {
        itemId
      }
    })
    if (response.success) {
      yield put({ type: actions.TIMELINE_SUCCESS, payload: response.data })
    } else {
      yield put({ type: actions.TIMELINE_FAIL, payload: response.message })
    }
  } catch (err) {
    console.log('error calling eventTimeline', err)
    yield put({ type: actions.TIMELINE_FAIL, payload: err })
  }
}

export default function * actionWatcher () {
  yield takeLatest(actions.TIMELINE_INIT, fetchEventTimeline)
}
