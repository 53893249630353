const actions = {
  LOGIN_ERROR: 'app/LOGIN_ERROR',
  CHECK_OLD_PASSWORD_ERROR: 'app/CHECK_OLD_PASSWORD_ERROR',
  CHANGE_PASSWORD_ERROR: 'app/CHANGE_PASSWORD_ERROR',
  CLEAR_ERRORS: 'app/CLEAR_ERRORS'
}

export const clearErrors = () => ({
  type: actions.CLEAR_ERRORS
})

export default actions
