import React from 'react'
import { SideLinks } from '../../components'
import { dateFormatter } from '../../../utility'

const { SideLink, getDatesRangeText } = SideLinks

export default (props) => {
  const { loads, loadId, selectLoad } = props

  const menus = loads.map(({ id, carrier, number, status, deliveryDate }) => {
    return {
      id,
      title: number,
      status,
      content: carrier,
      dateTitle: 'Delivery Date',
      date: dateFormatter(deliveryDate)
    }
  })

  return <SideLink menus={menus} title='Related Loads' subTitle={`${getDatesRangeText(loads, 'deliveryDate')}`} selectedId={loadId} handleSelect={selectLoad} />
}
