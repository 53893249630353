// @flow
import React from 'react'
import styled from 'styled-components'

const Header = styled.div`
  background-color: white;
  box-shadow: 0 0 3px #ddd;
  display: flex;
  justify-content: space-between;
  line-height: 18px;
  padding: 20px;
`

const TitleContainer = styled.div``

const Title = styled.div`
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px;
  color: #212121;
`

const SubTitle = styled.div`
  font-size: 13px;
  color: #757575;
`

type Props = {
  title: string,
  subTitle: string
}

export default (props: Props) => {
  const { title, subTitle } = props
  return <Header>
    <TitleContainer>
      <Title>
        {title}
      </Title>
      <SubTitle>
        {subTitle}
      </SubTitle>
    </TitleContainer>
  </Header>
}
