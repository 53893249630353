import React, { Component } from 'react'
import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import csv from '../../../../assets/csv.png'
import './dataImport.css'
const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes'
  var k = 1000

  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  var i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}
class SelectFileComponent extends Component {
  render () {
    var that = this
    const uploadOptions = {
      accept: '.csv',
      listType: 'picture',
      fileList: this.props.fileList,
      onRemove: () => {
        this.props.setFile(null)
        this.props.setFileList(null)
      },
      beforeUpload: file => {
        return false
      },
      onChange ({ file, fileList }) {
        if (fileList[0] && (fileList[0].type === 'text/csv' || fileList[0].type === 'application/vnd.ms-excel')) {
          fileList[0].name += `        ${formatFileSize(fileList[0].size)}`
          fileList[0].thumbUrl = csv
          that.props.setFile(file)
          that.props.setFileList(fileList)
        } else if (fileList[0]) {
          message.error('Invalid file Type - only csv accepted')
        }
      }
    }
    return (
      <div style={{ width: 400 }}>
        <Upload {...uploadOptions}>
          <Button disabled={this.props.file}>
            <UploadOutlined /> Browse File
          </Button>
        </Upload>
      </div>
    )
  }
}
export default SelectFileComponent
