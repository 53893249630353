import { call, put, takeLatest } from 'redux-saga/effects'
import searchbarActions from './actions'
import { apiRequest } from '../../../utility/fetch'
import { BASE_URLS } from '../../../environment/api'

function * fetchProducts (action) {
  const featchUrl = `${BASE_URLS.API_TRUSTLINK_MASTER_DATA_URL}/products/retrieve`
  const fetchOptions = {
    method: 'POST'
  }
  try {
    const response = yield call(apiRequest, featchUrl, fetchOptions)
    if (response.success) {
      yield put({ type: searchbarActions.PRODUCTS_FETCH_SUCCESS, payload: response.data })
    } else {
      yield put({ type: searchbarActions.PRODUCTS_FETCH_FAILURE, payload: response.message })
    }
  } catch (err) {
    console.log('error while products fetch', err)
    yield put({ type: searchbarActions.PRODUCTS_FETCH_FAILURE, payload: err })
  }
}

function * fetchCompanies (action) {
  const featchUrl = `${BASE_URLS.API_TRUSTLINK_MASTER_DATA_URL}/companies`
  const fetchOptions = {
    method: 'GET'
  }
  try {
    const response = yield call(apiRequest, featchUrl, fetchOptions)
    if (response.success) {
      yield put({ type: searchbarActions.COMPANIES_FETCH_SUCCESS, payload: response.data })
    } else {
      yield put({ type: searchbarActions.COMPANIES_FETCH_FAILURE, payload: response.message })
    }
  } catch (err) {
    console.log('error while companies fetch', err)
    yield put({ type: searchbarActions.COMPANIES_FETCH_FAILURE, payload: err })
  }
}

export default function * actionWatcher () {
  yield takeLatest(searchbarActions.PRODUCTS_FETCH_INIT, fetchProducts)
  yield takeLatest(searchbarActions.COMPANIES_FETCH_INIT, fetchCompanies)
}
