import React from 'react'
import styled from 'styled-components'
import { Pagination } from 'antd'

const PaginationContainer = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
    border: 0;
`

const LeftChild = styled.div`
    flex: 1;
    color: #212121;
    font-size: 13px;
`

const RightChild = styled.div`
    flex: 1;
    text-align: right;
    .ant-select-dropdown {
      width: 10px !important;
  }
`

const pageSizeOptions = ['10', '30', '50', '100']

export default function TablePagination (props) {
  const { paging: { limit: pageSize, pageNumber: page, totalElements }, handlePageSizeChange, handlePageChange } = props

  return (
    <PaginationContainer>
      <LeftChild>
        Total Elements : {totalElements}
      </LeftChild>
      <RightChild>
        <Pagination
          size='small'
          pageSize={pageSize}
          current={page}
          total={totalElements}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={pageSizeOptions}
          onShowSizeChange={handlePageSizeChange} />
      </RightChild>
    </PaginationContainer>
  )
}
