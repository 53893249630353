import { call, put, takeLatest } from 'redux-saga/effects'
import dashboardActions from './actions'
import entitiesActions from '../entities/actions'
import { apiRequest } from '../../../utility/fetch'
import { BASE_URLS } from '../../../environment/api'
import objects, { dateKey } from '../../../configs/objects'
import store from '../../../../src'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import validate from 'uuid-validate'

const FILTER_NULLS = (key, value) => {
  if (!isEmpty(value)) return value
}

function formRequestObjet (action) {
  const requestObject = {}
  const { product, query, customer, criteria, range, pageSize: limit } = store.getState().dashboard
  const productId = product === '0' ? '' : product
  const customerId = customer === '0' ? '' : customer
  const page = action.type === dashboardActions.PAGE_CHANGE ? action.data : null
  const dateIdentifier = dateKey[criteria.toUpperCase()]

  switch (criteria) {
    case 'orders':
    case 'loads':
    case 'shipments':
      requestObject.searchUrl = `${BASE_URLS.API_TRUSTLINK_OPERATIONS_URL}/${criteria}/searches`
      requestObject.options = {
        method: 'POST',
        queryParams: pickBy({
          page,
          limit
        }, identity),
        body: JSON.stringify({
          query: {
            [dateIdentifier]: isEmpty(query) && {
              from: range[0],
              to: range[1]
            },
            customerId,
            number: query
          }
        }, FILTER_NULLS)
      }
      break
    case 'batches':
      requestObject.searchUrl = `${BASE_URLS.API_TRUSTLINK_OPERATIONS_URL}/${criteria}/searches`
      requestObject.options = {
        method: 'POST',
        queryParams: pickBy({
          page,
          limit
        }, identity),
        body: JSON.stringify({
          query: {
            [dateIdentifier]: isEmpty(query) && {
              from: range[0],
              to: range[1]
            },
            productId,
            number: query
          }
        }, FILTER_NULLS)
      }
      break
    default:
      requestObject.searchUrl = `${BASE_URLS.API_PLATFORM_BASE_URL}/items/retrieve`
      requestObject.options = {
        method: 'POST',
        queryParams: pickBy({
          page,
          limit
        }, identity),
        body: JSON.stringify({
          digitizedTimestamp: {
            from: range[0],
            until: range[1]
          },
          currentCustody: { tenantId: customerId },
          input: query,
          template: { id: criteria }
        }, FILTER_NULLS)
      }
      break
  }
  return requestObject
}

function * objectSearch (action) {
  // can use selectors as well here to get store
  const criteria = validate(store.getState().dashboard.criteria) ? 'items' : store.getState().dashboard.criteria
  const requestObject = formRequestObjet(action)
  yield put({ type: entitiesActions.FETCH_START, entityType: criteria })
  try {
    const response = yield call(apiRequest, requestObject.searchUrl, requestObject.options)
    if (response.success) {
      yield put({ type: entitiesActions.FETCH_SUCCESS, payload: response.data, paging: response.paging, entityType: criteria })
    } else {
      yield put({ type: entitiesActions.FETCH_FAILURE, payload: response.message, entityType: criteria })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: entitiesActions.FETCH_FAILURE, payload: err })
  }
}

function * fetchCriteria (action) {
  const searchUrl = `${BASE_URLS.API_PLATFORM_BASE_URL}/itemTemplates`
  const searchOptions = {
    method: 'Get'
  }
  const sequence = Object.keys(objects).length
  yield put({ type: dashboardActions.SEARCH_CRITERIA_START })
  try {
    const response = yield call(apiRequest, searchUrl, searchOptions)
    if (response.success) {
      const data = response.data.map((xs, index) => { return { value: xs.id, name: xs.name, sequence: index + sequence } })// sequence is used in src/configs/roles/manufacturer.js searchCriteria
      yield put({ type: dashboardActions.SEARCH_CRITERIA_SUCCESS, payload: data, paging: response.paging })
    } else {
      yield put({ type: dashboardActions.SEARCH_FAILURE, payload: response.message })
    }
  } catch (err) {
    console.log('error calling object search', err)
    yield put({ type: dashboardActions.SEARCH_FAILURE, payload: err })
  }
}

export default function * actionWatcher () {
  yield takeLatest(dashboardActions.SEARCH_CRITERIA_INIT, fetchCriteria)
  yield takeLatest([dashboardActions.PRODUCT, dashboardActions.QUERY, dashboardActions.CUSTOMER, dashboardActions.RANGE, dashboardActions.INITIAL_LOAD, dashboardActions.PAGE_CHANGE, dashboardActions.PAGE_SIZE_CHANGE], objectSearch)
}
