import Status from '../../components/Status'
import React from 'react'
import Information from '../../components/EntityDetailView/Information'
import { dateFormatter } from '../../../utility'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import { TableUtils } from '../../components'
const { renderDate } = TableUtils

let listOfInfo = []

function flatObject (obj) {
  for (let [key, value] of Object.entries(obj)) {
    if (typeof value === 'object') {
      flatObject(value)
    } else if (typeof value === 'string') {
      listOfInfo.push({
        label: startCase(key),
        value: includes(key.toLowerCase(), 'date') ? renderDate(value) : value
      })
    } else {
      continue
    }
  }
}

export default function (props) {
  const { data, documentId } = props
  const { status, digitizedTimestamp, metadata, state } = data

  const headerInfo = { label: 'Status', documentId: documentId, status: status }
  listOfInfo = [
    {
      label: 'Status',
      value: <Status status={status || 'Unavailable'} />
    }, {
      label: 'Digitized Time',
      value: <Status status={dateFormatter(digitizedTimestamp) || 'Unavailable'} />
    }, {
      label: '',
      value: '',
      isFooter: true
    }
  ]

  if (Object.keys(metadata).length) {
    flatObject(metadata)
  }
  if (state) {
    listOfInfo.push({ label: 'State', value: state.name, color: state.exception ? 'orange' : '#757575' })
  }
  return <Information listOfInfo={listOfInfo} headerInfo={headerInfo} />
}
