import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Tree, Row, Col, Tooltip } from 'antd'
import { sortByKey } from '../../../utility'
import { PackageIcon, Cabinet } from '@styled-icons/boxicons-regular'
import startCase from 'lodash/startCase'
import includes from 'lodash/includes'
import { TableUtils } from '../../components'
const { renderDate } = TableUtils
const { TreeNode } = Tree

const RowEx = styled(Row)`
  min-height: 57vh;
`
const ItemsDescriptions = styled.div`
  background-color: white;
  max-height: 52vh;
  min-height: 52vh;
  overflow-y: scroll;
  border-left: 1px solid #ddd;
    &::webkit-scrollbar-track {
      width: 6px;
      height: 6px;
      background-color: #fff;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 10px;
    }
`
const Label = styled.span`
  color: #212121;
  font-weight: 500;
  font-size: 14px;
`
const Value = styled.span`
  color: #757575;
  font-size: 14px;
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ColEx = styled(Col)`
    padding: 5px 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
class ItemTreeDiagram extends React.Component {
  state = {
    selectedItem: {},
    showInfo: false
  }

  onSelect = (selectedKeys) => {
    const { treeHierarchy, item } = this.props
    const selectedItem = _.find([item].concat(treeHierarchy), { id: selectedKeys[0] })
    this.setState({ selectedItem, showInfo: true })
  }

  selectedItemInfo = () => {
    const { selectedItem } = this.state
    const { identification, metadata } = selectedItem || {}
    const { instance, class: c } = identification || {}
    const dataSource = { ...instance, ...c, ...metadata }

    return (
      <Row>
        <ItemsDescriptions>
          { Object.keys(dataSource).map(key => {
            let value = includes(key.toLowerCase(), 'date') ? renderDate(dataSource[key]) : dataSource[key]
            return <ColEx key={key}>
              <Label>{startCase(key)}</Label>
              <Value><Tooltip title={value}>{value}</Tooltip></Value>
            </ColEx>
          })}
        </ItemsDescriptions>
      </Row>
    )
  }

  removeTemplateFromName = (item) => {
    return item.template.name.split('-Template')[0] || item.template.name
  }

  BuildTreeNodes = () => {
    const { item, treeHierarchy } = this.props
    const treeNodes = treeHierarchy.length && treeHierarchy.sort(sortByKey('depth', 'asc'))

    let tree

    const name = this.removeTemplateFromName(item)
    tree = <TreeNode title={name} key={item.id} switcherIcon={<PackageIcon />} />

    treeNodes && treeNodes.forEach(element => {
      tree = <TreeNode
        title={this.removeTemplateFromName(element)}
        key={element.id}
        switcherIcon={<Cabinet />}>
        {tree}
      </TreeNode>
    })
    return tree
  }

  render () {
    const { item } = this.props

    return <>
      <RowEx>
        <Col span={9}>
          <Tree
            defaultExpandedAll
            defaultExpandedKeys={[item.id]}
            showIcon
            onSelect={this.onSelect}
          >
            {this.BuildTreeNodes(item)}
          </Tree>
        </Col>
        <Col span={15}>
          {this.state.showInfo && this.selectedItemInfo()}
        </Col>
      </RowEx>
    </>
  }
}

export default ItemTreeDiagram
