import React from 'react'
import ConfTable from './ConfTable'
import { TableUtils } from '../../../components'
import { SettingOutlined } from '@ant-design/icons'

const { TableStyled, renderDate, renderLink, renderProductName, dateSorter, customSorter } = TableUtils
const filterOptions = {
  dataIndex: 'configuration',
  key: 'configuration',
  filterIcon: <SettingOutlined />,
  filters: [
    { text: 'Batch #', value: 'number' },
    { text: 'Run #', value: 'runNumber' },
    { text: 'Created At', value: 'createdAt' },
    { text: 'Product Code', value: 'productId' },
    { text: 'Status', value: 'status' }
  ],
  width: '2%'
}
const columns = (products) => {
  return [
    {
      title: 'Batch #',
      dataIndex: 'number',
      key: 'number',
      render: renderLink,
      sorter: customSorter('number')
    },
    {
      title: 'Run #',
      key: 'runNumber',
      dataIndex: 'runNumber',
      sorter: customSorter('runNumber')
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: renderDate,
      sorter: dateSorter('createdAt')
    },
    {
      title: 'Product Code',
      key: 'productId',
      dataIndex: 'productId',
      render: renderProductName(products),
      ellipsis: true,
      sorter: customSorter('productId')
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: customSorter('status')
    }
  ]
}

export default function BatchTable (props) {
  const { entities, criteria, loading, products } = props
  const dataSource = entities[criteria]

  return (
    <TableStyled>
      <ConfTable
        columns={columns(products)}
        dataSource={dataSource}
        loading={loading}
        criteria={criteria}
        options={props}
        filterOptions={filterOptions}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => props.history.push(`batchDetail/${record.number}`)
          }
        }}
        saveFilters={(filters) => props.saveFilters(filters)}
      />
    </TableStyled>
  )
}
