import React, { Component } from 'react'
import { Table } from 'antd'
import { filter, map, find, forEach } from 'lodash'
import ReactDragListView from 'react-drag-listview'
import styled from 'styled-components'

const TableEx = styled(Table)`
  .ant-table-placeholder{
    margin-top: -14vh;
    @media (max-height: 768px) {
      margin-top: ${props => props.criteria !== 'batches' ? '-21vh' : '-23vh'};
    }
  }
  .ant-table-body {
    height: ${props => props.criteria !== 'batches' ? '37vh' : '73vh'};
    @media (max-height: 768px) {
      height: ${props => props.criteria !== 'batches' ? '24vh' : '63vh'};
    }
  &::webkit-scrollbar-track {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
}
`
class ConfTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filteredCol: []
    }
    const that = this
    this.dragProps = {
      onDragEnd (fromIndex, toIndex) {
        let { options: { tableFilters }, criteria, saveFilters } = that.props
        let filColumns = [...that.state.filteredCol]
        let colKeys = []; let _tableFilters = []
        const item = filColumns.splice(fromIndex, 1)[0]
        filColumns.splice(toIndex, 0, item)
        filColumns = filter(filColumns, col => col) // removes undefined in case of expanded columns
        forEach(filColumns, el => {
          if (el && el.key) colKeys.push(el.key)
        })
        forEach(colKeys, el => _tableFilters.push({ key: el, visible: true }))
        forEach(tableFilters[criteria], el => {
          if (!colKeys.includes(el.key)) _tableFilters.push(el)
        })
        saveFilters({ key: criteria, filters: _tableFilters })
        that.setState({ filteredCol: filColumns })
      },
      nodeSelector: 'th'
    }
  }

  setColFilter = (tableFilters) => {
    let visKeys = []; let _filteredCol = []
    forEach(tableFilters, el => {
      if (el.visible) visKeys.push(el.key)
    })
    forEach(visKeys, el => {
      let _column = find(this.props.columns, ['key', el])
      if (_column) _filteredCol.push(_column)
    })
    this.setState({ filteredCol: _filteredCol, visKeys })
  }

  resetColFilter= () => {
    let { columns, saveFilters, criteria } = this.props
    let defaultFilters = map(columns, el => { // initialize store with only key and visible form columns
      return { key: el.key, visible: true }
    })
    saveFilters({ key: criteria, filters: defaultFilters })
    this.setColFilter(defaultFilters)
  }

  componentDidMount () {
    const { options: { tableFilters }, criteria } = this.props
    if (tableFilters && tableFilters[criteria]) {
      this.setColFilter(tableFilters[criteria])
    } else this.resetColFilter()
  }

  handleChange = (pagination, filters, sorter) => {
    const { configuration } = filters
    const { criteria, options: { tableFilters }, saveFilters } = this.props
    if (configuration && configuration.length === 0) {
      this.resetColFilter()
    } else {
      let tempTableFilters = map(tableFilters[criteria], el => {
        if (configuration.includes(el.key)) return { key: el.key, visible: true }
        return { key: el.key, visible: false }
      })
      saveFilters({ key: criteria, filters: tempTableFilters })
      this.setColFilter(tempTableFilters)
    }
  }

  render () {
    const { dataSource, loading, expandedRowRender = null, filterOptions, criteria } = this.props
    let columnsFil = [...this.state.filteredCol]
    filterOptions.filteredValue = this.state.visKeys
    columnsFil.push(filterOptions)
    return (
      <ReactDragListView {...this.dragProps}>
        <TableEx
          size='small'
          loading={loading}
          rowKey='id'
          scroll={{ y: 'auto' }}
          onChange={this.handleChange}
          columns={columnsFil}
          dataSource={dataSource}
          pagination={false}
          expandedRowRender={expandedRowRender}
          onRow={(record, rowIndex) => this.props.onRow(record, rowIndex)}
          criteria={criteria}
        />
      </ReactDragListView >
    )
  }
}

export default ConfTable
