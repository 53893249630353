import React from 'react'
import styled from 'styled-components'
import isObject from 'lodash/isObject'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { Container, EntityDetailView } from '../../components'
import ShipmentSideLink from './ShipmentSideLink'
import { LocalShipping } from '@styled-icons/material-outlined'
import { doLoadDocuments, doLoadShipmentItems } from '../../../redux/modules/entities/actions'
import { entityTitle } from '../../../configs/objects'
import ShipmentDetailTable from './ShipmentDetailTable'
import ShipmentStatusInformation from './ShipmentStatusInformation'
import { Row, Col, Icon } from 'antd'
import { getEntityAlerts } from '../../../redux/modules/alerts/actions'

const IconEx = styled(Icon)`
  margin-right: 8.5px;
  border: 1px solid #c5c5c5;
  padding: 3px;
  border-radius: 20px;
  color: #c5c5c5;
`
const RowEx = styled(Row)`
  max-height: 90vh;
  overflow: hidden;
`
const ColSideLink = styled(Col)`
  margin-right: -20px !important;
`
const ColDetails = styled(Col)`
  padding-left:0 !important;
`
const Title = styled.span`
  font-weight: normal;
  color: #212121;
  font-size: 15px;
`
const RelatedShipmentContainer = styled(Container)`
  background-color: #F3F4F6;
  padding: 0;
`

const ContainerEx = styled(Container)`
  padding: 0;
  flex-grow: 1;
`

const ShipmentIcon = styled(LocalShipping)`
  color: #2196f3;
  width: 45px;
  padding: 5px;
  background-color: #f7f7f7;
`

class ShipmentDetailPage extends React.Component {
  componentDidMount () {
    const { loadShipmentItems, loadDocuments, match, shipments, getEntityAlerts } = this.props
    const { shipmentNumber } = match.params
    const shipment = _.find(shipments, { number: shipmentNumber })
    loadShipmentItems({ shipmentId: shipment.id })
    loadDocuments(shipment.id)
    getEntityAlerts(shipment.id)
  }

  selectShipment = (id) => {
    const { loadShipmentItems, loadDocuments, history, shipments, getEntityAlerts } = this.props
    const shipment = _.find(shipments, { id })
    history.push(`${shipment.number}`)
    loadShipmentItems({ shipmentId: id })
    loadDocuments(id)
    getEntityAlerts(id)
  }

  render () {
    const { shipments, match, documents, entityAlerts } = this.props
    const { shipmentNumber } = match.params

    const shipment = _.find(shipments, { number: shipmentNumber })
    const order = { key: entityTitle.ORDER, value: shipment.orderNumber || '' }
    const { customerId } = shipment
    const { billTo, shipTo } = isObject(shipment.order) ? shipment.order : {}
    const address = [{ ...billTo, title: <Title><IconEx type='user' />Bill To</Title>, customerId }, { ...shipTo, title: <Title><IconEx type='user' />Ship To</Title>, customerId }]

    return <RowEx>
      <ColSideLink span={5}>
        <RelatedShipmentContainer>
          <ShipmentSideLink shipments={shipments} shipmentId={shipment.id} selectShipment={this.selectShipment} />
        </RelatedShipmentContainer>
      </ColSideLink>
      <ColDetails span={19}>
        <ContainerEx>
          <EntityDetailView data={shipment} documentId={shipmentNumber}
            entity={entityTitle.SHIPMENT} icon={ShipmentIcon} documents={documents} information={ShipmentStatusInformation}
            subtitle={order} table={ShipmentDetailTable} tableTitle='Shipment Items' address={address} entityAlerts={entityAlerts} />
        </ContainerEx>
      </ColDetails>
    </RowEx>
  }
}

const mapStateToProps = ({ entities: { loading, shipments, documents }, alerts: { entityAlerts } }) => {
  return {
    shipments,
    documents,
    loading: loading,
    entityAlerts
  }
}

const mapDispatchToProps = {
  loadShipmentItems: doLoadShipmentItems,
  loadDocuments: doLoadDocuments,
  getEntityAlerts
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShipmentDetailPage))
