import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SelectFileComponent from './SelectFileComponent'
import SelectEntityComponent from './SelectEntityComponent'
import PreviewContent from './PreviewContent'
import { Table, message, Steps, Button, Breadcrumb, Divider } from 'antd'
import { connect } from 'react-redux'
import { onCreateDocument, resetDocUploadVar, documentsFetch, BUSINESS_DOC_TYPE } from '../../../../redux/modules/documentImport/actions'
import validationConfig from '../../../../configs/docValidation'
import TableStyled from '../../../components/TableUtils/TableStyled'
import './dataImport.css'
import csv from '../../../../assets/csv.png'

const MESSAGE_TOPIC = 'operational-data-import'

const TableCon = styled.div`
 display:grid
`
const StyledImage = styled.img`
  height: 4vh;
  float: left;
  padding: 3px;
`

const ActionsContainer = styled.div`
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: flex-end;
`
const ActionButtons = styled(Button)`
    margin-left: 20px;
    width: 140px;
`

const StepContent = styled.div`
    box-shadow: 0 1px 3px rgba(0,0,0,0.22);
    border: 1px solid #D9DADB;
    border-radius: 5px;
    padding: 20px 20px;
    font-size: 16px;
    color: #000000;
    margin-top: 30px;
`

const BreadcrumbEx = styled(Breadcrumb)`
    margin: 5px 5px;
`

const BCItem = styled(Breadcrumb.Item)`
    font-size: 14px;
    color: #000000;
`

const StyledSteps = styled(Steps)`
    margin-top: 10px;
`
const { Step } = Steps

const ImportFile = (props) => {
  const [current, setCurrent] = useState(0)
  const [file, setFile] = useState(null)
  const [fileList, setFileList] = useState(null)
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  const [entity, setEntity] = useState('orders')
  const config = validationConfig[`${entity || 'orders'}Config`]

  const [currErrorList, setCurrErrorList] = useState([])
  const { closeImportNew } = props
  const steps = [
    {
      title: 'Select Entity and Template',
      content: (
          <>
            <SelectEntityComponent setEntity={(entity) => setEntity(entity)} />
          </>
      )
    },
    {
      title: 'Select File',
      content: (
        <>
          <SelectFileComponent file={file} fileList={fileList} setFile={(file) => setFile(file)} setFileList={(fileList) => setFileList(fileList)} />
        </>
      )
    },
    {
      title: 'Preview Content',
      content: (<>
        <PreviewContent currErrorList={currErrorList} columns={columns} data={data} config={config} file={file} setCurrErrorList={(currErrorList) => setCurrErrorList(currErrorList)} setColumns={(columns) => setColumns(columns)} setData={(data) => setData(data)} />
      </>)
    },
    {
      title: 'Import',
      content: (
        <TableStyled>
          <TableCon>
            <div><StyledImage src={csv} /><p>{file ? file.name : ''}</p></div>
            <Table
              className='disTable'
              rowKey='slNo'
              bordered tableLayout='auto'
              scroll={{ x: 'max-content' }}
              columns={columns} dataSource={data} />
          </TableCon>
        </TableStyled>)
    }
  ]
  const handleNext = () => {
    setCurrent(current + 1)
    if (current === 0) props.resetDocUploadVar()
    if (current === 1) {
      setColumns([])
      setData([])
      setCurrErrorList([])
    }
  }
  const handleImportFile = () => {
    props.onCreateDocument({ file: file,
      data: JSON.stringify({
        messageTopic: MESSAGE_TOPIC,
        messageCategory: entity.toLowerCase(),
        documentType: BUSINESS_DOC_TYPE,
        gdti: 'gdti',
        parent: {},
        grants: ['Create', 'Read']
      })
    })
  }

  const handlePrev = () => {
    setCurrent(current - 1)
    if (current === 1) setEntity('orders')
  }
  useEffect(() => {
    if (props.docUploadStatus === 'success') success()
    if (props.docUploadStatus === 'fail') error()
  })
  const success = () => {
    message.success('Document Import Successful')
    props.resetDocUploadVar()
    props.documentsFetch()
    setFile(null)
    setFileList(null)
    closeImportNew()
  }

  const error = () => {
    message.error(JSON.stringify(props.uploadErrorMessage).length === 0 ? JSON.stringify(props.uploadErrorMessage) : 'Document Import Failed')
    props.resetDocUploadVar()
  }
  return (
        <>
          <BreadcrumbEx separator='>'>
            <BCItem href='/dataManagement'>Imported Data</BCItem>
            <BCItem>Import New</BCItem>
          </BreadcrumbEx>
          <Divider />
          <StyledSteps size='small' current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </StyledSteps>
          <StepContent>
            {steps[current].content}
            <ActionsContainer>
              {current > 0 && (
                <ActionButtons onClick={handlePrev}>{current === 2 ? 'Cancel' : 'Back'}</ActionButtons>
              )}
              {current < steps.length - 1 && (
                <ActionButtons disabled={current > 1 ? (columns.length > 0 ? currErrorList.length > 0 : true) : (current === 1 ? !file : false)} type='primary' onClick={handleNext}>Continue</ActionButtons>
              )}
              {current === steps.length - 1 && (
                <ActionButtons type='primary' loading={props.isUploading} onClick={handleImportFile}>Import</ActionButtons>
              )}
            </ActionsContainer>
          </StepContent>
        </>
  )
}
const mapStateToProps = ({ documentImport: { isUploading, docUploadStatus, uploadErrorMessage } }) => {
  return {
    isUploading,
    docUploadStatus,
    uploadErrorMessage
  }
}
const mapDispatchToProps = {
  onCreateDocument,
  resetDocUploadVar,
  documentsFetch
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportFile)
