import Status from '../../components/Status'
import React from 'react'
import Information from '../../components/EntityDetailView/Information'
import { dateFormatter } from '../../../utility'

export default function (props) {
  const { data, documentId } = props
  const { status, createdAt, items } = data

  const headerInfo = { label: 'Status', documentId: documentId, status: status }
  const listOfInfo = [
    {
      label: 'Status',
      value: <Status status={status} />
    }, {
      label: 'Created Date',
      value: dateFormatter(createdAt)
    }, {
      label: 'Item Count',
      value: Object.keys(items).length
    }, {
      label: '',
      value: '',
      isFooter: true
    }
  ]

  return <Information listOfInfo={listOfInfo} headerInfo={headerInfo} />
}
