import React, { Component } from 'react'
import CSVFileValidator from 'csv-file-validator'
import { Table, List, Alert } from 'antd'
import { isEmpty, filter } from 'lodash'
import './dataImport.css'
import styled from 'styled-components'
import csv from '../../../../assets/csv.png'

let errorList = []; let errorRow = []
const ErrorList = styled(List)`
  max-height: 330px;
  @media (max-height: 768px) {
    max-height: 200px;
  }
    overflow: auto
`
const TableCon = styled.div`
`
const TableEx = styled(Table)`
  width: 93vw;
  @media (max-width: 1366px) {
    width: 90vw;
  }
  overflow:auto;
  .ant-table-body {
    max-height: 25vh;
    @media (max-height: 768px) {
      max-height: 35vh;
    }
    &::webkit-scrollbar-track {
      width: 6px;
      height: 6px;
      background-color: #fff;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 10px;
    }
`
const StyledImage = styled.img`
  height: 4vh;
  float: left;
  padding: 3px;
`
class PreviewContent extends Component {
  state = {
    loading: true,
    fileErrorMessage: null
  }
  componentDidMount () {
    let _columns = [{ title: 'SL No', dataIndex: 'slNo', key: 'slNo' }]; let _data = []
    let _file = this.props.file
    let _config = this.props.config || []
    errorRow = []
    errorList = []
    if (_file) {
      if (isEmpty(_config)) {
        this.setState({ loading: false, fileErrorMessage: 'Unsupported entity' })
      }
      CSVFileValidator(_file, _config)
        .then((csvData) => {
          let headerErrors = []
          headerErrors = filter(csvData.inValidMessages, 'headerError')
          if (!isEmpty(headerErrors)) {
            this.props.setCurrErrorList(headerErrors)
            this.setState({ fileErrorMessage: 'Fix headers as per errors shown' })
          } else {
            let _idx = 1
            Object.keys(csvData.data[1]).forEach((hdr) => {
              _columns.push({
                title: hdr,
                dataIndex: hdr,
                key: hdr
              })
            })
            csvData.data.shift()
            _data = csvData.data
            errorList = csvData.inValidMessages
            errorList.forEach((errListElm) => {
              errorRow.push(errListElm.rowNumber - 2)
            })
            _data.map((dataElm) => {
              dataElm['slNo'] = ++_idx
              return dataElm
            })
            this.props.setColumns(_columns)
            this.props.setData(_data)
            let tempCurrErrorList = this.formCurrErrorList({ pageSize: 10, current: 1 })
            this.props.setCurrErrorList(tempCurrErrorList)
            this.setState({ loading: false })
          }
        })
        .catch((err) => {
          console.log('error', err)
          this.setState({ loading: false, fileErrorMessage: err && err.error ? err.error.message : 'Error reading file' })
        })
    } else this.setState({ loading: false })
  }
  onPageChange (page) {
    this.props.setCurrErrorList(this.formCurrErrorList(page))
  }
  formCurrErrorList (page) {
    let tempCurrErrorList = []
    let errList = [...errorList]
    if (errorRow.length === 0) return tempCurrErrorList
    for (let i = 0; i < page.pageSize; i++) {
      if ((((page.current - 1) * page.pageSize) + i) <= (page.current * page.pageSize)) {
        errorRow.forEach((el, index) => {
          if ((el === (page.pageSize * (page.current - 1) + i)) && errList[index]) tempCurrErrorList.push(errList[index])
        })
      }
    }
    return tempCurrErrorList
  }
  render () {
    return (
      <>
        { !this.state.fileErrorMessage &&
        <TableCon>
          <div><StyledImage src={csv} /><p>{this.props.file.name} {this.state.fileErrorMessage}</p></div>
          <TableEx
            rowKey='slNo'
            tableLayout='auto'
            bordered
            loading={this.state.loading}
            rowClassName={(record, index) => {
              return errorRow.indexOf(index) !== -1 ? 'ErrorRow' : 'normalRow'
            }}
            onChange={(page) => this.onPageChange(page)}
            columns={this.props.columns}
            dataSource={this.props.data}
            pagination
            scroll={{ x: 'max-content' }}
          />
        </TableCon>
        }
        <p>{this.state.fileErrorMessage}</p>
        {this.props.currErrorList && this.props.currErrorList.length > 0 && <ErrorList
          dataSource={this.props.currErrorList}
          renderItem={item => <Alert message={item.message} type='error' showIcon />}
        />}
      </>
    )
  }
}

export default PreviewContent
