const actions = {
  PRODUCTS_FETCH_INIT: 'products/PRODUCTS_FETCH_INIT',
  PRODUCTS_FETCH_SUCCESS: 'products/PRODUCTS_FETCH_SUCCESS',
  PRODUCTS_FETCH_FAILURE: 'products/PRODUCTS_FETCH_FAILURE',

  COMPANIES_FETCH_INIT: 'companies/COMPANIES_FETCH_INIT',
  COMPANIES_FETCH_SUCCESS: 'companies/COMPANIES_FETCH_SUCCESS',
  COMPANIES_FETCH_FAILURE: 'companies/COMPANIES_FETCH_FAILURE'
}

export const onProductsFetch = () => ({
  type: actions.PRODUCTS_FETCH_INIT
})

export const onCompaniesFetch = () => ({
  type: actions.COMPANIES_FETCH_INIT
})

export default actions
