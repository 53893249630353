import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getEventTimeline, resetEventTimeline } from '../../../redux/modules/eventTimeline/actions'
import { Collapse, Skeleton, Empty, Tooltip, Icon } from 'antd'
import styled from 'styled-components'
import { UserOutlined, ShopOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'
import orderBy from 'lodash/orderBy'
import { dayDateTimeFormatter } from '../../../utility'

const { Panel } = Collapse

const WidgetContianer = styled.div`
  margin: 12px;
  height: 36vh;
  @media (max-height: 768px) {
    height: 29.5vh;
  }
  border: none;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    width: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
`
const CollapseCon = styled.div`
  position: relative;
`
const StyledCollapse = styled(Collapse)`
  position: relative;
  z-index: 1;
  background-color: transparent !important;
  border: 0px;
  overflow: hidden;
`

const Timeline = styled.div`
  display: ${(props) => props._show ? 'block' : 'none'};
  position: absolute;
  z-index: 0;
  top: 21px;
  left: 12px;
  height: calc(100% - 42px);
  border-left: 2px solid #D9DADB;
`

const PanelItemCon = styled.div`
  color: ${(props) => (props.expanded ? '#053771' : '#060606')};
  padding-left: 20px !important;
`

const IconContainer = styled.div`
  background-color: white;
  padding: 6px;
  border: 1px solid #D9DADB;
  border-radius: 4px;
  margin-left: -18.5px;
`

const StyledHeader = styled.div`
  color: ${(props) => (props.expanded ? '#053771' : '#060606')};
`
const StyledPanel = styled(Panel)`
  white-space: pre;
  border: none !important;
  margin-left: 2px;
`
const PanelItem = styled.div`
  padding: 3px;
  white-space: pre;
`
const BoldText = styled.span`
  font-weight: normal;
  font-size: 13px;
  color: #212121;
`
const SmallText = styled.span`
  font-size: 11px;
  color: #757575;
  font-weight: normal;
`
const AddressText = styled.span`
  font-size: 11px;
  font-weight: normal;
  color: #757575;
  margin-left: 70px
`

const Time = styled.div`
  font-size: 11px;
  font-weight: normal;
  color: #757575;
  margin-top: -5px;
`
const EmptyEx = styled(Empty)`
  display: ${(props) => props._display};
  margin: 20% auto;
`

const renderAddress = (location) => {
  const { name, address: { postalAddress: { addressLocality, streetAddress, addressRegion, postalCode, addressCountry } = {} } = {} } = location || {}
  return (
    <>
      {name}<br />
      <AddressText >{streetAddress}</AddressText><br />
      <AddressText >{addressLocality}</AddressText><br />
      <AddressText >{addressRegion} {postalCode}</AddressText><br />
      <AddressText >{addressCountry}</AddressText><br />
    </>
  )
}
class TimelineWidget extends Component {
  constructor () {
    super()
    this.state = {
      events: [],
      activeIndex: 0,
      itemId: null,
      loading: true
    }
  }
  componentDidMount () {
    const { itemId, entity, getEventTimeline } = this.props
    getEventTimeline({ itemId: itemId, entity: entity })
    this.setState({ loading: true, itemId: itemId })
  }
  componentDidUpdate () {
    const { itemId, entity, status, events, getEventTimeline, resetEventTimeline } = this.props
    if (this.state.itemId !== itemId) {
      getEventTimeline({ itemId: itemId, entity: entity })
      this.setState({ loading: true, itemId: itemId })
    }
    if (status === 'success') {
      resetEventTimeline()
      this.setState({ loading: false, events: [...events] })
    }
    if (status === 'fail') {
      this.setState({ loading: false, events: [] })
      resetEventTimeline()
    }
  }
  setActiveTimeline (index) {
    if (index) this.setState({ activeIndex: parseInt(index[0]) })
  }
  render () {
    let evLength = this.state.events.length
    return (
      <WidgetContianer>
        {this.state.loading
          ? <Skeleton active paragraph={{ rows: 4 }} />
          : <CollapseCon>
            <StyledCollapse
              accordion
              onChange={(index) => this.setActiveTimeline(index)}
              bordered={false}
              defaultActiveKey={[evLength === 0 ? 0 : evLength - 1]}
              expandIcon={({ isActive }) => (<IconContainer><Icon type={isActive ? 'up' : 'down'} style={{ color: isActive ? '#212121' : '#c6c6c6', lineHeight: 0 }} /></IconContainer>)}
            >
              {this.state.events.map((tmItem, index) => {
                return (
                  <StyledPanel header={<StyledHeader expanded={index === this.state.activeIndex}><Tooltip title={tmItem.status}><BoldText>{tmItem.status}</BoldText></Tooltip>    <Time>{dayDateTimeFormatter(tmItem.time)}</Time></StyledHeader>} key={index}>
                    <PanelItemCon expanded={index === this.state.activeIndex}>
                      <PanelItem><UserOutlined /> <BoldText><FormattedMessage id='who' />:</BoldText>  <Tooltip title={tmItem.userId}><SmallText>{tmItem.userId}</SmallText></Tooltip></PanelItem>
                      <PanelItem><ShopOutlined /> <BoldText><FormattedMessage id='where' />:</BoldText> <Tooltip title={renderAddress(tmItem.location)}><SmallText>{renderAddress(tmItem.location)}</SmallText></Tooltip></PanelItem>
                    </PanelItemCon>
                  </StyledPanel>
                )
              })}
            </StyledCollapse>
            <Timeline _show={evLength !== 0} />
            <EmptyEx _display={evLength === 0 ? 'block' : 'none'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </CollapseCon>
        }
      </WidgetContianer>
    )
  }
}

const mapStateToProps = ({ eventTimeline: { events, status } }) => {
  return {
    events: orderBy(events, 'time'),
    status
  }
}

const mapDispatchToProps = {
  getEventTimeline,
  resetEventTimeline
}

export default connect(mapStateToProps, mapDispatchToProps)(TimelineWidget)
