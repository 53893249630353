import React from 'react'
import { SideLinks } from '../../components'
import { dateFormatter } from '../../../utility'

const { SideLink, getDatesRangeText } = SideLinks

export default (props) => {
  const { items, selectitem, itemId } = props

  const menus = items.map(({ id, number, status, digitizedTimestamp, identification, template }) => {
    const primaryKey = Object.keys(identification.instance)[0]
    return {
      id,
      title: identification.instance[primaryKey],
      status,
      content: template.name,
      dateTitle: 'Created Date',
      date: dateFormatter(digitizedTimestamp)
    }
  })

  return <SideLink menus={menus} title='Related Items' subTitle={`${getDatesRangeText(items, 'digitizedTimestamp')}`} selectedId={itemId} handleSelect={selectitem} />
}
